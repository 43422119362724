import React from "react";
import { Box, Grid, Button, Typography, MenuItem, Select, FormControl, Divider } from "@mui/material";
import LabeledInput from "../main/LabeledInput";

const EducationForm = ({ education, setEducation }) => {
    const educationLevels = [
        "среднее",
        "среднее специальное",
        "неоконченное высшее",
        "высшее",
        "бакалавр",
        "магистр",
        "кандидат наук",
        "доктор наук",
    ];

    const years = Array.from(new Array(50), (_, index) => new Date().getFullYear() - index);

    const handleAddEducation = (event) => {
        event.preventDefault();
        setEducation((prev) => ({
            ...prev,
            items: [
                ...prev.items,
                {
                    place: "",
                    major: "",
                    faculty: "",
                    level: "",
                    year: "",
                },
            ],
        }));
    };

    const handleRemoveEducation = (index) => {
        setEducation((prev) => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index),
        }));
    };

    const handleFieldChange = (index, field, value) => {
        setEducation((prev) => {
            const updatedItems = [...prev.items];
            updatedItems[index][field] = value;
            return { ...prev, items: updatedItems };
        });
    };

    const handleLevelChange = (value) => {
        setEducation((prev) => ({ ...prev, level: value }));
    };

    return (
        <Box sx={{ pt: "17px", borderTop: "1px solid rgba(235, 238, 239, 1)", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px"}}>
                Образование
            </Typography>

            {/* Уровень образования */}
            <Box>
                <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '16px', fontWeight: 500 }}>
                    Уровень образования
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={education.level}
                        onChange={(e) => handleLevelChange(e.target.value)}
                        displayEmpty
                        sx={{ borderRadius: '8px' }}
                    >
                        <MenuItem value="" disabled>
                            Выберите из списка
                        </MenuItem>
                        {educationLevels.map((level, idx) => (
                            <MenuItem key={idx} value={level}>
                                {level}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Divider sx={{borderColor : "rgba(235, 238, 239, 1)"}}/>

            {/* Образовательные места */}
            {education.items.map((ed, index) => (
                <Box
                    key={index}
                    sx={{
                        borderRadius: "8px",
                        position: "relative",
                    }}
                >
                    {index !== 0 && <Divider sx={{ borderBottom: "1px solid rgba(235, 238, 239, 1)", mb: "24px" }} />}
                    <Grid container spacing={3}>
                        {/* Название учебного заведения */}
                        <Grid item xs={12} md={6}>
                            <LabeledInput
                                label="Название учебного заведения"
                                placeholder="Введите название заведения"
                                value={ed.place}
                                onChange={(e) => handleFieldChange(index, "place", e.target.value)}
                            />
                        </Grid>

                        {/* Факультет */}
                        <Grid item xs={12} md={6}>
                            <LabeledInput
                                label="Факультет"
                                placeholder="Введите название факультета"
                                value={ed.faculty}
                                onChange={(e) => handleFieldChange(index, "faculty", e.target.value)}
                            />
                        </Grid>

                        {/* Специализация */}
                        <Grid item xs={12} md={6}>
                            <LabeledInput
                                label="Специализация"
                                placeholder="Введите название специализации"
                                value={ed.major}
                                onChange={(e) => handleFieldChange(index, "major", e.target.value)}
                            />
                        </Grid>


                        {/* Год окончания */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Год окончания
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={ed.year}
                                    onChange={(e) => handleFieldChange(index, "year", e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        Год окончания
                                    </MenuItem>
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                    {/* Кнопки */}
                    <Box sx={{display: "flex", justifyContent: "space-between", flexDirection: "row-reverse", mt: 3}}>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => handleRemoveEducation(index)}
                            disabled={education.items.length === 1}
                            sx={{
                                textTransform: "none",
                                fontWeight: 500,
                                padding: "16px 24px",
                                border: "1px solid transparent",
                                color: "rgba(255, 59, 48, 1)",
                                fontSize: "18px",
                                lineHeight: "22px",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 243, 242, 1)",
                                    color: "rgba(228, 53, 43, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "transparent",
                                    color: "rgba(198, 46, 37, 1)",
                                }
                            }}
                        >
                            Удалить
                        </Button>
                        {index === education.items.length - 1 && <Box
                            component = "button"
                            variant="contained"
                            color="success"
                            onClick={handleAddEducation}
                            sx={{
                                textTransform: "none",
                                fontWeight: 500,
                                padding: "16px 24px",
                                border: "1px solid rgba(89, 157, 21, 1)",
                                color: "rgba(89, 157, 21, 1)",
                                fontSize: "18px",
                                lineHeight: "22px",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(244, 247, 242, 1)",
                                    borderColor: "rgba(80, 140, 19, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "rgba(205, 221, 198, 1)",
                                    borderColor: "rgba(69, 122, 16, 1)",
                                }
                            }}
                        >
                            Добавить место обучения
                        </Box>}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default EducationForm;

import axiosClassic from "../api/interceptors";

/**
 * @param {String} searchQuery - search query
 * @param {Number} page - page number
 * @param {Number} perPage - number of items per page
 * @param {Number} purpose - 1 for stages, 2 for refuses
 * @returns {Object} - object that contains data and meta
 */

export const fetchStages = async (searchQuery = "", page = 1, perPage = 10,  additional) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    
    try {
        const params = {
            page,
            per_page: perPage,
            ...(searchQuery && { name: searchQuery }),
            ...(additional && {
                ...(additional.purpose && { purpose: additional.purpose }),
                ...(typeof additional.isDefault !== 'undefined' && { is_default: additional.isDefault }),
            }),
        };
        
        const response = await axiosClassic.get(`/api/company/${companyId}/stages`, { params });;

        // Check if the response has data
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error fetching stages:", error.message);
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * 
 * @param {Object} data - data containing two fields name String, and purpose Number, 1 for stages, 2 for refuses
 * @returns {Object} - object that contains data and meta
 */
export const createStage = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/stages`, 
            { 
                ...data.body,
            },
            {
                params: { 
                    page: data.page || 1,
                    per_page: data.perPage || 13,
                }, 
            }
        );
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error creating stage:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Object} data - data containing two fields first is id Number and second is name String
 * @returns {Object} - object that contains data and meta
 */
export const updateStage = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/stages/${data?.body?.id}`,
            { 
                ...data.body,
            },
            {
                params: { 
                    page: data.page || 1,
                    per_page: data.perPage || 13,
                }, 
            }
        );
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error updating stage:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Number} data - object with field id in side
 * @returns {Object} - object that contains data and meta
 */
export const deleteStage = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/stages/${data.id}`, {
            params: { 
                page : data.page || 1, 
                per_page: data.perPage || 13,
            },
        });
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error deleting stage:", error.message);
        throw error;
    }
}

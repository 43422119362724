import { Container } from '@mui/material';

const PageContainer = ({ children }) => {

    return (
        <Container maxWidth="none" sx={{ margin: '24px 0', display: 'flex', flexDirection: 'column' }}>
            {children}
        </Container>
    );
}

export default PageContainer;
import { Box, Typography, Button } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import CustomInput from "../../components/styledComponents/CustomInput";
import { useState, useEffect, useCallback } from "react";
import { CameraIcon } from "../../components/svgComponents";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getMe } from "../../services/main";
import { CircularLoading, Error } from "../../components/tapHrStyledComponents";
import CustomAutoComplete from "../../components/styledComponents/CustomAutoComplete";
import { updateProfile } from "../../services/profileSettings";

const ProfileSettingsPage = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const maxFileSize = 2097152; // 2 MB in bytes
    const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const queryClient = useQueryClient();

    const {data, isLoading, isError, error} = useQuery(['getMe'], getMe, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data?.data) {
            setName(data.data.name);
            setPhone(data.data.phone);
            setEmail(data.data.email);
            setPreviewUrl(data.data.avatar);
            setUploadedFile(Boolean(data.data.avatar));
        }
    }, [data]);

    const formatPhoneNumber = useCallback((value) => {
        // Format the number to +7 (___) ___-__-__
        let formattedValue = "";
        if (value.length > 0) {
            formattedValue += `+${value.slice(0, 1)}`;
        }
        if (value.length > 1) {
            formattedValue += ` (${value.slice(1, 4)}`;
        }
        if (value.length >= 5) {
            formattedValue += `) ${value.slice(4, 7)}`;
        }
        if (value.length >= 8) {
            formattedValue += `-${value.slice(7, 9)}`;
        }
        if (value.length >= 10) {
            formattedValue += `-${value.slice(9, 11)}`;
        }
    
        return formattedValue;
    },[]);

    const restrictPhoneNumber = useCallback((value) => {
        // Remove all non-numeric characters
        return value.replace(/\D/g, "").slice(0, 11);
    },[]);


    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            // Check if the file type is in the allowed image types
            if (allowedImageTypes.includes(uploadedFile.type) && uploadedFile.size <= maxFileSize) {
                setUploadedFile(uploadedFile);
                setPreviewUrl(URL.createObjectURL(uploadedFile));
                setErrorMessage(""); // Clear any previous error
            } else if (uploadedFile.size > maxFileSize) {
                setErrorMessage("Размер файла не должен превышать 2 MB!");
                setUploadedFile(null);
                setPreviewUrl(null);
            }
            else {
                setErrorMessage("Можно загружать только изображения (JPG, JPEG, PNG)!");
                setUploadedFile(null);
                setPreviewUrl(null);
            }
        }
    };
    

    const updateMutation = useMutation(updateProfile, {
        onSuccess: (data) => {
            queryClient.setQueryData(["getMe"], (oldData) => {
                return {
                    ...data,
                    data: {
                        ...oldData.data,
                        ...data.data,
                    },
                };
            });
            alert("Профиль успешно обновлен!");
        },
    });

    // create FormData object and send only fields that changed to the server
    const handleSave = () => {
        const formData = new FormData();
        let changed = false;
        // Phone number validation (e.g., must be 11 digits, starts with "+7")
        const isValidPhone = (phone) => /^(?:\+7|7|8)\d{10}$/.test(phone);

        // Email validation using a regex pattern
        const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (uploadedFile instanceof File) {
            changed = true;
            formData.append("avatar", uploadedFile);
        }
        if (name !== data.data.name && name) {
            changed = true;
            formData.append("name", name);
        }

        if (phone !== data.data.phone && isValidPhone(phone)) {
            changed = true;
            formData.append("phone", phone);
        }
        if (email !== data.data.email && isValidEmail(email)) {
            changed = true;
            formData.append("email", email);
        }

        if (changed) {
            updateMutation.mutate(formData);
        }
    }

    return (
        <PageContainer>
            <Box
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{ fontWeight: 600, fontSize: 24, lineHeight: "24px" }}
                >
                    Настройки профиля
                </Typography>
                {isLoading ? (
                    <CircularLoading />
                ) : isError ? (
                    <Error error={error.message || "Неопознанная причина"} />
                ) : (
                        <>
                            <Box sx={{ display: "flex", gap: "36px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "16px",
                                        alignItems: "center",
                                        position: "relative",
                                        flex: 1,
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            width: 84,
                                            height: 84,
                                            border: "1px solid rgba(162, 161, 168, 0.2)",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        {previewUrl ? (
                                            <img
                                                src={previewUrl}
                                                alt="Profile"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                        ) : (
                                            <CameraIcon
                                                sx={{ width: 24, height: 24 }}
                                                svgSx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: "rgba(22, 21, 28, 1)",
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box sx={{ width: "calc(100% - 84px)" }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: 16,
                                                lineHeight: "20px",
                                                color: "rgba(21, 24, 19, 1)",
                                                mb: "4px",
                                            }}
                                        >
                                            Фото профиля
                                        </Typography>
                                        {errorMessage ? (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    lineHeight: "18px",
                                                    color: "red",
                                                    mb: 1,
                                                }}
                                            >
                                                {errorMessage}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    lineHeight: "18px",
                                                    color: "rgba(173, 181, 189, 1)",
                                                    mb: 1,
                                                }}
                                            >
                                                .png, .jpg, .jpeg до 2MB
                                            </Typography>
                                        )}
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: 14,
                                                lineHeight: "18px",
                                                color: "rgba(89, 157, 21, 1)",
                                            }}
                                        >
                                            Загрузить
                                        </Typography>
                                    </Box>
                                    <input
                                        accept=".png, .jpg, .jpeg"
                                        type="file"
                                        onChange={handleFileUpload}
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            opacity: 0,
                                            cursor: "pointer",
                                        }}
                                    />
                                </Box>
                                <CustomInput
                                    label="ФИО"
                                    placeholder="Введите имя"
                                    value={name || ""}
                                    onChange={setName}
                                    sx={{ flex: 1, inputPadding: "18px 16px" }}
                                />
                                <CustomInput
                                    label="Телефон"
                                    placeholder="+7 (___) ___-__-__"
                                    renderOutput={formatPhoneNumber}
                                    renderInput={restrictPhoneNumber}
                                    value={phone || ""}
                                    onChange={setPhone}
                                    sx={{ flex: 1, inputPadding: "18px 16px" }}
                                />
                            </Box>
                            <Box
                                sx={{ display: "flex", gap: "36px", width: "100%" }}
                            >
                                <Box sx={{ flex: 1}}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            marginBottom: "8px",
                                            display: "block",
                                        }}
                                    >
                                        Часовой пояс
                                    </label>
                                    <CustomAutoComplete />
                                </Box>
                                <CustomInput
                                    label="Почта для уведомлений"
                                    placeholder="Введите почту"
                                    value={email || ""}
                                    onChange={setEmail}
                                    sx={{ flex: 2.102, inputPadding: "18px 16px" }}
                                />
                            </Box>
                            <Box
                                sx={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box
                                    component={"button"}
                                    onClick={handleSave}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "12px 20px",
                                        borderRadius: "8px",
                                        backgroundColor: "rgba(89, 157, 21, 1)",
                                        color: "white",
                                        fontWeight: 400,
                                        fontSize: 16,
                                        lineHeight: "20px",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: "rgba(80, 140, 19, 1)",
                                        },
                                        "&:active": {
                                            backgroundColor: "rgba(69, 122, 16, 1)",
                                        },
                                        border: "none",
                                    }}
                                >
                                    Сохранить
                                </Box>
                            </Box>
                        </>
                    )}
            </Box>
        </PageContainer>
    );
}

export default ProfileSettingsPage;
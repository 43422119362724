import React, { useState } from "react";
import { Box, Grid, Typography, MenuItem, Select, FormControl, TextField, Button, Divider } from "@mui/material";
import LabeledInput from "../main/LabeledInput";

const WorkExperienceForm = ({ experience, setExperience }) => {

    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    const years = Array.from(new Array(50), (_, index) => new Date().getFullYear() - index);

    const handleAddWorkExperience = (event) => {
        event.preventDefault();
        setExperience([
            ...experience,
            {
                company: "",
                position: "",
                start: "",
                end: "",
                description: "",
            },
        ]);
    };

    const handleRemoveWorkExperience = (index) => {
        setExperience(experience.filter((_, i) => i !== index));
    };

    const handleFieldChange = (index, field, value) => {
        const updatedWorkExperiences = [...experience];
        updatedWorkExperiences[index][field] = value;
        setExperience(updatedWorkExperiences);
    };

    return (
        <Box sx={{ pt: "17px", borderTop: "1px solid rgba(235, 238, 239, 1)", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px" }}>
                Опыт работы
            </Typography>
            {experience.map((ex, index) => (
                <Box
                    key={index}
                    sx={{
                        borderRadius: "8px",
                        position: "relative",
                    }}
                >
                    {index !== 0 && <Divider sx={{ borderBottom: "1px solid rgba(235, 238, 239, 1)", mb: "24px" }} />}
                    <Grid container spacing={3}>
                        {/* Название компании */}
                        <Grid item xs={12} md={6}>
                            <LabeledInput
                                label="Название компании"
                                placeholder="Введите название организации"
                                value={ex.company}
                                onChange={(e) => handleFieldChange(index, "company", e.target.value)}
                                required
                            />
                        </Grid>

                        {/* Должность */}
                        <Grid item xs={12} md={6}>
                            <LabeledInput
                                label="Должность"
                                placeholder="Введите название должности"
                                value={ex.position}
                                onChange={(e) => handleFieldChange(index, "position", e.target.value)}
                                required
                            />
                        </Grid>

                        {/* Начало работы */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ mb: 1, fontSize: "16px" }}>
                                Начало работы
                            </Typography>
                            <Box display="flex" gap={2}>
                                <FormControl fullWidth>
                                    <Select
                                        value={ex.startMonth || ""}
                                        onChange={(e) => handleFieldChange(index, "startMonth", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Месяц
                                        </MenuItem>
                                        {months.map((month, idx) => (
                                            <MenuItem key={idx} value={String(idx + 1).padStart(2, "0")}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Select
                                        value={ex.startYear}
                                        onChange={(e) => handleFieldChange(index, "startYear", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Год
                                        </MenuItem>
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* Окончание работы */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ mb: 1, fontSize: "16px"  }}>
                                Окончание работы
                            </Typography>
                            <Box display="flex" gap={2}>
                                <FormControl fullWidth>
                                    <Select
                                        value={ex.endMonth || ""}
                                        onChange={(e) => handleFieldChange(index, "endMonth", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Месяц
                                        </MenuItem>
                                        {months.map((month, idx) => (
                                            <MenuItem key={idx} value={String(idx + 1).padStart(2, "0")}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Select
                                        value={ex.endYear || ""}
                                        onChange={(e) => handleFieldChange(index, "endYear", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Год
                                        </MenuItem>
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* Описание */}
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    color: "#151813",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                Описание
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                value={experience.description}
                                onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                                variant="outlined"
                                sx={{
                                    backgroundColor: "#F8F9F9",
                                    borderRadius: "8px",
                                    border: "1px solid rgba(226, 228, 231, 1)",
                                    "&:hover": {
                                        borderColor: "#BDBDBD",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "none",
                                        },
                                    },
                                    mb: "6px",
                                }}
                            />
                            <Typography sx={{ fontSize: "14px", color: "rgba(116, 116, 115, 1)", lineHeight: "18px" }}>
                                Не менее 150 символов
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Кнопки */}
                    <Box sx={{display: "flex", justifyContent: "space-between", flexDirection: "row-reverse", mt: 3}}>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => handleRemoveWorkExperience(index)}
                            disabled={experience.length === 1}
                            sx={{
                                textTransform: "none",
                                fontWeight: 500,
                                padding: "16px 24px",
                                border: "1px solid transparent",
                                color: "rgba(255, 59, 48, 1)",
                                fontSize: "18px",
                                lineHeight: "22px",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 243, 242, 1)",
                                    color: "rgba(228, 53, 43, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "transparent",
                                    color: "rgba(198, 46, 37, 1)",
                                }
                            }}
                        >
                            Удалить
                        </Button>
                        {index === experience.length - 1 && <Box
                            component = "button"
                            variant="contained"
                            color="success"
                            onClick={handleAddWorkExperience}
                            sx={{
                                textTransform: "none",
                                fontWeight: 500,
                                padding: "16px 24px",
                                border: "1px solid rgba(89, 157, 21, 1)",
                                color: "rgba(89, 157, 21, 1)",
                                fontSize: "18px",
                                lineHeight: "22px",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(244, 247, 242, 1)",
                                    borderColor: "rgba(80, 140, 19, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "rgba(205, 221, 198, 1)",
                                    borderColor: "rgba(69, 122, 16, 1)",
                                }
                            }}
                        >
                            Добавить место работы
                        </Box>}
                    </Box>
                </Box >
            ))}
        </Box >
    );
};

export default WorkExperienceForm;

import { useState, useCallback} from "react"
import { Box, Typography } from "@mui/material";
import { EditingCard, DepartmentCard } from "./DepartmentCards";
import CreateDepartment from "./CreateDepartment";
import { ArrowUpIcon, PlusIcon } from "../../components/svgComponents";
import { updateDepartment, deleteDepartment } from "../../services/departmentsApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loading } from "../tapHrStyledComponents";
import { DeleteModalWindow } from "../common/modalWindows/deleteModalWindow";
const DepartmentsPanel = ({ companyName, departments = [], queryKey}) => {
    const [open, setOpen] = useState(true);
    const [deleteModalWindow, setDeleteModalWindow] = useState({index: null, idx: null});
    const [inputActive, setInputActive] = useState(false);
    const [id, setId] = useState(null);
    const queryClient = useQueryClient();

    const handleDataChange = useCallback((data) => {
        queryClient.setQueryData(queryKey, (oldData) => {
            return {
                ...oldData,
                pages: [data]
            }
        });
    }, [queryClient, queryKey]);

    const handleDataEdit = useCallback((data) => {
        queryClient.setQueryData(queryKey, (oldData) => {
            const pageIndex = data?.meta?.current_page - 1;
            return {
                ...oldData,
                pages: oldData.pages.map((page, index) => {
                    if (index === pageIndex) {
                        return data; // Replace the specific page with the updated data
                    }
                    return page; // Keep other pages unchanged
                }),
            };
        });
    }, [queryClient, queryKey]);

    const updateMutation = useMutation(updateDepartment, {
        onSuccess : (data) => {
            handleDataEdit(data);
            setId(null);
        }
    });

    const { mutate: deleteDepartmentMutate } = useMutation(deleteDepartment, {
        onSuccess: (data) => {
            handleDataChange(data);
        },
    });

    const handleEdit = (departmentId, name, index) => {
        const page = Math.floor(index / 10) + 1;
        updateMutation.mutate({ id: departmentId, name: name, page });
    };

    const handleDelete = useCallback((departmentId) => {
        deleteDepartmentMutate({ id: departmentId });
    }, [deleteDepartmentMutate]);

    const handleAdd = () => {
        if (!inputActive) {
            setInputActive(true);
        }
    };

    return (
        <Box sx={{ width: "100%", borderRadius: "8px" }}>
            {/* Header */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(226, 228, 231, 1)",
                    height: "52px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <ArrowUpIcon
                    onClick={() => setOpen((prev) => !prev)}
                    sx={{
                        transform: open ? "rotate(180deg)" : "rotate(90deg)",
                        width: 18,
                        height: 18,
                        cursor: "pointer",
                    }}
                    svgSx={{
                        width: 10,
                        height: 8,
                        color: "rgba(134, 140, 146, 0.5)",
                        hoverColor: "rgba(134, 140, 146, 0.75)",
                        pressedColor: "rgba(134, 140, 146, 1)",
                    }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {companyName || "Отделы вашей компании"}
                </Typography>
                </Box>
                <PlusIcon
                onClick={handleAdd}
                sx={{ width: 46, height: 46, cursor: "pointer", borderRadius: "8px", "&:hover" : {backgroundColor: "rgba(242, 243, 243, 1)"} }}
                svgSx={{
                    width: 10,
                    height: 10,
                    color: "rgba(46, 64, 82, 1)",
                    hoverColor: "rgba(41, 57, 73, 1)",
                    pressedColor: "rgba(36, 50, 64, 1)",
                }}
                />
            </Box>

            {/* Departments */}
            {open &&
                (<>
                    {inputActive && (<CreateDepartment setInputActive={setInputActive} handleDataChange={handleDataChange}/>)}
                    {departments.map((department, index) => {
                        if (department.id === id && updateMutation.isLoading) {
                            return <Loading key={index} sx ={{width: "calc(100% - 36px)", ml: "36px", justifyContent: "space-between", height: "52px", borderBottom: "1px solid rgba(226, 228, 231, 1)", color: "rgba(89, 157, 21, 1)"}}/>;
                        }

                        return id !== null && department.id === id ? (
                            <EditingCard
                                key={index}
                                department={department}
                                handleEdit={handleEdit}
                                handleModalWindow={setDeleteModalWindow}
                                setId={setId}
                                index = {index}
                            />
                        ) : (
                            <DepartmentCard
                                key={index}
                                department={department}
                                isLast={index === departments.length - 1}
                                handleModalWindow={setDeleteModalWindow}
                                setId={setId}
                                index = {index}
                            />
                        );
                    })}
                </>)}

            {deleteModalWindow.index !== null && deleteModalWindow.index < departments.length && deleteModalWindow.idx !== null && (
                <DeleteModalWindow 
                    text = {departments[deleteModalWindow.index].name} 
                    title = "отдела"
                    subtitle="этот отдел" 
                    handleDelete = {() => {
                        handleDelete(deleteModalWindow.idx)
                        setDeleteModalWindow({index : null, idx : null})
                    }} 
                    handleClose={() => setDeleteModalWindow({index : null, idx : null})}/>
            )}
        </Box>
    );
};

export default DepartmentsPanel;
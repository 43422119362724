
import { CloseIcon, PlusIcon } from "../../svgComponents";
import { Dialog, Box, DialogContent, DialogActions, TextField, Button, IconButton, Divider, Typography } from "@mui/material";
import { useState } from "react";
import CustomCheckboxAutoComplete from "../../styledComponents/CustomCheckboxAutoComplete";
import { fetchStages } from "../../../services/stagesApi";


const StagesModalWindow = ({ handleClose, handleAddStages, orderStart }) => {
    const [values, setValues] = useState([]);
    const handleSendingText = () => {
        if (values.length > 0) {
            handleAddStages(values);
        }
        handleClose(); // close modal window
    }


    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "440px",
                    height: "650px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                },
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "8px",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <PlusIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                border: "1px solid rgba(89, 157, 21, 1)",
                            }}
                            svgSx={{
                                width: 10,
                                height: 10,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    Добавить этап
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "20px",
                        marginBottom: "24px",
                        color: "rgba(116, 116, 115, 1)",
                    }}
                >
                    Отказы добавлены в ваш список по умолчанию
                </Typography>
                <CustomCheckboxAutoComplete
                    values={values}
                    orderStart={orderStart}
                    setValues={setValues}
                    queryKeyStartingValue={["stages"]}
                    fetchFunction={fetchStages}
                    additional={{purpose: 1, isDefault: 0}}
                />
            </Box>
            <DialogActions sx={{ justifyContent: "center", mt: 3, padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "12px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSendingText}
                    variant="contained"
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "12px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            boxShadow: "none !important",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StagesModalWindow;
import React, { useState, useRef, useCallback } from "react";
import {
    Dialog,
    Box,
    DialogActions,
    Button,
    Typography,
    Slider,
} from "@mui/material";
import { CloseIcon, UrlIcon, ImageIcon } from "../../svgComponents";
import BackgroundImg from "../../../assets/images/careerSiteImgBack.jpg";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/cropImages";

const IMAGE_BOX_CONTAINER_WIDTH = 512;
const IMAGE_BOX_CONTAINER_HEIGHT = 300;

const CROP_IMAGE_ASPECT_RATIO = 13 / 4;

const CareerSiteUploadImgModal = ({ handleClose, uploadedFile, handleCroppedFile }) => {
    const uploadedImage = useRef(URL.createObjectURL(uploadedFile));
    const [zoomValue, setZoomValue] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);


    const handleZoomChange = (event, newValue) => {
        setZoomValue(newValue);
    };

    // -------------------------Saving functionality for image-------------------------
    const handleSave = async () => {
        const croppedImage = await getCroppedImg(uploadedImage.current, croppedAreaPixels);
        handleCroppedFile(croppedImage);
    };
    // -----------------------------------------------------------------------------------

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "560px",
                    height: "646px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "24px",
                    maxWidth: "unset",
                    maxHeight: "unset",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "16px",
                    }}
                >
                    <UrlIcon
                        sx={{
                            width: 48,
                            height: 48,
                            border: "1px solid rgba(229, 230, 231, 1)",
                            boxShadow: "0px 1px 2px rgba(10, 13, 18, 0.05)",
                            borderRadius: "10px",
                        }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(41, 57, 73, 1)",
                        }}
                    />
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                    }}
                >
                    Загрузка изображения на фон
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "20px",
                        color: "rgba(116, 116, 115, 1)",
                    }}
                >
                    Загрузите изображение размером 1920 х 1080px для лучшего
                    результата.
                </Typography>
                <Box
                    sx={{
                        width: `${IMAGE_BOX_CONTAINER_WIDTH}px`,
                        height: `${IMAGE_BOX_CONTAINER_HEIGHT}px`,
                        borderRadius: "8px",
                        backgroundImage: `url(${BackgroundImg})`,
                        border: "1px solid rgba(229, 230, 231, 1)",
                        overflow: "hidden",
                        position: "relative",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Cropper
                        image={uploadedImage.current}
                        crop={crop}
                        zoom={zoomValue}
                        aspect= {CROP_IMAGE_ASPECT_RATIO}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        style={{
                            cropAreaStyle: {
                                border: '2px solid #599D15',
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                }}
            >
                <ImageIcon
                    sx={{ width: 24, height: 24 }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(173, 181, 189, 1)",
                    }}
                />
                <Slider
                    value={zoomValue}
                    onChange={handleZoomChange}
                    min={1}
                    max={3}
                    step={0.1}
                    sx={{
                        flex: 1,
                        color: "rgba(89, 157, 21, 1)",
                        height: "8px",
                        "& .MuiSlider-rail": {
                            color: "rgb(182, 182, 182)",
                        },
                        "& .MuiSlider-thumb": {
                            backgroundColor: "#fff",
                            border: "1px solid rgba(89, 157, 21, 1)",
                        },
                        "& .MuiSlider-thumb:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            border: "none",
                        },
                        "& .MuiSlider-thumb::focus": {
                            boxShadow: "none",
                        },
                        "& .MuiSlider-thumb input::focus": {
                            boxShadow: "none",
                        },
                    }}
                />
                <ImageIcon
                    sx={{ width: 32, height: 32 }}
                    svgSx={{
                        width: 27,
                        height: 27,
                        color: "rgba(173, 181, 189, 1)",
                    }}
                />
            </Box>
            <DialogActions sx={{ justifyContent: "center", padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CareerSiteUploadImgModal;

import { useCallback, useRef, useEffect } from "react";
import { Divider, Typography, Box } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { fetchDepartments } from "../../../services/departmentsApi";
import { Loading, Error } from "../../../components/tapHrStyledComponents";
import DepartmentsPanel from "../../../components/departments/DepartmentsPanel";
import { getMe } from "../../../services/main";

const DepartmentsPage = () => {
    const queryKey = useRef(["departments"]);
    const observerRef = useRef(null);
    
    const {
        data: departmentsData,
        isLoading: isDepartmentsDataLoading,
        isError: isDepartmentsDataError,
        error: departmentsDataError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        queryKey.current,
        ({ pageParam = 1 }) => fetchDepartments("", pageParam),
        {
            getNextPageParam: (lastPage) => {
                const { meta } = lastPage;
                const currentPage = parseInt(meta.current_page, 10);
                const totalPages = Math.ceil(meta.total / meta.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
        }
    );

    const {data : personalData, isLoading: isPersonalDataLoading, isError : isPersonalDataError, error: personalDataError} = useQuery(['getMe'], getMe, {
        refetchOnWindowFocus: false,
    });

    const getCompanyName = useCallback(() => {
        if (personalData?.data?.companies?.length > 0) {
            const companyId = localStorage.getItem("company_id") || null;
            const companyName = personalData?.data.companies.find((company) => company.company_id === +companyId)?.company_name;
            return companyName;
        }
        return null;
    }, [personalData]);

    const isLoading = isDepartmentsDataLoading || isPersonalDataLoading;
    const isError = isDepartmentsDataError || isPersonalDataError;
    const error = departmentsDataError || personalDataError;

    const flatData = (departmentsData && departmentsData.pages && departmentsData.pages.flatMap((page) => page?.data || page)) || [];

    useEffect(() => {
        if (!hasNextPage || isFetchingNextPage) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    fetchNextPage();
                }
            },
            { threshold: 1.0 }
        );

        const currentRef = observerRef.current; // Store the current ref value

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    return (
        <PageContainer>
            <Box
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                }}
            >
                <Box
                sx={{ py: 1, display: "flex", flexDirection: "column", gap: "16px" }}
                >
                <Typography
                    variant="h5"
                    sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
                >
                    Отделы
                </Typography>
                <Divider sx={{ backgroundColor: "rgba(235, 238, 239, 1)" }} />
                </Box>
                {(isLoading) && <Loading />}
                {(isError) && <Error error={error.message || "Неопознанная причина"} />}
                {departmentsData && personalData && (
                    <>
                        <DepartmentsPanel
                            companyName={getCompanyName()}
                            departments={flatData}
                            queryKey={queryKey.current}
                        />
                        {isFetchingNextPage && <Loading />}
                        <div ref={observerRef} />
                    </>
                )}
            </Box>
        </PageContainer>
    );
};

export default DepartmentsPage;

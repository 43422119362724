import React, { useState } from 'react';
import { Box, OutlinedInput, Typography, Button, Paper, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Для редиректа
import BackgroundImage from '../../assets/images/signInBg.png';
import { useLogin } from '../../hooks/useLogin';
import { getMe } from '../../services/main';
import { setSelectedCompany } from '../../services/accountApi';

const SignInPage = () => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [user, setUser] = useState(null);
    const loginMutation = useLogin();
    const navigate = useNavigate(); // Для редиректа

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
    };

    const handleLogin = () => {
        setError('');
        loginMutation.mutate(credentials, {
            onSuccess: async () => {
                try {
                    const userData = await getMe();
                    setUser(userData);

                    const companies = userData?.data?.companies || [];
                    if (companies.length > 1) {
                        // Редирект на страницу выбора аккаунта
                        navigate('/select_account');
                    } else if (companies.length === 1) {
                        // Устанавливаем единственную компанию как выбранную
                        const selectedCompany = companies[0];
                        setSelectedCompany({
                            company_id: selectedCompany.company_id,
                            manager_id: selectedCompany.manager_id,
                            company_domain: selectedCompany.company_domain,
                            is_hh_connected: selectedCompany.is_hh_connected,
                        });
                        // Здесь можно редиректить на нужную страницу
                        navigate('/'); // Например, на дашборд
                    } else {
                        setError('У пользователя нет доступных компаний');
                    }
                } catch (err) {
                    setError('Не удалось загрузить данные пользователя');
                    console.error(err);
                }
            },
            onError: (err) => {
                setError(err.response?.data?.error || 'Произошла ошибка при авторизации');
            },
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: '#E9F1E3',
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F4F5F7',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '508px',
                        padding: '40px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        position: 'relative',
                        marginTop: '80px',
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#599D15',
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: '-80px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        tapHR
                    </Typography>

                    <Typography variant="h5" sx={{ fontWeight: '600', mb: 1 }}>
                        Вход
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#A2A1A8', mb: 4 }}>
                        Введите логин и пароль для входа в систему
                    </Typography>

                    <Box
                        component="form"
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}
                        onKeyDown={handleKeyDown}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                            <Typography sx={{ color: '#151813', fontSize: '16px', lineHeight: '20px', fontWeight: 500, mb: 0.5 }}>
                                Логин
                            </Typography>
                            <OutlinedInput
                                name="email"
                                placeholder="Введите логин"
                                fullWidth
                                value={credentials.email}
                                onChange={handleInputChange}
                                sx={{
                                    backgroundColor: '#F8F9F9',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    height: '52px',
                                    width: '100%',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#E2E4E7',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#E2E4E7',
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                            <Typography sx={{ color: '#151813', fontSize: '16px', lineHeight: '20px', fontWeight: 500, mb: 0.5 }}>
                                Пароль
                            </Typography>
                            <OutlinedInput
                                type="password"
                                name="password"
                                placeholder="Введите пароль"
                                fullWidth
                                value={credentials.password}
                                onChange={handleInputChange}
                                sx={{
                                    backgroundColor: '#F8F9F9',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    height: '52px',
                                    width: '100%',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#E2E4E7',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#E2E4E7',
                                    },
                                }}
                            />
                        </Box>
                        {error && (
                            <Alert severity="error" sx={{ mb: 0 }}>
                                {error}
                            </Alert>
                        )}
                    </Box>

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleLogin}
                        sx={{
                            backgroundColor: '#599D15',
                            padding: '16px',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: '#4A8B12',
                            },
                        }}
                        disabled={loginMutation.isLoading}
                    >
                        {loginMutation.isLoading ? 'Загрузка...' : 'Войти'}
                    </Button>
                </Paper>
            </Box>
        </Box>
    );
};

export default SignInPage;

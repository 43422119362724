import { Modal, Box, Typography } from '@mui/material';
import { TrashIcon, CloseIcon } from '../../svgComponents';

const StyledButton = ({ text, onClick = () => {}, isDangerous }) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            sx={{
                borderRadius: '8px',
                border: isDangerous ? "1px solid transparent" : "1px solid rgba(46, 64, 82, 1)",
                padding: '12px 20px',
                color: isDangerous ? "white" : "rgba(46, 64, 82, 1)",
                backgroundColor: isDangerous ? "rgba(255, 59, 48, 1)" : "transparent",
                fontSize: '16px',
                lineHeight: '20px',
                cursor: 'pointer',
                "&:hover": {
                    backgroundColor: isDangerous ? "rgba(228, 53, 43, 1)" : "rgba(242, 243, 243, 1)"
                },
                "&:active": {
                    backgroundColor: isDangerous ? "rgba(198, 46, 37, 1)" : "rgba(226, 228, 231, 1)"
                },
                flex: 1,
            }}
        >
            {text}
        </Box>
    );
};

export const DeleteModalWindow = ({ text, title, subtitle, handleDelete, handleClose }) => {

    return (
        <Modal open={true} onClose={handleClose}>
            <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    border: 'none !important',
                    borderRadius: "8px",
                    maxWidth: 520,
                    maxHeight: 266,
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "24px",
                    outline: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <TrashIcon sx={{ width: 48, height: 48, backgroundColor: "rgba(255, 230, 229, 1)", border: "8px solid rgba(255, 243, 242, 1)", borderRadius: "50%" }} svgSx={{ width: 18, height: 20, color: "rgba(228, 53, 43, 1)" }} />
                    <CloseIcon onClick={handleClose} sx={{ width: 24, height: 24, cursor: "pointer" }} svgSx={{ width: 20, height: 20, color: "rgba(0, 0, 0, 0.5)", hoverColor: "rgba(0, 0, 0, 0.75)", pressedColor: "rgba(0, 0, 0, 1)" }} />
                </Box>
                <Typography
                    sx={{
                        fontWeight: "600",
                        color: "rgba(0, 0, 0, 1)",
                        fontSize: "20px",
                        lineHeight: "24px"
                    }}
                >
                    Удаление {title}
                    <Typography
                        component="span"
                        sx={{
                            display: "block",
                            fontWeight: "600",
                            color: "rgba(0, 0, 0, 1)",
                            fontSize: "20px",
                            lineHeight: "24px"
                        }}
                    >
                        "{text}"
                    </Typography>
                </Typography>
                <Typography
                    sx={{
                        fontWeight: "400",
                        color: "rgba(116, 116, 115, 1)",
                        fontSize: "16px",
                        lineHeight: "20px",
                        mb: 1,
                    }}
                >
                    Вы действительно хотите удалить {subtitle}?
                </Typography>
                <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                    <StyledButton text="Отмена" onClick={handleClose} />
                    <StyledButton text="Удалить" onClick={handleDelete} isDangerous={true} />
                </Box>
            </Box>
        </Modal>
    );
};

import { navigateToHH } from "../../utils/connections";
import { ProfileIcon } from "../svgComponents";
import { Box, Button, Typography } from "@mui/material";

const IntegrationCard = ({ isActive, onClick }) => {
    return (
        <Box
            sx={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                backgroundColor: '#F9FAFB',
                borderRadius: '8px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                maxWidth: '348px',
                width: '100%',
            }}
        >
            {/* First Element */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <ProfileIcon
                    sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "rgba(232, 239, 228, 1)" }}
                    svgSx={{ width: "20px", height: "20px", color: "rgba(89, 157, 21, 1)" }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            lineHeight: '22px',
                        }}
                    >
                        HeadHunter
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: 'rgba(155, 162, 169, 1)',
                        }}
                    >
                        Биржа вакансий
                    </Typography>
                </Box>
            </Box>

            {/* Second Element */}
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'rgba(116, 116, 115, 1)',
                }}
            >
                Публикуйте вакансии напрямую с TapHR.
            </Typography>

            {/* Third Element */}
            <Button
                disabled={isActive}
                onClick={onClick}
                sx={{
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: isActive ? 'rgba(46, 64, 82, 1)' : 'rgba(89, 157, 21, 1)',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'rgba(255, 255, 255, 1) !important',
                    padding: "12px 20px",
                    '&:hover': {
                        backgroundColor: 'rgba(80, 140, 19, 1)',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(69, 122, 16, 1)',
                    },
                }}
            >
                {isActive ? 'Подключено' : 'Подключить'}
            </Button>
        </Box>
    );
};

const PossibleIntegrations = () => {
    const isHHConnected = JSON.parse(localStorage.getItem('is_hh_connected')) === true;
    return (
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start' }}>
            <IntegrationCard isActive={isHHConnected} onClick={navigateToHH} />
        </Box>
    )
}

export default PossibleIntegrations;
// NavigationItem.js
import React from 'react';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const NavigationItem = ({ icon, label, isActive, isSubActive, onClick, arrow, isDisabled, hidden, divider }) => {
    if (hidden) return null; // Условное рендеринг

    if (isSubActive) {
        isActive = true;
    }

    return (
        <>
            {divider && <Divider sx={{marginTop: "8px", marginBottom: "24px", backgroundColor: "rgba(248, 249, 249, 1)"}}/>}
            <ListItem
                content = "button"
                onClick={onClick}
                sx={{
                    paddingY: 1,
                    paddingLeft: "16.6px",
                    paddingRight: 0,
                    cursor: 'pointer',
                    marginBottom: '16px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    pointerEvents: isDisabled ? 'none' : 'auto',
                    backgroundColor: isActive ? '#F0F9E7' : 'transparent',
                    borderLeft: isActive && !isSubActive ? '3px solid rgba(89, 157, 21, 1)' : '3px solid transparent',
                    '&:hover': {
                        backgroundColor: isActive ? '#F0F9E7' : (isDisabled ? 'transparent' : '#F8F9F9'),
                    },
                    height: "48px",
                }}
            >
                <ListItemIcon sx={{ minWidth: 24, marginRight: label ? '16px' : "0" }}>
                    <img
                        src={icon}
                        alt={`${label} icon`}
                        style={{
                            filter: isActive
                                ? 'invert(38%) sepia(74%) saturate(472%) hue-rotate(66deg) brightness(90%) contrast(83%)'
                                : 'none',
                            opacity: isDisabled ? 0.5 : 1,
                        }}
                    />
                </ListItemIcon>
                {label && <ListItemText
                    primary={label}
                    primaryTypographyProps={{ fontWeight: "600", color: isActive ? '#599D15' : isDisabled ? "rgba(226, 228, 231, 1)" : '#151813', width: 'fit-content' }}
                />}
                {arrow && arrow}
            </ListItem>
        </>
    );
};

export default NavigationItem;

import React from 'react';
import { Box, Typography, Divider, Paper } from '@mui/material';

// Функция для форматирования зарплаты
function formatCurrency(value, isCurrency) {
    if (!value) return ''; // Если значение пустое, возвращаем пустую строку
    return `${Number(value).toLocaleString('en-US')} ${isCurrency ? '₸' : ''}`; // Format the number as currency
}

const ShowVacancySalary = ({ vacancyFrom, vacancyTo }) => {
    console.log(vacancyFrom, vacancyTo);
    return (
        <Box sx={{ backgroundColor: "rgba(248, 249, 249, 1)", borderRadius: "8px", padding: "16px", border: "1px solid rgba(173, 181, 189, 1)" }}>
            {(vacancyFrom === vacancyTo) && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    {vacancyFrom}
                </Typography>
            )}
            {(vacancyFrom || vacancyTo) && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    {vacancyFrom ? `${formatCurrency(vacancyFrom)} - ` : 'до'} {formatCurrency(vacancyTo, true)}
                </Typography>
            )}
            {!vacancyFrom && !vacancyTo && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#16151C',
                        marginTop: '5px'
                    }}
                >
                    Не указано
                </Typography>
            )}
        </Box>
    )
};

const DescriptionTab = ({ vacancy }) => {
    // Fallback description in case it's undefined
    const descriptionContent = vacancy.description || "<p>Описание отсутствует.</p>";

    return (
        <Box sx={{ mt: "36px", display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box>
                <Typography
                    sx={{
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 400,
                        mb: 1,
                    }}
                >
                    Зарплата
                </Typography>
                <ShowVacancySalary vacancyFrom={vacancy.salary_from} vacancyTo={vacancy.salary_to} />
            </Box>

            <Box>
                <Typography
                    sx={{
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 400,
                        mb: 1,
                    }}
                >
                    Описание
                </Typography>
                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        backgroundColor: "rgba(248, 249, 249, 1)", 
                        borderRadius: "8px", 
                        padding: "10px 14px", 
                        border: "1px solid rgba(173, 181, 189, 1)"
                    }}
                >
                    <Box
                        sx={{
                            '& ul': {
                                listStyleType: 'disc',
                                marginLeft: '18px',
                                width: "calc(100% - 20px)",
                            },
                            '& li': {
                                width: "100%",
                                whiteSpace: "normal", /* Allow text wrapping */
                                wordWrap: "break-word", /* Break long words onto a new line */
                                wordBreak: "break-word", /* Ensure words wrap even if very long */
                            }
                        }}
                    >
                        {/* HTML-содержимое description */}
                        <Typography
                            sx={{ fontSize: '16px', lineHeight: '20px', color: '#151813', padding: 0 }}
                            dangerouslySetInnerHTML={{ __html: descriptionContent }}
                        />
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default DescriptionTab;

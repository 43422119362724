import axiosClassic from "../api/interceptors";

// Получение уведомления по уникальному ключу
export const fetchNotificationByUniqueKey = async (managerId, uniqueKey) => {
    try {
        const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications/get`, {
            params: {
                manager_id: managerId,
                unique_key: uniqueKey,
            },
        });
        return response.data; // Возвращаем данные уведомления
    } catch (error) {
        console.error("Ошибка при получении уведомления по уникальному ключу:", error);
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Получение списка уведомлений
export const fetchUserNotifications = async () => {
    try {
        const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications`);
        return response.data; // Возвращаем массив уведомлений
    } catch (error) {
        console.error("Ошибка при получении уведомлений:", error);
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Получение количества непрочитанных уведомлений
export const fetchUnreadNotificationsCount = async () => {
    try {
        const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications/count`);

        return response.data; // Предполагаем, что возвращается число
    } catch (error) {
        console.error("Ошибка при получении количества уведомлений:", error);
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Просмотр конкретного уведомления по уникальному ключу
export const markNotificationAsRead = async (managerId, uniqueKey) => {
    try {
        const response = await axiosClassic.post("/user-notifications/mark-as-read", {
            manager_id: managerId,
            unique_key: uniqueKey,
        });
        return response.data; // Возвращаем результат обновления
    } catch (error) {
        console.error("Ошибка при обновлении уведомления:", error);
        throw error; // Пробрасываем ошибку для обработки
    }
};

import { memo, useRef, useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCareerSiteSocialLinks, updateCareerSiteData, updateCareerSiteSocialLinks, uploadCareerSiteBackgroundImage } from "../../../services/careerSiteApi";
import SocialLinks from "./SocialLinks";
import PageCustomization from "./ColorCustomization";


/*
    This component has following sections 
        1. Helper Functions
            a) handleSocialLinkChange
            b) getUpdatedLinks
        2. Mutations
            a) saveInfoMutation
            b) saveSocialLinksMutation
            c) saveBackgroundImageMutation
            d) deleteSocialLinksMutation
        3. Mutations Observer
            a) incrementPendingMutations
            b) decrementPendingMutations
        4. Main Component
*/
const BottomPanel = memo(({queryKey, data, setData, handleCancel }) => {
    const queryClient = useQueryClient();
    const [pendingMutations, setPendingMutations] = useState({changed: false, count: 0});
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (pendingMutations.count === 0) {
            if (errorMessage) {
                alert(errorMessage);
                handleCancel();
            } else if (pendingMutations.changed) {
                queryClient.setQueriesData(queryKey, data);
                alert("Изменения сохранены");
            }
        }
    }, [pendingMutations, errorMessage]);

    // ------------------------------------- Helper Functions -------------------------------------

    const handleSocialLinkChange = useCallback((index, value) => {
        setData(prev => ({
            ...prev, // Ensure the previous state is spread correctly into the new object
            social_links: prev.social_links.map((link, i) => 
                i === index ? { ...link, url: value } : link
            )
        }));
    }, []);    

    const getUpdatedLinks = () => {
        const oldData = queryClient.getQueryData(queryKey);
        const linkMap = new Map(
            Array.isArray(oldData?.social_links) 
                ? oldData.social_links.map(link => [link?.social_network, link?.url]) 
                : []
        );
    
        const updatedLinks = [];
        const deletedLinks = [];
        data.social_links.forEach(link => {
            const existingUrl = linkMap.get(link.social_network);
    
            if (link.url && link.url !== existingUrl) {
                // Add to updated links if URL is valid and different
                updatedLinks.push(link);
            } else if (!link.url && existingUrl) {
                // Add to deleted links if URL is empty and exists in the original links
                deletedLinks.push(link.id);
            }
        });
        return [updatedLinks, deletedLinks];
    };
    // ---------------------------------------------------------------------------------------
    

    // ------------------------------------- Mutations -------------------------------------
    const saveInfoMutation = useMutation(updateCareerSiteData, {
        onSuccess: () => {
            decrementPendingMutations();
        },
        onError: (error) => {
            decrementPendingMutations();
            if (!errorMessage) {
                setErrorMessage(`Ошибка сохранения информации: ${error.message}`);
            }
        },
    });

    const saveSocialLinksMutation = useMutation(updateCareerSiteSocialLinks, {
        onSuccess: () => {
            decrementPendingMutations();
        },
        onError: (error) => {
            decrementPendingMutations();
            if (!errorMessage) {
                setErrorMessage(`Ошибка сохранения информации: ${error.message}`);
            }
        },
    });

    const saveBackgroundImageMutation = useMutation(uploadCareerSiteBackgroundImage, {
        onSuccess: () => {
            decrementPendingMutations();
        },
        onError: (error) => {
            decrementPendingMutations();
            if (!errorMessage) {
                setErrorMessage(`Ошибка сохранения информации: ${error.message}`);
            }
        },
    });

    const deleteSocialLinksMutation = useMutation(deleteCareerSiteSocialLinks, {
        onSuccess: () => {
            decrementPendingMutations();
        },
        onError: (error) => {
            decrementPendingMutations();
            if (!errorMessage) {
                setErrorMessage(`Ошибка сохранения информации: ${error.message}`);
            }
        },
    });
    // ---------------------------------------------------------------------------------------

    // ------------------------------------- Mutations Observer -------------------------------------
    const incrementPendingMutations = () => setPendingMutations(prev => { return {changed: true, count: prev.count + 1}; });
    const decrementPendingMutations = () => {
        setPendingMutations(prev => {return {changed: true, count: prev.count - 1}; });
    };
    // ---------------------------------------------------------------------------------------

    const handleSave = () => {
        const oldData = queryClient.getQueryData(queryKey);
        if (data.name !== oldData?.name || data.description !== oldData?.description || data.color_scheme !== oldData?.color_scheme) {
            incrementPendingMutations();
            saveInfoMutation.mutate({
                ...(data.name !== oldData?.name ? { name : data.name } : {}),
                ...(data.description !== oldData?.description ? { description : data.description } : {}),
                ...(data.color_scheme !== oldData?.color_scheme ? { color_scheme: data.color_scheme } : {}),
            });
        }

        if (data.background_image !== oldData?.background_image) {
            incrementPendingMutations();
            saveBackgroundImageMutation.mutate(data.background_image);
        }


        const [updatedLinks, deletedLinks] = getUpdatedLinks();
        if (updatedLinks.length > 0) {
            incrementPendingMutations();
            console.log(updatedLinks);
            saveSocialLinksMutation.mutate({social_links : updatedLinks});
        }

        if (deletedLinks.length > 0) {
            incrementPendingMutations();
            console.log(deletedLinks);
            deleteSocialLinksMutation.mutate({ids : deletedLinks});
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "36px",
                mt: "36px",
                minWidth: "1124px",
            }}
        >
            {/* Кастомизация страницы */}
            <PageCustomization color_scheme={data.color_scheme} setData={setData} />
            
            {/* Социальные сети */}
            <SocialLinks socialLinks={data.social_links} onChange={handleSocialLinkChange} />

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}>
                <Box
                    component="button"
                    onClick={handleCancel}
                    sx={{
                        color: "rgba(255, 59, 48, 1)",
                        padding: "16px 24px",
                        border: "1px solid transparent",
                        fontSize: "18px",
                        lineHeight: "22px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(255, 243, 242, 1)",
                        },
                        "&:active": {
                            color: "rgba(198, 46, 37, 1)",
                        },
                    }}
                >
                    Отменить
                </Box>
                <Box
                    component="button"
                    sx={{
                        display: "none",
                        padding: "16px 24px",
                        border: "1px solid rgba(89, 157, 21, 1)",
                        color: "rgba(89, 157, 21, 1)",
                        fontSize: "18px",
                        lineHeight: "22px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(244, 247, 242, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(205, 221, 198, 1)",
                        },
                    }}
                >
                    Предпросмотр
                </Box>
                <Box
                    component="button"
                    onClick={handleSave}
                    sx={{
                        padding: "16px 24px",
                        border: "1px solid rgba(89, 157, 21, 1)",
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        fontSize: "18px",
                        lineHeight: "22px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Сохранить
                </Box>
            </Box>
        </Box>
    );
});


export default BottomPanel;
import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { PlusIcon } from '../svgComponents';
import { CloseIcon } from '../svgComponents';

const FilterSelect = ({ placeholder, selected, setSelected, keyName }) => {
    return (
      <TextField
        variant="outlined"
        fullWidth
        value={selected}
        onChange={(e) => setSelected((prev) => ({ ...prev, [keyName]: e.target.value }))} // Update the value when typing
        placeholder={placeholder}
        slotProps={{
          htmlInput: {
            autoComplete: "off", // Disable autocomplete
          },
          input: {
            endAdornment: selected && (
              <CloseIcon
                onClick={() => setSelected((prev) => ({ ...prev, [keyName]: "" }))}
				dontPreventDefault = {false}
                sx={{
					cursor: "pointer",
					width: 24,
					height: 24,
					position: "absolute",
					right: "0",
                }}
				svgSx={{
					width: 10,
					height: 10,
					color: "rgba(0,0,0,0.5)",
					hoverColor: "rgba(0,0,0,0.75)",
					pressedCollor: "rgba(0,0,0,1)",
				}}
              />
            ),
          },
        }}
        sx={{
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#fff",
          padding: "16px",
          border: "1px solid rgba(226, 228, 231, 1)",
          "& .MuiOutlinedInput-root": {
            paddingRight: "32px",
            position: "relative",
            "& input": {
				padding: "0",
				fontSize: "14px",
				lineHeight: "18px",
				color: "#000",
				border: "none",
				height: "24px",
				Autocomplete: false,
            },
            "& fieldset": {
              	border: "none", // Remove the border
            },
          },
          "&:hover": {
            	borderColor: "#599D15", // Hover border color
          },
          "&.MuiFormControl-root:focus-within": {
				border: "2px solid rgba(205, 221, 198, 1)",
				padding: "15px",
          },
        }}
      />
    );
};

const FilterBar = ({ handleOpen, hiddenSelects, label, filter, setFilter }) => { // Принимаем функцию для открытия
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "16px", backgroundColor: "transparent" }}>
            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                {!hiddenSelects && (
                    <>
                        {/* <FilterSelect placeholder="Рекрутер" /> */}
                        <FilterSelect selected={filter?.vacancy} setSelected={setFilter} keyName={"vacancy"} placeholder={"Вакансия"} />
                        <FilterSelect selected={filter?.region} setSelected={setFilter} keyName={"region"} placeholder={"Регион"} />
                        <FilterSelect selected={filter?.department} setSelected={setFilter} keyName={"department"} placeholder={"Отдел"}/>
                        {/* <Typography
                            sx={{
                                color: '#ADB5BD',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                            onClick={() => console.log("Сбросить фильтр")}
                        >
                            Сбросить фильтр
                        </Typography> */}
                    </>
                )}
            </Box>

            <Button
                variant="contained"
                startIcon={<PlusIcon sx = {{width: 16.67, height:16.67, backgroundColor: "white", borderRadius: "50%"}} svgSx = {{width: 13, height: 13, color: "rgba(89, 157, 21, 1)"}}/>}
                onClick={handleOpen} // Добавляем обработчик нажатия на кнопку
                sx={{
                    height: '56px',
                    backgroundColor: '#599D15',
                    padding: "16px 24px",
                    borderRadius: '8px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#4A8B12',
                    },
                }}
            >
                {label}
            </Button>
        </Box>
    );
};


export default FilterBar;

import React, { useState, useRef } from "react";
import {
    Box,
    List,
    Avatar,
    Typography,
    Chip,
    TextField,
    InputAdornment,
    CircularProgress,
    CardActionArea,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { fetchCandidates } from "../../services/candidatesApi";
import useDebounce from "../../hooks/useDebounce";

const CandidatesList = ({ selectedCandidateId, onSelectCandidateId }) => {
    const { debouncedValue : debouncedSearchQuery, setValue : setSearchQuery } = useDebounce("");
    const [page, setPage] = useState(1);
    const [candidates, setCandidates] = useState([]);
    const [pagination, setPagination] = useState(null); // Хранение meta данных
    const isFetchingNextPage = useRef(false); // Флаг, чтобы избежать дублирующихся запросов

    // Запрос данных кандидатов
    const { data, isLoading, isFetching } = useQuery(
        ["candidates", page, debouncedSearchQuery],
        () => fetchCandidates(page, debouncedSearchQuery),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                if (page === 1) {
                    setCandidates(data.candidates);
                } else {
                    setCandidates((prev) => [...prev, ...data.candidates]);
                }
                setPagination(data.pagination); // Сохраняем данные meta
                isFetchingNextPage.current = false; // Сбрасываем флаг после успешной загрузки
            },
        }
    );

    // Обработчик поиска
    const handleSearch = (value) => {
        setSearchQuery(value);
        setPage(1); // Сбрасываем на первую страницу
    };

    // Обработчик скролла
    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10; // Находимся ли мы внизу списка

        if (bottom && pagination && page < pagination.last_page && !isFetchingNextPage.current) {
            isFetchingNextPage.current = true; // Устанавливаем флаг, чтобы избежать дублирующих запросов
            setPage((prev) => prev + 1); // Загружаем следующую страницу
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            {/* Поле поиска */}
            <TextField
                placeholder="Введите имя"
                fullWidth
                variant="outlined"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 3,
                        backgroundColor: "#F9F9F9",
                        "& fieldset": {
                            borderColor: "#E0E0E0",
                        },
                        "&:hover fieldset": {
                            borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#6BA14A",
                        },
                    },
                }}
            />

            {/* Список кандидатов */}
            <Box
                onScroll={handleScroll} // Добавляем обработчик скролла
                sx={{
                    borderRadius: 2,
                    overflowY: "auto",
                    height: "70vh",
                    pr: 2,
                }}
            >
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2, // Отступ между карточками
                    }}
                >
                    {candidates.map((candidate) => (
                        <CardActionArea
                            key={candidate.id}
                            content = "button"
                            onClick={() => onSelectCandidateId(candidate.id)}
                            selected={selectedCandidateId === candidate.id}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                minWidth: "305px",
                                minHeight: "158px",
                                maxHeight: "fit-content",
                                border: selectedCandidateId === candidate.id ? "2px solid #6BA14A" : "1px solid #E0E0E0",
                                transition: "all 0.15s ease-in-out",
                                borderRadius: "10px",
                                padding: "16px 24px",
                                backgroundColor: "#FFFFFF",
                                "&.Mui-selected": {
                                    backgroundColor: "#F1F8E9",
                                    borderLeft: "4px solid #6BA14A",
                                },
                                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                                cursor: 'pointer',
                            }}
                        >
                            {/* Лого слева */}
                            <Avatar
                                src={candidate.avatar || ""}
                                alt={candidate.first_name}
                                sx={{ width: 40, height: 40 }}
                            />

                            {/* Информация справа */}
                            <Box sx={{ flex: 1, ml: 2 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 700,
                                        mb: "4px",
                                    }}
                                >
                                    {`${candidate.first_name} ${candidate.last_name}`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        mb: 1,
                                    }}
                                >
                                    {candidate.city_name || "Город не указан"}
                                </Typography>
                                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                                    {candidate.vacancies?.map((vacancy) => (
                                        <Chip
                                            key={vacancy.id}
                                            label={vacancy.name}
                                            size="small"
                                            sx={{
                                                border: "1px solid #D2D6DA",
                                                backgroundColor: "#F1F2F3",
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#151813",
                                                borderRadius: "4px",
                                                maxWidth: "200px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </CardActionArea>
                    ))}
                </List>
                {(isFetching) && <CircularProgress sx={{ display: "block", margin: "0 auto" }} />}
            </Box>
        </Box>
    );
};

export default CandidatesList;

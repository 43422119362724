// src/services/channelsApi.js

import axiosClassic from "../api/interceptors";

// Получение списка каналов
export const fetchChannels = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/settings/channel`);
    return response.data.data; // Возвращаем список каналов
};

// Создание нового канала
export const createChannel = async (channelData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    // Проверяем обязательные поля
    const { name, utm_source } = channelData;
    if (!name || !utm_source) {
        throw new Error("Название канала и UTM source обязательны для заполнения.");
    }

    const response = await axiosClassic.post(
        `/api/company/${companyId}/settings/channel`,
        channelData
    );

    return response.data; // Возвращаем результат создания
};

// Обновление канала
export const updateChannel = async (channelData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    console.log(channelData)
    if (!channelData.id) throw new Error("ID канала обязателен для обновления.");

    const response = await axiosClassic.put(
        `/api/company/${companyId}/settings/channel/${channelData.id}`,
        channelData
    );

    return response.data; // Возвращаем результат обновления
};

// Удаление канала
export const deleteChannel = async (channelId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    if (!channelId) throw new Error("ID канала обязателен для удаления.");

    const response = await axiosClassic.delete(
        `/api/company/${companyId}/settings/channel/${channelId}`
    );

    return response.data; // Возвращаем результат удаления
};

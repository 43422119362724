import React, { useState } from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import ShareLink from './ShareLink';
import ChannelList from './ChannelList';
import CustomSwitch from './CustomSwitch';
import InfoIcon from './InfoIcon';
import { useQuery } from '@tanstack/react-query';
import { fetchChannels } from '../../services/channelsApi';

const CareerChannelSettings = ({ vacancyId, is_visible }) => {
    // Используем TanStack Query для получения списка каналов
    const { data: channels, isLoading, error } = useQuery(['channels'], fetchChannels);

    const [selectedChannel, setSelectedChannel] = useState('');

    const handleChannelChange = (event) => {
        setSelectedChannel(event.target.value);
    };

    if (isLoading) {
        return <Typography>Загрузка каналов...</Typography>;
    }

    if (error) {
        return <Typography>Ошибка загрузки: {error.message}</Typography>;
    }

    return (
        <Box sx={{ padding: 1, paddingTop: '30px' }}>
            {/* Переключатель публикации */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, background: '#F8F9F9', padding: '18px', height: '56px', borderRadius: '8px' }}>
                <CustomSwitch value={is_visible} />
                <Typography sx={{ marginLeft: 2 }}>Опубликовано на карьерном сайте</Typography>
                <div style={{ marginLeft: 4 }}>
                    <InfoIcon />
                </div>
            </Box>

            {/* Секция "Поделиться ссылкой" */}
            <ShareLink channels={channels} vacancyId={vacancyId} />

            {/* Таблица каналов */}
            <div style={{ marginTop: '24px' }}>
                <ChannelList channels={channels} />
            </div>
        </Box>
    );
};

export default CareerChannelSettings;

import { TrashIcon, PencilIcon } from "../svgComponents";
import { TableRow, TableCell } from "@mui/material";
import { memo } from "react";
import { EDIT_STATUS } from "../../utils/statusesStorage";

const TemplatesRow = memo(({template, isEditable, handleDelete, handleModalWindow, index, isLast, hideAuthor}) => {
    const onDelete = () => {
        handleDelete({index, id : template?.id});
    }

    const onEdit = () => {
        handleModalWindow({status: EDIT_STATUS, template: template, index: index});
    }
    return (
        <TableRow>
            <TableCell sx={{width: "30svw", minWidth:"650px", padding: "16px 24px", fontSize: "16px", fontWeight: 500, lineHeight: "20px", ...(isLast && {borderBottom: "none"})}}>{template?.name || "Без названия"}</TableCell>
            <TableCell sx={{width: "10svw", minWidth: "150px", padding: "16px 24px", ...(isLast && {borderBottom: "none"})}}>{hideAuthor ? "" : template?.manager_name || "Без автора"}</TableCell>
            <TableCell
                align="center"
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "13px 16px",
                    gap: "4px",
                    "&:empty": {
                        "&::before": {
                            content: '""', // Add pseudo-content for empty cells
                            display: "block",
                            height: "46px",
                        },
                    },
                    ...(isLast && {borderBottom: "none"})
                }}
            >
                {isEditable && (
                    <>
                        <TrashIcon
                            onClick={onDelete}
                            sx={{
                                width: 46,
                                height: 46,
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 243, 243, 1)",
                                },
                            }}
                            svgSx={{
                                width: 23,
                                height: 23,
                                color: "rgba(21, 24, 19, 1)"
                            }}
                        />
                        <PencilIcon
                            onClick={onEdit}
                            sx={{
                                width: 46,
                                height: 46,
                                cursor: "pointer",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 243, 243, 1)",
                                },
                            }}
                            svgSx={{
                                width: 23,
                                height: 23,
                                color: "rgba(21, 24, 19, 1)"
                            }}
                        />
                    </>
                )}
            </TableCell>
        </TableRow>
    );
});

export default TemplatesRow
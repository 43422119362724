import {useState, memo} from 'react'
import { Typography, Box, Input,  } from '@mui/material';
import { PencilIcon, CheckIcon, TrashIcon } from '../svgComponents';

const TrashIconUi = ({handleModalWindow, index, idx}) => {
    return (
        <TrashIcon
            onClick={() => {
                handleModalWindow({index, idx});
            }}
            sx={{
                cursor: "pointer",
                width: 46,
                height: 46,
                "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                borderRadius: "8px",
            }}
            svgSx={{
                width: 20,
                height: 20,
                color: "rgba(21, 24, 19, 1)",
                hoverColor: "rgba(41, 57, 73, 1)",
                pressedColor: "rgba(36, 50, 64, 1)",
            }}
        />
    )
}

export const EditingCard = ({department, handleEdit, handleModalWindow, setId, index}) => {
    const [text, setText] = useState(department.name);

    const handleSave = () => {
        if (text.trim().length !== 0 && text.trim() !== department.name){
            handleEdit(department.id, text.trim(), index);
        } else {
            setId(null);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && text.trim()) {
            handleSave();
        }
    }

    const handleBlur = () => {
        console.log("unbluring")
        setId(null);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ml: 3,
                height: "52px",
            }}
        >
            <Input
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                autoFocus
                sx={{ 
                    ml: "12px", 
                    height: "52px", 
                    padding: 0,
                    "&::after": {
                        borderBottom: "2px solid rgba(89, 157, 21, 1)",
                    },
                }}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <CheckIcon 
                    onMouseDown={handleSave}
                    dontPreventDefault = {false}
                    sx={{
                        cursor: "pointer",
                        width: 46,
                        height: 46,
                        "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                        borderRadius: "8px",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(21, 24, 19, 1)",
                        hoverColor: "rgba(41, 57, 73, 1)",
                        pressedColor: "rgba(36, 50, 64, 1)",
                    }}
                />
                <TrashIconUi handleModalWindow={handleModalWindow} index={index} idx = {department.id}/>
            </Box>
        </Box>
    )
}

export const DepartmentCard = memo(({ department, isLast, handleModalWindow, setId, index }) => {
    console.log("Department card is rendering " + department.name)
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom:
                    isLast
                    ? "none"
                    : "1px solid rgba(226, 228, 231, 1)",
                ml: 3,
                height: "52px",
            }}
        >
            <Typography
                sx={{
                    ml: "12px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(21,24,19,1)",
                }}
            >
            {department.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <PencilIcon
                    onClick={() => {setId(department.id)}}
                    sx={{
                        cursor: "pointer",
                        width: 46,
                        height: 46,
                        "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                        borderRadius: "8px",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(21, 24, 19, 1)",
                        hoverColor: "rgba(41, 57, 73, 1)",
                        pressedColor: "rgba(36, 50, 64, 1)",
                    }}
                />
                <TrashIconUi handleModalWindow={handleModalWindow} index={index} idx = {department.id}/>
            </Box>
        </Box>
    )
})
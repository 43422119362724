import { format } from "date-fns";
import ru from "date-fns/locale/ru";

/**
 * 
 * @param {String} dateString - date in string format
 * @param {String} format - preferred format
 * @returns {String} - formatted date
 */
export const formatDate = (dateString, format = "DD.MM.YYYY", locale = "en") => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date)) return ""; // Handle invalid date

    // Helper function to capitalize the first letter
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const replacements = {
        MMMM: capitalize(date.toLocaleString(locale, { month: "long" })), // Full month name
        MMM: capitalize(date.toLocaleString(locale, { month: "short" })), // Abbreviated month name
        DD: String(date.getDate()).padStart(2, "0"),
        MM: String(date.getMonth() + 1).padStart(2, "0"),
        YYYY: date.getFullYear(),
        YY: String(date.getFullYear()).slice(-2),
        HH: String(date.getHours()).padStart(2, "0"),
        mm: String(date.getMinutes()).padStart(2, "0"),
        ss: String(date.getSeconds()).padStart(2, "0"),
    };

    return format.replace(/MMMM|MMM|DD|MM|YYYY|YY|HH|mm|ss/g, (match) => replacements[match] || match);
};


export const getTimeStampFomatted = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    
    if (
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
    ) {
        return `Сегодня в ${format(date, "HH:mm", { locale: ru })}`;
    } else if (
        date.getDate() === now.getDate() - 1 &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
    ) {
        return `Вчера в ${format(date, "HH:mm", { locale: ru })}`;
    } else {
        return format(date, "d MMMM, HH:mm", { locale: ru });
    }
};

    // Russian grammatical rules for years
    const getYearForm = (number) => {
        const absNumber = Math.abs(number);
        
        // Special cases for 11-14
        if (absNumber % 100 >= 11 && absNumber % 100 <= 14) {
            return 'лет';
        }
        
        // Last digit determines the form
        const lastDigit = absNumber % 10;
        
        if (lastDigit === 1) {
            return 'год';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'года';
        } else {
            return 'лет';
        }
    };

// Russian grammatical rules for months
const getMonthForm = (number) => {
    const absNumber = Math.abs(number);
    
    // Special cases for 11-14
    if (absNumber % 100 >= 11 && absNumber % 100 <= 14) {
        return 'месяцев';
    }
    
    // Last digit determines the form
    const lastDigit = absNumber % 10;
    
    if (lastDigit === 1) {
        return 'месяц';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return 'месяца';
    } else {
        return 'месяцев';
    }
};

/*
    * @param {String} start - start date
    * @param {String} end - end date
    * @returns {String} - duration in months
*/
export const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    const years = Math.floor(monthDiff / 12);
    const months = monthDiff % 12;
    let string = '';
    if (years) {
        string += `${years} ${getYearForm(years)}`;
        if (months) {
            string += ' и ';
        }
    }
    if (months) {
        string += ` ${months} ${getMonthForm(months)}`;
    }
    return `${string}`;
};
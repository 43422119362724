import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const AdditionalInformation = ({ description, handleChange }) => {
    return (
        <Box
            sx={{
                mt: 4,
                p: 3,
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 500,
                    mb: 2,
                }}
            >
                Дополнительная информация
            </Typography>
            <TextField
                placeholder="Введите дополнительную информацию"
                multiline
                rows={4}
                fullWidth
                value={description}
                name="description"
                onChange={handleChange}
                variant="outlined"
                sx={{
                    backgroundColor: "#F8F9F9",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                            borderColor: "#BDBDBD",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#599D15",
                        },
                    },
                }}
            />
        </Box>
    );
};

export default AdditionalInformation;

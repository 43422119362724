
import { CloseIcon, PlusIcon } from "../../svgComponents";
import { Dialog, Box, MenuItem, DialogActions, TextField, Button, Typography } from "@mui/material";
import { useState, useEffect,useCallback } from "react";

const CreateStagesModalWindow = ({ handleClose, handleAddStage }) => {
    const [purpose, SetPurpose] = useState("");
    const [name, setName] = useState("");

    const handleSubmit = useCallback(() => {
        if (!purpose || !name) {
            return;
        }
        handleAddStage(purpose, name);
        handleClose();
    }, [purpose, name, handleAddStage, handleClose]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault(); // Prevent default "Enter" behavior like form submission
                handleSubmit();
            }
        };

        // Attach the keydown event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleSubmit]); // Dependency array ensures the latest state values are used

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "403px",
                    minHeight: "420px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                },
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "8px",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <PlusIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                border: "1px solid rgba(89, 157, 21, 1)",
                            }}
                            svgSx={{
                                width: 10,
                                height: 10,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: "24px",
                    }}
                >
                    Создание этапа
                </Typography>
            </Box>
            <Box>
                <label style={{ fontSize: '16px', lineHeight: "20px", fontWeight: 500, marginBottom: '8px', display: 'block' }}>
                Тип этапа
                </label>
                <TextField
                    select
                    value={purpose}
                    onChange={(e) => SetPurpose(Number(e.target.value))}
                    fullWidth
                    sx = {{
                        "& .MuiOutlinedInput-input": {
                            padding: "16px",
                            border: "1px solid rgba(226, 228, 231, 1)",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            "&:focus": {
                                backgroundColor: "rgba(248, 249, 249, 1)",
                                border: "3px solid rgba(205, 221, 198, 1)",
                                padding: "14px",
                            }
                        },
                        "&:hover .MuiOutlinedInput-input": {
                            backgroundColor: "rgba(241, 242, 243, 1)",
                        },
                        "& fieldset": {
                            border: "none",
                        }
                    }}
                    slotProps={{
                        htmlInput: { autoComplete: "off" },
                        select: {
                            displayEmpty: true,
                            renderValue: (value) => {
                                let text = "";
                                let color = "#fff";
                                if (value === 1) {
                                    text = "Этап вакансии"
                                } else if (value === 2) {
                                    text = "Отказы"
                                } else {
                                    text = "Выберите из списка"
                                    color = "rgba(173, 181, 189, 1)";
                                }
                                return <Typography style={{ fontSize: '16px', lineHeight: "20px", color: {color} }}>{text}</Typography>;
                            }
                        }
                    }}
                >
                    <MenuItem value="1">Этап вакансии</MenuItem>
                    <MenuItem value="2">Отказы</MenuItem>
                </TextField>
            </Box>
            <Box>
                <label style={{ fontSize: '16px', lineHeight: "20px", fontWeight: 500, marginBottom: '8px', display: 'block' }}>
                Название этапа
                </label>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    fullWidth
                    placeholder="Введите название"
                    sx = {{
                        "& .MuiOutlinedInput-input": {
                            padding: "16px",
                            border: "1px solid rgba(226, 228, 231, 1)",
                            backgroundColor: "rgba(248, 249, 249, 1)",
                            borderRadius: "8px",
                            "&:focus": {
                                backgroundColor: "rgba(248, 249, 249, 1)",
                                border: "3px solid rgba(205, 221, 198, 1)",
                                padding: "14px",
                            }
                        },
                        "&:hover .MuiOutlinedInput-input": {
                            backgroundColor: "rgba(241, 242, 243, 1)",
                        },
                        "& fieldset": {
                            border: "none",
                        }
                    }}
                    slotProps={{
                        htmlInput: { autoComplete: "off" }
                    }}
                />
            </Box>
            <DialogActions sx={{ justifyContent: "center", padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            boxShadow: "none !important",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateStagesModalWindow;
const processContent = (predefinedVariables, content) => {
    // Regex to match variables in {{}} and spans
    const variableRegex = /{{\s*([a-zA-Zа-яА-ЯёЁіІңҢғҒұҰқҚөӨһҺ]+\.[a-zA-Zа-яА-ЯёЁіІңҢғҒұҰқҚөӨһҺ]+)\s*}}(?![^<]*<\/span>)/g; // Exclude those already inside <span>
    const spanRegex = /<span style=['"]color: blue;['"]>(.*?)<\/span>/g;

    //check if variables inside span are not valid anymore
    let processedContent = content.replace(spanRegex, (match, innerText) => {
        // Check if the span contains a valid variable
        if (!(innerText.includes("{{") && innerText.includes("}}"))) {
            return innerText; // Span does not contain a variable
        }
        
        innerText = innerText.replace(" ", "&nbsp;");

        // Match the first variable "{{...}}" and separate the rest
        const variableMatch = innerText.match(/{{\s*([^}]+)\s*}}/);
        const variableName = variableMatch[1]; // Extract the variable name
        const startingText = innerText.slice(0, variableMatch.index); // Text before the variable
        const endingText = innerText.slice(variableMatch.index + variableMatch[0].length); // Text after the variable

        if (predefinedVariables.some((v) => {
            return v.id === variableName
        })) {
            const output = `${startingText}<span style="color: blue;">{{${variableName}}}</span>${endingText}`;
            return output;
        } else {
            return innerText;
        }
    });

    // turn valid variables into blue
    processedContent = processedContent.replace(variableRegex, (match, variableName) => {
        if (predefinedVariables.some((v) => v.id === variableName)) {
            return `<span style="color: blue;">{{${variableName}}}</span>`;
        }
        return `{{${variableName}}}`;
    });

    return processedContent;
};

export default processContent;
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import { TabLabel, TabPanel } from '../../../components/common/TabComponents';
import { useState, useCallback, memo } from "react";
import { BannerEmailIcon, BannerMessageIcon, PlusIcon } from "../../../components/svgComponents";
import { MessengerTab } from "../../../components/templates/MessengerTab";
import { EmailTab } from "../../../components/templates/EmailTab";


const Banner = memo(({activeMainTab, handleModalWindow}) => {

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "24px",
                height: "128px",
                background: !activeMainTab ? "linear-gradient(90deg, #0061FF 0%, #60D5FF 100%)" : "linear-gradient(90deg, #0B3866 0%, #95F9C3 100%)",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {!activeMainTab ? 
                <BannerMessageIcon 
                    sx={{width: 263, height: 263, position: "absolute", left: "-48px", top: "-66px"}}
                    svgSx= {{width: 177, height: 128}}
                />:
                <BannerEmailIcon 
                    sx={{width: 263, height: 263, position: "absolute", left: "-62px", top: "-66px"}}
                    svgSx= {{width: 177, height: 128}}
                />}
            <Box sx={{ml: "156px", width: "50%"}}>
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: "24px",
                        lineHeight: "28px",
                        fontWeight: 600,
                    }}
                >
                    Шаблоны {!activeMainTab ? "сообщений для мессенджеров" : "писем"}
                    <Typography
                        component="span"
                        sx={{
                            display: "block",
                            color: "#fff",
                            fontSize: "18px",
                            lineHeight: "22px",
                            fontWeight: 400,
                        }}
                    >
                        {!activeMainTab ? "Отправляйте кандидатам сообщения по шаблону  с переменными в Telegram и Whatsapp" : "Настройте шаблоны писем, чтобы сэкономить время и повысить эффективность общения с кандидатами"}
                    </Typography>
                </Typography>
            </Box>
            <Box
                component="button"
                onClick={handleModalWindow}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "12px 20px",
                    background: "rgba(255, 255, 255, 1)",
                    borderRadius: "8px",
                    border: "none",
                    outline: "none",
                    color: "rgba(21, 24, 19, 1)",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    cursor: "pointer",
                    "&:hover" : {
                        background: "rgb(175, 174, 174)",
                    }
                }}
            >
                Создать шаблон 
                <PlusIcon 
                    sx={{width: 10, height: 10}}
                    svgSx= {{width: 10, height: 10, color: "rgba(21, 24, 19, 1)"}}
                />
            </Box>
        </Box>
    )
});

const TemplatesPage = () => {
    const [activeMainTab, setActiveMainTab] = useState(0);

    const [messengerInfo, setMessengerInfo] = useState(null);

    const handleMessengerChange = useCallback(
        (fieldName, data) => {
            setMessengerInfo((prev) => {
                return { ...prev, [fieldName]: data };
            });
        },
        []
    );

    const [emailInfo, setEmailInfo] = useState(null);

    const handleEmailChange = useCallback(
        (fieldName, data) => {
            setEmailInfo((prev) => {
                return { ...prev, [fieldName]: data };
            });
        },
        []
    );

        

    const renderBanner = useCallback(
        (handleModalWindow) => (
            <Banner
                activeMainTab={activeMainTab}
                handleModalWindow={handleModalWindow}
            />
        ),
        [activeMainTab]
    );

    return (
        <PageContainer>
            <Box
                sx={{
                    minWidth: "1124px",
                    minHeight: "943px",
                    width: "100%",
                    background: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    gap: "36px",
                    borderRadius: "8px",
                }}
            >
                {/* Tabs */}
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "rgba(226, 228, 231, 1)",
                        padding: "36px 24px 0 24px",
                    }}
                >
                    <Typography
                        sx={{
                            mb: "16px",
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "28px",
                            py: 1,
                        }}
                    >
                        Шаблоны
                    </Typography>
                    <Tabs
                        value={activeMainTab}
                        onChange={(e, newValue) => {
                            setActiveMainTab(newValue);
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "rgba(89, 157, 21, 1)",
                                height: "3px",
                                borderRadius: "100px 100px 0 0",
                            },
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            "& .MuiTab-root": {
                                maxWidth: 135,
                                textTransform: "none",
                                padding: "0 8px",
                            },
                            paddingLeft: "24px",
                        }}
                    >
                        <Tab
                            label={
                                <TabLabel
                                    text={"Мессенджеры"}
                                    activeMainTab={activeMainTab}
                                    index={0}
                                />
                            }
                        />
                        <Tab
                            label={
                                <TabLabel
                                    text={"Шаблоны писем"}
                                    activeMainTab={activeMainTab}
                                    index={1}
                                />
                            }
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    value={activeMainTab}
                    index={0}
                    sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        borderRadius: "8px",
                    }}
                >
                    <MessengerTab
                        info={messengerInfo}
                        type={2}
                        typeText={"Общие"}
                        renderBanner={renderBanner}
                        showEmptyTemplate={true}
                        handleInfoChange={handleMessengerChange}
                    />
                </TabPanel>
                <TabPanel
                    value={activeMainTab}
                    index={1}
                    sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        borderRadius: "8px",
                    }}
                >
                    <EmailTab
                        info={emailInfo}
                        type={2}
                        typeText={"Общие"}
                        renderBanner={renderBanner}
                        showEmptyTemplate={true}
                        handleInfoChange={handleEmailChange}
                    />
                </TabPanel>
            </Box>
            <Box
                sx={{
                    mt: "16px",
                    pt: "24px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    minWidth: "1124px",
                }}
            >
                {activeMainTab === 0 && (
                    <MessengerTab
                        info={messengerInfo}
                        type={1}
                        handleInfoChange={handleMessengerChange}
                        typeText={"Личные"}
                    />
                )}
                {activeMainTab === 1 && (
                    <EmailTab
                        info={emailInfo}
                        type={1}
                        handleInfoChange={handleEmailChange}
                        typeText={"Личные"}
                    />
                )}
            </Box>
        </PageContainer>
    );
};

export default TemplatesPage;
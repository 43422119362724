import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInput from '../../styledComponents/CustomInput';

// element to store company name
export const CompanyName = memo(({name, setData}) => {
    console.log("company name rendering");
    const changeCompanyName = (text) => {
        setData((prevData) => ({
            ...prevData,
            name: text,
        }));
    };

    return (
        <Box
            sx={{
                gridColumn: '1 / 2', // Span the first column
                gridRow: '1 / 2', // First row
            }}
        >
            <CustomInput label={"Название компании"} placeholder={"Введите название компании"} value = {name} onChange={changeCompanyName} />
        </Box>
    );
});

// element to store company description
export const CompanyDescription = memo(({description, setData}) => {
    console.log("company description rendering");
    const changeCompanyDescription = (text) => {
        setData((prevData) => ({
            ...prevData,
            description: text,
        }));
    };

    return (
        <Box
            sx={{
                gridColumn: '1 / 2', // Span the first column
                gridRow: '2 / 3', // Second row
            }}
        >
            <CustomInput label={"Описание компании"} placeholder={"Введите название компании"} value = {description} onChange={changeCompanyDescription} multiline={true} rows={5} />
            <Typography sx={{ fontSize: '14px', lineHeight: "18px",  mt: "6px", color: "rgba(116, 116, 115, 1)" }}>Максимальное количество символов — 400</Typography>
        </Box>
    );
});

// src/services/vacancyApi.js

// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";
import { fetchDepartments } from "./departmentsApi";

// Получение списка вакансий
export const fetchVacancies = async (page = 1, perPage = 10, status_id = 1, searchParams = {}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    // Utility to filter searchParams
    const filterSearchParams = (params) => {
        return Object.fromEntries(
            Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
        );
    };

    const filteredSearchParams = filterSearchParams(searchParams);

    if (filteredSearchParams.department_name) {
        const data = await fetchDepartments(filteredSearchParams.department_name);
        const department_ids = data && data.data && data.data.length > 0 ? data.data.map(department => department.id) : [];
        delete filteredSearchParams.department_name;
        if (department_ids.length <= 0){
            return {data: [], meta: {}};
        }
        filteredSearchParams.department_ids = department_ids;
    }

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies`, {
        params: { 
            page, 
            per_page: perPage,
            status_id: status_id,
            ...filteredSearchParams, // Add additional searching filters
        },
    });

    return {
        data: response.data.data, // Список вакансий
        meta: response.data.meta, // Данные для пагинации
    };
};


// Получение конкретной вакансии по ID
export const fetchVacancyById = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}`);
    return response.data.data;
};

export const fetchVacancyByIdOnEdit = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}?without_stages=1`);
    return response.data.data;
};

// Создание новой вакансии вручную
export const createVacancy = async (vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.post(`/api/company/${companyId}/vacancies`, vacancyData);
    return response.data.data;
};

// Создание вакансии через внешний источник (например, HeadHunter)
export const createVacancyFromExternal = async (externalUrl) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.post(`/api/company/${companyId}/vacancies/from-external`, {
        external_url: externalUrl,
    });
    return response.data.data; // Предполагаем, что в `data.data` приходит созданная вакансия
};

// Обновление вакансии по ID
export const updateVacancy = async (vacancyId, vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.put(
        `/api/company/${companyId}/vacancies/${vacancyId}`,
        vacancyData
    );

    return response.data.data; // Возвращаем обновлённые данные вакансии
};

// Change vacancy status
export const updateVacanciesStatus = async (body) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.put(
        `/api/company/${companyId}/vacancies/change-status`,
        body,
        {
            headers: {
                "Content-Type": "application/json", // Specify JSON content type
            },
        }
    );

    return response?.data?.data; // Return updated vacancies
};

// Обновление вакансии по ID
export const deleteVacancy = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.delete(
        `/api/company/${companyId}/vacancies/${vacancyId}`
    );

    return response.data.data; // Возвращаем обновлённые данные вакансии
};

// Mass delete
export const deleteMassVacancies = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.delete(
        `/api/company/${companyId}/vacancies/mass-delete`,
        {
            data: data,
            headers: {
                "Content-Type": "application/json", // Specify JSON content type
            },
        }
    );

    return response.data.data; // Возвращаем обновлённые данные вакансии
};




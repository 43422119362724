import {memo, useState} from "react";
import { Box, Divider, Typography } from "@mui/material";
import { VacancyIcon } from "../svgComponents"

const Negotiation = ({negotiation, isActive, onCardClick, index, isButton}) => {

    const [open, setOpen] = useState(false);

    const presentStage = (stageName, sx = {}) => {
        return (
            <Typography
                component="span"
                sx={{
                    borderRadius: "4px",
                    backgroundColor: "rgba(198, 209, 234, 1)",
                    color: "rgba(31, 107, 197, 1)",
                    padding: "4px 8px",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                    ...sx
                }}
            >
                {stageName}
            </Typography>
        )
    }

    const handleDialog = () => {
        setOpen(prev => !prev);
    }

    const handleClick = (index) => {
        onCardClick(index);
        if (isButton) {
            handleDialog();
        }
    }

    return (
        <Box>
            <Box
                onClick={() => handleClick(index)}
                sx={{
                    padding: "16px 24px",
                    backgroundColor: isActive ? "rgba(244, 247, 242, 1)" : "rgba(260, 260, 260, 1)",
                    border: isActive ? "1px solid rgba(89, 157, 21, 1)" : "1px solid rgba(226, 228, 231, 1)",
                    boxShadow: isActive ? "0px 5px 50px 0px rgba(0, 0, 0, 0.1)" : "none",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minWidth: "538px",
                    cursor: isButton ? "default" : "pointer"
                }}
            >
                <Box display="flex" gap="16px">
                    <VacancyIcon
                        sx={{ width: isButton ? "76px" : "45px", height: isButton ? "76px" : "45px", backgroundColor: "rgba(248, 249, 249, 1)", borderRadius: "5px" }}
                        svgSx={{ width: "24px", height: "24px", color: "rgba(21, 24, 19, 1)" }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(21, 24, 19, 1)",
                            width: "calc(100% - 61px)",
                        }}
                    >
                        {negotiation.name}
                        <Typography
                            component="span"
                            sx={{
                                marginTop: "4px",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "rgba(21, 24, 19, 0.5)",
                                display: "block",
                            }}
                        >
                            {negotiation.city ? `${negotiation.city}, ` : "Астана"}
                        </Typography>
                        {isButton && presentStage(negotiation.stage.name, {marginTop: "8px", display: "inline-block"})}
                    </Typography>
                </Box>
                {!isButton && presentStage(negotiation.stage.name)}
            </Box>
            {/* нужно что то делать*/}
            {open && <Box>
            </Box>}
        </Box>
    )
}

const Negotiations = memo(({ negotiations, render = {isHeader: true, isDivider: true, isButton: false}, currVacancyIdx, setCurrVacancyIdx }) => {
    console.log("Negotiations being rendered")

    if (negotiations.length === 0) {
        return null;
    }

    const handleClick = (index) => {
        if (setCurrVacancyIdx) {
            setCurrVacancyIdx(index);
            return;
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", padding: "24px", backgroundColor: "#FFFFFF" }}>
            {render.isHeader && <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Отклики
            </Typography>}
            {render.isDivider && <Divider sx={{left: "24px", right: "24px", backgroundColor: "rgba(235, 238, 239, 1)" }}></Divider>}
            {negotiations.map((negotiation, index) => (
                <Negotiation key={index} negotiation={negotiation} index={index} isActive = {currVacancyIdx === index} onCardClick = {handleClick} isButton = {render.isButton}/>
            ))}
        </Box>
    );
});

export default Negotiations;
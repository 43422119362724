
// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";

export const fetchCategories = async (searchQuery) => {
    const responce = await axiosClassic.get(`https://api.taphr.kz/api/vacancy/categories`, {
        params: {
            ...(searchQuery ? { name: searchQuery } : {}),
        }
    });
    return responce.data.data;
};
import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import LabeledInput from "../main/LabeledInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createChannel, updateChannel, deleteChannel } from "../../services/channelsApi";

// Изначальное состояние для создания нового канала
const INITIAL_CHANNEL_STATE = {
    name: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
};

export default function ChannelList({ channels }) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [currentChannel, setCurrentChannel] = useState(INITIAL_CHANNEL_STATE);

    const queryClient = useQueryClient();

    // Создание канала
    const { mutate: addChannel, isLoading: isAdding } = useMutation(createChannel, {
        onSuccess: () => {
            queryClient.invalidateQueries(["channels"]);
            handleDialogClose();
        },
        onError: (error) => {
            alert("Ошибка при создании канала: " + error.message);
        },
    });

    // Обновление канала
    const { mutate: updateExistingChannel, isLoading: isUpdating } = useMutation(updateChannel, {
        onSuccess: () => {
            queryClient.invalidateQueries(["channels"]);
            handleDialogClose();
        },
        onError: (error) => {
            alert("Ошибка при обновлении канала: " + error.message);
        },
    });

    // Удаление канала
    const { mutate: removeChannel, isLoading: isDeleting } = useMutation(deleteChannel, {
        onSuccess: () => {
            queryClient.invalidateQueries(["channels"]);
        },
        onError: (error) => {
            alert("Ошибка при удалении канала: " + error.message);
        },
    });

    // Открытие модалки (с очищением состояния или данными канала)
    const handleDialogOpen = (channel = null) => {
        if (channel) {
            setCurrentChannel(channel); // Устанавливаем данные канала для редактирования
        } else {
            setCurrentChannel(INITIAL_CHANNEL_STATE); // Очищаем для создания нового канала
        }
        setDialogOpen(true);
    };

    // Закрытие модалки
    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentChannel(INITIAL_CHANNEL_STATE); // Сбрасываем состояние
    };

    // Обновление полей ввода
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentChannel((prev) => ({ ...prev, [name]: value }));
    };

    // Сохранение канала (создание или обновление)
    const handleSaveChannel = () => {
        if (!currentChannel.name || !currentChannel.utm_source) {
            alert("Пожалуйста, заполните обязательные поля.");
            return;
        }
        if (currentChannel.id) {
            console.log(currentChannel)
            updateExistingChannel({ id: currentChannel.id, ...currentChannel });
        } else {
            addChannel(currentChannel);
        }
    };

    // Удаление канала
    const handleDeleteChannel = (id) => {
        if (window.confirm("Вы уверены, что хотите удалить канал?")) {
            removeChannel(id);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#F8F9F9",
                padding: "18px",
                borderRadius: "8px",
            }}
        >
            {/* Верхняя панель */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "500", fontSize: "24px" }}>
                    Список каналов
                </Typography>

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#599D15",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "8px",
                        padding: "6px 16px",
                        boxShadow: "none",
                        height: "50px",
                        fontSize: "16px",
                    }}
                    onClick={() => handleDialogOpen()}
                >
                    Добавить
                </Button>
            </Box>

            {/* Таблица */}
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>UTM source</TableCell>
                            <TableCell>UTM medium</TableCell>
                            <TableCell>UTM campaign</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {channels.map((channel) => (
                            <TableRow key={channel.id}>
                                <TableCell>{channel.name}</TableCell>
                                <TableCell>{channel.utm_source}</TableCell>
                                <TableCell>{channel.utm_medium}</TableCell>
                                <TableCell>{channel.utm_campaign}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleDialogOpen(channel)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleDeleteChannel(channel.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Модалка */}
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "650px",
                        maxWidth: "100%",
                        padding: "24px",
                        borderRadius: "24px",
                    },
                }}
            >
                <DialogTitle sx={{ fontSize: "24px", fontWeight: 500 }}>
                    {currentChannel.id ? "Редактировать канал" : "Новый канал"}
                </DialogTitle>
                <DialogContent>
                    <LabeledInput
                        label="Название канала"
                        name="name"
                        value={currentChannel.name}
                        onChange={handleInputChange}
                        placeholder="Например: Ютуб"
                        sx={{ flex: 1, width: "100%" }}
                        required
                    />
                    <LabeledInput
                        label="UTM source"
                        name="utm_source"
                        value={currentChannel.utm_source}
                        onChange={handleInputChange}
                        placeholder="Например: Youtube"
                        sx={{ flex: 1, width: "100%", marginTop: "24px" }}
                        required
                    />
                    <p style={{ color: "#747473", fontSize: "14px", marginTop: "1px" }}>
                        Используйте только латинские буквы
                    </p>
                    <LabeledInput
                        label="UTM medium"
                        name="utm_medium"
                        value={currentChannel.utm_medium}
                        onChange={handleInputChange}
                        placeholder="Например: video"
                        sx={{ flex: 1, width: "100%", marginTop: "24px" }}
                    />
                    <p style={{ color: "#747473", fontSize: "14px", marginTop: "1px" }}>
                        Используйте только латинские буквы
                    </p>
                    <LabeledInput
                        label="UTM campaign"
                        name="utm_campaign"
                        value={currentChannel.utm_campaign}
                        onChange={handleInputChange}
                        placeholder="Например: video_campaign"
                        sx={{ flex: 1, width: "100%", marginTop: "24px" }}
                    />
                </DialogContent>
                <DialogActions sx={{ gap: 1 }}>
                    <Button
                        sx={{ flex: 1, color: "#2E4052", borderColor: "#2E4052", height: "46px", borderRadius: '8px' }}
                        onClick={handleDialogClose}
                        variant="outlined"
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={handleSaveChannel}
                        variant="contained"
                        sx={{
                            backgroundColor: "#599D15",
                            flex: 1,
                            height: "46px",
                            borderRadius: '8px'
                        }}
                        disabled={isAdding || isUpdating}
                    >
                        {isAdding || isUpdating ? "Сохранение..." : "Сохранить"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

import { Box, Typography } from "@mui/material";
import { useState, useRef, useEffect, useCallback } from "react";
import TemplatesPanel from "./TemplatesPanel";
import { createEmailTemplate, fetchEmailTemplates, updateEmailTemplate, deleteEmailTemplate } from "../../services/emailTemplatesApi";
import { useMutation, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { CircularLoading, Loading, Error } from "../tapHrStyledComponents";
import EmptyTextTemplate from "./EmptyTextTemplate";
import CreateEmailTemplate from "../common/modalWindows/CreateEmailTemplate";
import { EDIT_STATUS } from "../../utils/statusesStorage";

export const EmailTab = ({info, handleInfoChange, showEmptyTemplate, renderBanner, type, typeText}) => {
    const observerRef = useRef(null);
    const [modalWindow, setModalWindow] = useState({open: false, data: null});
    const queryClient = useQueryClient();
    const [currentTarget, setCurrentTarget] = useState(null);
    const queryKey = useRef(["templates", "email", type]);

    // if data is passed to handle modal window then store it in the state, as weel as its id and index
    const handleModalWindow = (data) => {
        if (!modalWindow.open && data?.status === EDIT_STATUS) {
            setCurrentTarget({ id: data?.template.id, index: data?.index });
        }
        setModalWindow((prev) => ({
            open: !prev.open,
            data: !prev.open && data?.status === EDIT_STATUS ? data.template : null,
        }));
    };

    const {
        isLoading: isDataLoading,
        isError: isDataError,
        refetch,
        error: dataError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        queryKey.current,
        async ({ pageParam = 1 }) => {
            const response = await fetchEmailTemplates({ page: pageParam, type: type });
            if (response) {
                // Ensure the existing data is an array
                if (pageParam === 1) {
                    handleInfoChange(type, response.data);
                } else {
                    const existingData = info?.[type] || [];
                    
                    // Concatenate the new data to the existing data
                    const updatedData = [...existingData, ...response.data];
                    handleInfoChange(type, updatedData);
                }
            }
            return response;
        },
        {
            getNextPageParam: (lastPage) => {
                const { meta } = lastPage;
                const currentPage = parseInt(meta.current_page, 10);
                const totalPages = Math.ceil(meta.total / meta.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (!hasNextPage || isFetchingNextPage) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    fetchNextPage();
                }
            },
            { threshold: 1.0 }
        );

        const currentRef = observerRef.current; // Store the current ref value

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const handleDataDelete = (data) => {
        handleInfoChange(type, data.data)
    }

    const handleDataAdd = (type, response) => {
        handleInfoChange(type, response.data)
    }

    const handleDataEdit = useCallback((newType, data) => {
        if (newType !== type) {
            refetch();
        }

        const pageIndex = data?.meta?.current_page - 1;
        const pageSize = data?.meta?.per_page || 10;
        const startIndex = pageIndex * pageSize;
    
        // Ensure info[type] is an array before spreading
        const updatedData = [...(info?.[newType] || [])];
        const newPageData = data?.data || []; // Extract the data to be updated for the page
    
        // Replace items in the array with the new data
        updatedData.splice(startIndex, newPageData.length, ...newPageData);
    
        // Update the state using handleInfoChange
        handleInfoChange(newType, updatedData);
    }, [queryClient, queryKey]);

    const createMutation = useMutation(createEmailTemplate, {
        onSuccess: ({type, response}) => {
            handleDataAdd(type, response);
        }
    });

    const updateMutation = useMutation(updateEmailTemplate, {
        onSuccess: ({type, response}) => {
            handleDataEdit(type, response);
            setCurrentTarget(null);
        }
    });

    const handleNewItem = (body) => {
        const formData = new FormData();

        formData.append("type", body.type);

        formData.append("name", body.name);

        formData.append("content", body.content);

        // Remove HTML tags using a regular expression
        const plainTextSubject = body.subject.replace(/<[^>]*>/g, "").trim();
        formData.append("subject", plainTextSubject);

        if (body?.file) {
            formData.append("file", body.file);
        }

        if (currentTarget) {
            const page = Math.floor(currentTarget.index / 10) + 1;
            updateMutation.mutate({id: currentTarget.id, body: formData, page, type: body.type});
        } else {
            createMutation.mutate({body: formData, type : body.type});
        }
        handleModalWindow();
    } 

    const { mutate: deleteTemplateMutate } = useMutation(deleteEmailTemplate, {
        onSuccess: (data) => {
            handleDataDelete(data);
        },
    });

    const handleDelete = useCallback(
        (id) => {
            deleteTemplateMutate({
                id,
                type,
            });
        },
        [deleteTemplateMutate, type]
    );

    const isLoading = isDataLoading || createMutation.isLoading || deleteTemplateMutate.isLoading;
    const isError = isDataError || createMutation.isError || updateMutation.isError || deleteTemplateMutate.isError;
    const error = dataError || createMutation.error || updateMutation.error || deleteTemplateMutate.error;

    const isInfoLoading = !info || !info[1] || !info[2];
    const isInfoEmpty = info && (info[1]?.length === 0 && info[2]?.length === 0);

    return isLoading || isInfoLoading ? (
        <CircularLoading />
    ) : isError ? (
        <Error error={error.message || "Неопознанная причина"} />
    ) : (
        <>
            {modalWindow.open && (
                <CreateEmailTemplate
                    handleClose={handleModalWindow}
                    handleNewItem={handleNewItem}
                    data={modalWindow.data}
                />
            )}
            {isInfoEmpty ? (
                showEmptyTemplate ? (
                    <EmptyTextTemplate
                        handleModalWindow={handleModalWindow}
                        mainText={"Шаблоны сообщений для писем"}
                        subText={
                            "Настройте шаблоны для эффективного общения с кандидатами"
                        }
                    />
                ) : null
            ) : (
                <>
                    {renderBanner && renderBanner(handleModalWindow)}
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                mb: 2,
                                ml: 3,
                            }}
                        >
                            {typeText}
                        </Typography>
                        <TemplatesPanel
                            templates={info[type]}
                            handleDelete={handleDelete}
                            handleModalWindow={handleModalWindow}
                            currentId={currentTarget?.id}
                            isLoading={updateMutation.isLoading}
                            hideAuthor={type === 1}
                        />
                    </Box>
                </>
            )}
        </>
    );
};
import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { CompanyName, CompanyDescription } from './NameDescription';
import ImageUploader from './ImageUploader';


const TopPanel = ({data, setData}) => {
    return (
        <Box>
            {/* Tabs */}
            {/* Основная информация */}
            <Paper
                sx={{
                    border: "none",
                    boxShadow: "none",
                }}
            >
                <Typography variant="h6" sx={{ mb: "36px", fontSize: "24px", fontWeight: 600, lineHeight: "28px" }}>
                    Основная информация
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', md: '481px 1fr' }, // Define column layout
                        gridTemplateRows: 'auto auto', // Define rows
                        gap: 2,
                    }}
                >
                    {/* Название компании */}
                    <CompanyName name={data.name || ""} setData={setData}/>

                    {/* Описание компании */}
                    <CompanyDescription description={data.description || ""} setData={setData}/>

                    {/* File Uploader */}
                    <ImageUploader backImg={data.background_image} setData={setData}/>
                </Box>

            </Paper>
        </Box>
    );
};

export default TopPanel;
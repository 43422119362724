import { useState, useRef, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Pagination, CircularLoading, Error } from "../../../components/tapHrStyledComponents";
import { fetchSources } from "../../../services/sourcesApi";
import CreateSource from "../../../components/sources/CreateSource";
import SourcesList from "../../../components/sources/SourcesList";

const SourcesPage = () => {
    const rowsPerPage = useRef(13); // Number of lines
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const queryKey = useRef(["sources"]);
    const queryClient = useQueryClient();
    
    const { data, isLoading, isError, error } = useQuery(
        [queryKey.current, currentPage, rowsPerPage.current],
        async () => fetchSources("", currentPage, rowsPerPage.current),
        {
            refetchOnWindowFocus : false,
            keepPreviousData: true, // Avoid flickering when changing pages
        }
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // status = 1 - add new source
    // status = 2 - edit source
    // status = 3 - delete source
    const handleDataChange = useCallback((newData, status) => {
        if (status === 1 && data?.data?.length === rowsPerPage.current) {
            setCurrentPage((prevPage) => prevPage + 1);
            return;
        }

        queryClient.setQueryData([queryKey.current, currentPage, rowsPerPage.current], newData);

        if (status === 3 && newData?.data?.length === 0 && currentPage !== 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    }, [queryClient, currentPage, data?.data?.length]);

    return (
        <PageContainer>
            <Box
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "36px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                }}
            >
                <Box
                    sx={{
                        py: 1,
                        display: "flex",
                        gap: "16px",
                        height: "62px",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "28px",
                        }}
                    >
                        Источники резюме
                    </Typography>
                </Box>
                <Box sx={{ minHeight: "1052px"}}>
                    {isLoading && <CircularLoading />}
                    {isError && <Error error={error.message || "Неопознанная причина"} />}
                    {!isLoading && !isError && data && (
                        <>
                            <Box sx={{padding: "12.5px 24px", fontSize: 14, lineHeight: "18px", fontWeight: 500, color: "rgba(116, 116, 115, 1)", backgroundColor: "rgba(248, 249, 249, 1)", borderBottom: "1px solid rgba(226, 228, 231, 1)" }}>Название источника</Box>
                            <CreateSource handleDataChange={handleDataChange} currentPage={currentPage} perPage={rowsPerPage.current}/>
                            <SourcesList 
                                handleDataChange={handleDataChange}
                                sources={data.data || []}
                                currentPage={currentPage}
                                perPage={rowsPerPage.current}
                            />
                        </>
                    )}
                </Box>
            </Box>
            {/* Пагинация и выбор количества строк */}
            {!isLoading && (
                <Pagination
                    sx={{ my: 3 }}
                    count={data?.meta?.last_page || 1}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            )}
        </PageContainer>
    );
};

export default SourcesPage;

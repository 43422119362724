import axiosClassic from "../api/interceptors";

/**
 * 
 * @param {String} searchQuery - search query default empty string
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * @param {String} type - type of template default null
 * @returns {Object} - object that contains data and meta
 */
export const fetchEmailTemplates = async ({searchQuery = "", page = 1, perPage = 10, type=null}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const params = {
            page,
            per_page: perPage,
            ...(searchQuery && { name: searchQuery }),
            ...(type && { type }),
        };
        
        const response = await axiosClassic.get(`/api/company/${companyId}/templates/email`, { params });

        // Check if the response has data
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error fetching stages:", error.message);
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * 
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * @param {FormData} body - object containing three fields name String, type Number, and content String
 * @returns {Object} - object that contains type and responce (data and meta)
 */
export const createEmailTemplate = async ({page = 1, perPage = 10, body, type}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/templates/email`, 
            body,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: { 
                    page: page,
                    per_page: perPage,
                    ...(body?.type && { type: body?.type }),
                }, 
            }
        );
        return {
            type: type, response: {
                data: response?.data?.data || [],
                meta: response?.data?.meta,
            }
        };
    } catch (error) {
        console.error("Error creating stage:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * @param {FormData} body - formData containing three fields name String, type Number, and content String
 * @returns {Object} - object that contains data and meta
 * @param {Number} id - id of the template to update
 * @returns {Object} - object that contains type and responce (data and meta)
 */
export const updateEmailTemplate = async ({page = 1, perPage = 10, body, id, type=null}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/templates/email/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    _method: "PUT",
                    page: page,
                    per_page: perPage,
                    ...(type && { type }),
                }, 
            }
        );

        return {
            type: type, response: {
                data: response?.data?.data || [],
                meta: response?.data?.meta,
            }
        };
    } catch (error) {
        console.error("Error updating stage:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * @param {Number} id - id of the template to delete
 * @param {String} type - type of template default null
 * @returns {Object} - object that contains data and meta
 */
export const deleteEmailTemplate = async ({page = 1, perPage = 10, id, type=null}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/templates/email/${id}`, {
            params: { 
                page : page, 
                per_page: perPage,
                ...(type && { type }),
            },
        });
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error deleting stage:", error.message);
        throw error;
    }
}

import { memo } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { PreviewIcon } from "../../svgComponents";

const PageCustomization = memo(({color_scheme, setData}) => {
    console.log("Page Customization rendering");
    const setChangedColorScheme = (colorScheme) => {
        setData((prevData) => ({
            ...prevData,
            color_scheme: colorScheme,
        }));
    }

    return (
        <Paper sx={{ p: 3, borderRadius: "8px", border: null, boxShadow: "none" }}>
            <Typography
                variant="h6"
                sx={{
                    mb: 1,
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "28px",
                }}
            >
                Кастомизация страницы
            </Typography>
            <Typography
                sx={{
                    mb: "36px",
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "rgba(116, 116, 115, 1)",
                }}
            >
                Персонализируйте свою страницу
            </Typography>
            <Box
                sx={{
                    backgroundColor: "rgba(242, 243, 243, 1)",
                    padding: "24px 101px",
                    display: "grid",
                    gridTemplateColumns: "1.2fr 1px 1fr",
                    flexWrap: "wrap",
                    gap: "74px",
                    minHeight: "374px",
                    justifyContent: "space-between", // Space between two sections
                }}
            >
                {/* Color Circles Section */}
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(5, 1fr)",
                            gridTemplateRows: "repeat(2, 66px)",
                            flexWrap: "wrap",
                            gap: "36px",
                            height: "168px",
                        }}
                    >
                        {[
                            "rgba(89, 157, 21, 1)",
                            "rgba(0, 51, 102, 1)",
                            "rgba(255, 215, 0, 1)",
                            "rgba(211, 47, 47, 1)",
                            "rgba(142, 68, 173, 1)",
                            "rgba(245, 124, 0, 1)",
                            "rgba(0, 169, 157, 1)",
                            "rgba(141, 110, 99, 1)",
                            "rgba(244, 143, 177, 1)",
                            "rgba(90, 155, 212, 1)",
                        ].map((color, index) => (
                            <Box
                                key={color}
                                sx={{
                                    width: 66,
                                    height: 66,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    border: color_scheme === (index + 1 )? "7px solid rgba(200, 202, 204, 1)" : "7px solid transparent",
                                    borderRadius: "50%",
                                    "&:hover": {
                                        borderColor: "rgba(229, 230, 231, 1)"
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={() => setChangedColorScheme(index + 1)}
                            >
                                <Box
                                    sx={{
                                        width: 48,
                                        height: 48,
                                        borderRadius: "50%",
                                        backgroundColor: color,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 26,
                                            height: 26,
                                            borderRadius: "50%",
                                            backgroundColor:
                                                "rgba(244, 247, 242, 1)",
                                        }}
                                    ></Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Divider 
                    orientation="vertical" 
                    flexItem
                    sx={{
                        marginTop: "22px", // Reduce height at the top
                        marginBottom: "22px", // Reduce height at the bottom
                    }}
                />

                {/* Box with Border */}
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <PreviewIcon sx={{width: 326, height: 326, colorScheme: color_scheme}}/>
                </Box>
            </Box>
        </Paper>

    )
});

export default PageCustomization;
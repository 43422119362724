import React, { useState, useEffect, memo } from 'react';
import {
    Container,
    Box,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Tooltip
} from '@mui/material';
import CustomStepper from '../../components/main/CustomStepper';
import FirstStep from '../../components/vacancy/FirstStep';
import SecondStep from '../../components/vacancy/SecondStep';
import ThirdStep from '../../components/vacancy/ThirdStep';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createVacancy } from "../../services/vacancyApi"; // Ваш API для создания вакансий
import { useNavigate, useLocation } from "react-router-dom";
import { countCharacters } from '../../utils/textFunctions';
import { PlusIcon } from '../../components/svgComponents';
import StagesModalWindow from '../../components/common/modalWindows/stagesModalWindow';


const steps = ['Основная информация', 'Этапы', 'Публикация'];


const CreateVacancyPage = () => {
    const location = useLocation();
    const externalVacancyData = location.state; // Получаем данные, переданные через navigate
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false); // Состояние для модального окна
    const [includeExternalFields, setIncludeExternalFields] = useState(false); // Флаг для включения external полей
    const [activeStep, setActiveStep] = useState(0);

    const [name, setName] = useState(externalVacancyData?.name || ''); // Предзаполнение
    const [text, setText] = useState(externalVacancyData?.description || '<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul>');

    // Состояние для всех шагов
    const [salaryFrom, setSalaryFrom] = useState(externalVacancyData?.salary_from || '');
    const [salaryTo, setSalaryTo] = useState(externalVacancyData?.salary_to || '');
    const [currency, setCurrency] = useState(externalVacancyData?.salary_currency || 'KZT');
    const [experience, setExperience] = useState(externalVacancyData?.experience || 'noExperience');
    const [employmentType, setEmploymentType] = useState(externalVacancyData?.employment || 'full');
    const [workSchedule, setWorkSchedule] = useState(externalVacancyData?.schedule || 'fullDay');
    const [taxType, setTaxType] = useState(externalVacancyData?.salary_gross || true);
    const [city, setCity] = useState(externalVacancyData?.city_name ? { name: externalVacancyData.city_name, id: externalVacancyData.hh_area_id } : null);
    const [category, setCategory] = useState(null);

    const [department, setDepartment] = useState(null)
    const [accept_temporary, set_accept_temporary] = useState(externalVacancyData?.accept_temporary || false);

    const [stages, setStages] = useState([]);


    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleNext = () => setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));

    const handleBack = () => setActiveStep((prev) => Math.max(prev - 1, 0));

    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const mutation = useMutation(createVacancy, {
        onSuccess: () => {
            // Обновляем кэш вакансий после успешного создания
            queryClient.invalidateQueries(["vacancies"]);
            alert("Вакансия успешно создана!");
            navigate("/");
        },
        onError: (error) => {
            alert("Ошибка при создании вакансии:", error);
        },
    });

    const handleCreate = () => {
        // Подготовка данных для отправки
        let newSalaryFrom = salaryFrom;
        let newSalaryTo = salaryTo;

        if (salaryFrom !== null) {
            newSalaryFrom = String(salaryFrom).replace(/[^0-9]/g, "");
        }

        if (salaryTo !== null) {
            newSalaryTo = String(salaryTo).replace(/[^0-9]/g, "");
        }
        console.log(salaryFrom, newSalaryFrom)
        console.log(salaryTo, newSalaryTo)
        const body = {
            name,
            description: text,
            salary_from: newSalaryFrom ? Number(newSalaryFrom) : null,
            salary_to: newSalaryTo ? Number(newSalaryTo) : null,
            salary_gross: Boolean(taxType),
            salary_currency: currency,
            city_name: city?.name,
            hh_area_id: city?.id,
            recruiter_id: localStorage.getItem('manager_id'),
            department_id: department?.id,
            category_id: category?.id,
            experience,
            schedule: workSchedule,
            employment: employmentType,
            accept_temporary,
        };

        if (stages.length > 0) {
            body.stages = stages.map(({ name, order }) => ({ name, order }));
        }

        // Добавляем external_source и external_id, если выбран вариант "Связать"
        if (includeExternalFields) {
            body.external_source = externalVacancyData.external_source;
            body.external_id = externalVacancyData.external_id;
        }

        console.log(body);

        mutation.mutate(body); // Отправляем запрос
    };

    // append new array to old array
    const handleAddStages = (stages) => {
        // stage inside stages must have name and order
        setStages(prev => [...prev, ...stages]);
    };


    // Открытие модального окна, если is_vacancy_owner === true
    useEffect(() => {
        if (externalVacancyData?.is_vacancy_owner) {
            setIsLinkModalOpen(true);
        }
    }, [externalVacancyData]);

    const handleLinkVacancy = () => {
        setIncludeExternalFields(true); // Устанавливаем флаг для включения external полей
        setIsLinkModalOpen(false); // Закрываем модальное окно
    };

    const characters = countCharacters(text);
    const isNotValid = !name || !city || !department || characters < 150 || !category;

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        name={name}
                        setName={setName}
                        salaryFrom={salaryFrom}
                        setSalaryFrom={setSalaryFrom}
                        salaryTo={salaryTo}
                        setSalaryTo={setSalaryTo}
                        currency={currency}
                        setCurrency={setCurrency}
                        experience={experience}
                        setExperience={setExperience}
                        employmentType={employmentType}
                        setEmploymentType={setEmploymentType}
                        workSchedule={workSchedule}
                        setWorkSchedule={setWorkSchedule}
                        taxType={taxType}
                        setTaxType={setTaxType}
                        text={text}
                        setText={setText}
                        city={city}
                        setCity={setCity}
                        department={department}
                        setDepartment={setDepartment}
                        accept_temporary={accept_temporary}
                        set_accept_temporary={set_accept_temporary}
                        characters={characters}
                        category={category}
                        setCategory={setCategory}
                    />
                );
            case 1:
                return <>
                    <AddStageButton open={open} handleOpen={handleOpen} />
                    <SecondStep stages={stages} setStages={setStages} handleOpen={handleOpen} />
                </>
            case 2:
                return (
                    <ThirdStep
                        name={name}
                        salaryFrom={salaryFrom}
                        salaryTo={salaryTo}
                        currency={currency}
                        experience={experience}
                        employmentType={employmentType}
                        workSchedule={workSchedule}
                        taxType={taxType}
                        text={text}
                        city={city}
                        department={department}
                        category={category}
                        accept_temporary={accept_temporary}
                        stages={stages}
                        handleCreate={handleCreate}
                        handleBack={handleBack}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="none" sx={{ margin: "24px 0", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx = {{maxWidth: "1200px", width: "100%", minHeight: 'calc(90vh - 25px)', display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Box>
                    <CustomStepper steps={steps} activeStep={activeStep} />
                    <Paper
                        elevation={0}
                        sx={{
                            py: activeStep !== 2 ? 3 : 0,
                            px : activeStep === 2 ? 0 : 3,
                            borderRadius: 2,
                            backgroundColor: activeStep === 2 ? "transparent" : '#FFFFFF',
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '662px',
                        }}
                    >
                        {renderStepContent(activeStep)}
                    </Paper>
                </Box>

                {open && (
                    <StagesModalWindow handleAddStages={handleAddStages} handleClose={handleClose} orderStart = {stages.length + 1} />
                )}

                {activeStep < steps.length - 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button
                            variant="outlined"
                            sx={{ marginRight: 2, height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', color: '#599D15', borderColor: '#599D15', fontSize: '16px', fontWeight: '400' }}
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            Назад
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: isNotValid ? '#E0E0E0' : '#599D15',
                                color: isNotValid ? '#A0A0A0' : 'white',
                                height: '56px',
                                boxShadow: 'none',
                                borderRadius: '8px',
                                width: '202px',
                                fontSize: '16px',
                                fontWeight: '400'
                            }}
                            onClick={handleNext}
                            disabled={isNotValid} // Проверка на заполненность
                        >
                            Перейти к {activeStep + 2} шагу
                        </Button>

                    </Box>
                )}

                <Dialog open={isLinkModalOpen} onClose={() => setIsLinkModalOpen(false)} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: '12px', width: '352px', padding: '24px' } }}>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: 0, justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <svg style={{ marginRight: '16px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.1213 9.87891L9.8787 14.1215" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M10.3332 7.00003L11.9999 5.33336C13.8409 3.49239 16.8256 3.49239 18.6666 5.33335V5.33335C20.5076 7.17431 20.5076 10.1591 18.6666 12.0001L16.9999 13.6667" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M13.6665 17L11.9999 18.6666C10.1589 20.5076 7.17411 20.5076 5.33315 18.6666V18.6666C3.49219 16.8257 3.49219 13.8409 5.33315 11.9999L6.99983 10.3333" stroke="#151813" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '20px', lineHeight: '30px', padding: 0 }}>
                                Связать вакансию?
                            </Typography>
                        </div>
                        <Tooltip title="Все новые отклики будут автоматически перенаправляться на платформу tapHR" arrow>
                            <IconButton>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1183_11326)">
                                        <circle cx="9" cy="5.25" r="0.75" fill="#868C92" />
                                        <path d="M8.25 7.5H9V12.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#868C92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1183_11326">
                                            <rect width="18" height="18" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent sx={{ marginTop: 2, marginBottom: 3, padding: 0 }}>
                        <Typography sx={{ color: '#868C92', fontSize: '14px', lineHeight: '18px' }}>
                            Мы обнаружили, что вакансия из HeadHunter принадлежит вашей компании. Хотите связать её с текущей вакансией?
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ padding: 0 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setIsLinkModalOpen(false)}
                            sx={{ color: '#599D15', borderColor: '#599D15', width: '148px', height: '46px', borderRadius: '8px' }}
                        >
                            Отменить
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleLinkVacancy}
                            sx={{ backgroundColor: '#599D15', color: 'white', width: '148px', height: '46px', borderRadius: '8px' }}
                        >
                            Связать
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
};

const AddStageButton = memo(({ open, handleOpen }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, px: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px' }}>Этапы собеседования</Typography>
            <Box
                component = "button"
                variant="outlined"
                key={open}
                onClick={handleOpen}
                sx={{ 
                    color: '#fff', 
                    backgroundColor: 'rgba(89, 157, 21, 1)',
                    border: '1px solid transparent',
                    minWidth: '174px', 
                    height: '46px', 
                    borderRadius: '8px', 
                    fontSize: '16px', 
                    lineHeight: "20px",
                    fontWeight: 400, 
                    textTransform: 'none', 
                    display: 'flex',
                    gap: '8px',
                    padding: "12px 20px",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "rgba(80, 140, 19, 1)",
                    },
                    "&:active": {
                        backgroundColor: "rgba(69, 122, 16, 1)",
                    }
                }}
            >
                <PlusIcon 
                    sx = {{
                        width: 20,
                        height: 20,
                    }}
                    svgSx = {{
                        width: 10,
                        height: 10,
                        color: "#fff",
                    }}
                />
                Добавить этап
            </Box>
        </Box>
    )
});

export default CreateVacancyPage;

import React, { useState } from "react";
import {
    Box, Grid, Typography, Button, TextField, Chip, FormControl,
    FormLabel,
} from "@mui/material";

const KeySkills = ({ skills, setSkills }) => {
    const [inputValue, setInputValue] = useState("");

    const handleAddSkill = () => {
        if (skills.length < 15) {
            setSkills([...skills, inputValue.trim()]);
            setInputValue(""); // Очистить поле ввода
        } else {
            alert("Можно добавить не более 15 навыков");
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue.trim() !== "") {
            e.preventDefault(); // Отключить стандартное поведение Enter
            if (skills.length < 15) {
                setSkills([...skills, inputValue.trim()]);
                setInputValue(""); // Очистить поле ввода
            } else {
                alert("Можно добавить не более 15 навыков");
            }
        }
    };

    const handleDeleteSkill = (index) => {
        const updatedSkills = skills.filter((_, i) => i !== index);
        setSkills(updatedSkills);
    };

    return (
        <FormControl fullWidth sx={{ pt: "17px", borderTop: "1px solid rgba(235, 238, 239, 1)", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px"}}>
                Ключевые навыки
            </Typography>
            <Box sx={{ display: "flex", alignItems: 'center', gap: 5 }}>
                <TextField
                    fullWidth
                    placeholder="Напишите свой вариант"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{
                        flex: 7,
                        backgroundColor: "#F8F9F9",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            "& fieldset": {
                                borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                                borderColor: "#BDBDBD",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#599D15",
                            },
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleAddSkill}
                    fullWidth
                    sx={{
                        flex: 1,
                        backgroundColor: "#2F3C4A",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: 500,
                        height: '56px',

                        ":hover": {
                            backgroundColor: "#1F2A36",
                        },
                    }}
                >
                    Добавить
                </Button>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", mt: 2 }}>
                {skills.map((skill, index) => (
                    <Chip
                        key={index}
                        label={skill}
                        onDelete={() => handleDeleteSkill(index)}
                        sx={{
                            backgroundColor: "#F8F9F9",
                            border: "1px solid #BDBDBD",
                            borderRadius: "4px",
                            fontWeight: 500,
                            "& .MuiChip-deleteIcon": {
                                color: "#ADB5BD",
                                "&:hover": {
                                    color: "#FF3B30",
                                },
                            },
                        }}
                    />
                ))}
            </Box>
        </FormControl>
    );
};

export default KeySkills;

import { MenuItem, Typography } from "@mui/material";
import PopUpWindow from "../popUpWindow";

const StagePopUpWindow = ({ 
    anchorEl, 
    onClose, 
    handleRefusal, 
    onStageChange, 
    negotiationId, 
    currStageId, 
    stages 
}) => {

    const handleStageChange = (stageId) => {
        try {
            onStageChange(negotiationId, stageId);
            onClose();
        } catch (error) {
            console.error("Ошибка при смене этапа подбора:", error);
        }
    };

    return (
        <PopUpWindow 
            anchorEl={anchorEl} 
            open={Boolean(anchorEl)} 
            onClose={onClose}
            onClick={handleStageChange}
            startItems=
                {<MenuItem
                    sx={{
                        pointerEvents: "none",
                        backgroundColor: "transparent !important",
                        "&:hover": {
                            backgroundColor: "transparent !important",
                        },
                    }}
                >
                    <Typography 
                        sx={{ 
                            fontSize: "14px", 
                            color: "#599D15", 
                            fontWeight: "bold", 
                            backgroundColor: "transparent !important" 
                        }}
                    >
                        Сменить этап подбора
                    </Typography>
                </MenuItem>}
            endItems=
                {<MenuItem
                    key={'refusal'}
                    onClick={handleRefusal}
                >
                    Отказ
                </MenuItem>}
            middleItems={Array.isArray(stages) ? stages.filter((stage) => stage.id !== currStageId) : []}
            currStageId={currStageId}
        />
    );
};

export default StagePopUpWindow;


import {useState} from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Box } from '@mui/material';
import VacancyTableRow from './VacancyTableRow';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMassVacancies, updateVacanciesStatus } from '../../services/vacancyApi';
import { CheckBox } from '../tapHrStyledComponents';
import { CloseIcon, TrashIcon, UnZipIcon, ZipIcon } from '../svgComponents';
import { DeleteModalWindow } from '../common/modalWindows/deleteModalWindow';
import { deleteVacancy } from '../../services/vacancyApi';

const MultiActionButton = ({children, handleClick}) => {
    return (
        <Box
            content = "button"
            onClick = {handleClick}
            sx={{
                backgroundColor: "rgba(89, 157, 21, 1)",
                cursor: "pointer",
                padding: "12px 20px",
                borderRadius: "8px",
                border: "none",
                color: "white",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "20px",
                "&:hover": {
                    backgroundColor: "rgba(80, 140, 19, 1)",
                },
                "&:active": {
                    backgroundColor: "rgba(69, 122, 16, 1)",
                },
            }}
        >
            {children}
        </Box>
    )
}

const MultiActionBox = ({list, multiStatusChange, multiDelete, tables, cleanList}) => {
    return (
        <Box 
            sx={{
                overflow: "hidden", // Ensures content doesn’t overflow during height transition
                height: list.length > 0 ? "auto" : 0, // Smoothly transition height
                transition: "height 0.5s ease-in-out",
            }}
        >
            <Box 
                sx = {{
                    borderTopLeftRadius: "8px", 
                    borderTopRightRadius: "8px", 
                    backgroundColor: "rgba(89, 157, 21, 1)", 
                    padding: "16px 8px", 
                    display: "flex", 
                    gap: "36px", 
                    alignItems: "center",
                    opacity: list.length > 0 ? 1 : 0,
                    transition: "opacity 0.5s ease-in-out",
                }}
            >
                <Box sx = {{display: "flex", gap: "16px", alignItems: "center"}}>
                    <CloseIcon
                        onClick = {() => cleanList(true)} 
                        sx = {{width: 50, height: 50, cursor: "pointer"}}
                        svgSx = {{width: 16, height: 16, color: "rgba(0, 0, 0, 0.5)", hoverColor : "rgba(0, 0, 0, 0.75)", pressedColor : "rgba(0, 0, 0, 1)"}}
                    />
                    <Box sx = {{color: "white"}}>{list.length} вакансии выбрано</Box>
                </Box>
                <Box sx = {{display: "flex", gap: "16px"}}>
                    {tables.isArchive && (<MultiActionButton handleClick={multiStatusChange}>
                        <UnZipIcon
                            sx = {{width: 20, height: 20}}
                            svgSx = {{width: 17, height: 17, color: "white"}}
                        />
                        Восстановить
                    </MultiActionButton>)}
                    {tables.isActive && (<MultiActionButton handleClick={multiStatusChange}>
                        <ZipIcon
                            sx = {{width: 20, height: 20}}
                            svgSx = {{width: 17, height: 17, color: "white"}}
                        />
                        Архивировать
                    </MultiActionButton>)}
                    {tables.isArchive && (<MultiActionButton handleClick={multiDelete}>
                        <TrashIcon
                            sx = {{width: 20, height: 20}}
                            svgSx = {{width: 17, height: 17, color: "white"}}
                        />
                        Удалить
                    </MultiActionButton>)}
                </Box>
            </Box>
        </Box>
    )
};

const VacanciesByStage = ({ vacancies, queryKey, tables }) => {

    const [checkAll, setCheckAll] = useState({fromParent: false, currState: false});
    const [deleteModalWindow, setDeleteModalWindow] = useState({index: null, idx: null});
    const [list, setList] = useState([]);
    const queryClient = useQueryClient();

    const handleCheckAll = (onlyClose) => {
        if (!onlyClose && !checkAll.currState) {
            const listOfVacanciesIds = vacancies.map((vacancy) => vacancy.id);
            setList(listOfVacanciesIds);
        } else {
            setList([]);
        }
        if (onlyClose) {
            setCheckAll({fromParent: true, currState: false});
            return;
        }
        setCheckAll(prev => ({
            fromParent: true,
            currState: !prev.currState, // toggle current state
        }));
    }

    const onMutationSuccess = () => {
        setCheckAll({fromParent: true, currState: false});
        setList([]);
    }

    const archivateMutation = useMutation(updateVacanciesStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            onMutationSuccess();
        },
    });

    const deleteListMutation = useMutation(deleteMassVacancies, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            onMutationSuccess();
        },
    });

    const handleList = () => {
        archivateMutation.mutate({ vacancy_ids: list, status_id: tables?.targetStageId });
    }

    const handleDeleteList = () => {
        deleteListMutation.mutate({ vacancy_ids: list });
    }

    const handleCheckBoxClick = (index) => {
        const newList = [...list];
        const indexToDelete = newList.indexOf(index);
        if (indexToDelete !== -1) {
            newList.splice(indexToDelete, 1);
        } else {
            newList.push(index);
        }
        setList(newList);
        setCheckAll(prev => ({
            fromParent: false,  // previous state should track the current one
            currState: prev.currState, // toggle current state
        }));
    };

    const deleteMutation = useMutation((data) => deleteVacancy(data.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    const handleDelete = (id) => {
        deleteMutation.mutate({id});
    };

    return (
        <>
            <MultiActionBox list={list} multiStatusChange = {handleList} multiDelete = {() => setDeleteModalWindow({index : -1, idx : -1})} tables={tables} cleanList={handleCheckAll}/>
            <Table 
                sx={{ 
                    minWidth: 650, 
                    border: "1px solid rgba(226, 228, 231, 1)", 
                    borderRadius: "8px",
                    '& .MuiTableHead-root': {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px', // Apply rounded corners to the TableHead
                    },
                }} 
                aria-label="vacancy table"
            >
                <TableHead sx={{ borderRadius: "8px" }}>
                    <TableRow sx={{ borderRadius: "8px" }}>
                        <TableCell sx = {{width: "24px", backgroundColor:"rgba(248, 249, 249, 1)"}}></TableCell>
                        <TableCell sx = {{width: "20px", padding: 0, backgroundColor:"rgba(248, 249, 249, 1)"}}>
                            <CheckBox checked={checkAll.currState} onClick={() => {handleCheckAll()}}/>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 16px", backgroundColor:"rgba(248, 249, 249, 1)" }}>Название вакансии</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)" }}>Регион</TableCell>
                        {!tables?.notDepartment && <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)" }}>Отдел</TableCell>}
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)" }}>Отклики</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)" }}>Рекрутер</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#6E6E6E', padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)" }}> {tables.isArchive ? "Дата архивации" : "Дата создания"} </TableCell>
                        <TableCell sx={{padding: "12px 24px", backgroundColor:"rgba(248, 249, 249, 1)"}}/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vacancies && vacancies.map((vacancy, index) => (
                        <VacancyTableRow
                            key={index}
                            id={vacancy?.id}
                            position={vacancy?.name}
                            region={vacancy?.city_name}
                            department={vacancy?.department_name}
                            responses={vacancy?.negotiations_count || 0}
                            newResponses={vacancy?.unseen_negotiations_count || 0}
                            recruiterName={vacancy?.recruiter_name}
                            recruiterImage={vacancy?.recruiter_avatar}
                            date={tables.isArchive ? new Date(vacancy?.updated_at).toLocaleDateString() : new Date(vacancy?.created_at).toLocaleDateString()}
                            handleCheckBoxClick = {handleCheckBoxClick}
                            handleModalWindow={() => setDeleteModalWindow({index, idx: vacancy?.id})}
                            checkAll = {checkAll}
                            queryKey = {queryKey}
                            tables = {tables}
                        />
                    ))}
                </TableBody>
            </Table>
            {deleteModalWindow.index !== null && deleteModalWindow.index < vacancies.length && deleteModalWindow.idx !== null && (
                <DeleteModalWindow 
                    text = {deleteModalWindow.index < 0 ? "все выбранные вакансии" : vacancies[deleteModalWindow.index].name} 
                    title = {deleteModalWindow.index < 0 ? "вакансий" : "вакансии"}
                    subtitle={deleteModalWindow.index < 0 ? "выбранные вакансии" : "эту вакансию"} 
                    handleDelete = {() => {
                        if (deleteModalWindow.index < 0) {
                            handleDeleteList();
                        } else {
                            handleDelete(deleteModalWindow.idx)
                        }
                        setDeleteModalWindow({index : null, idx : null})
                    }} 
                    handleClose={() => setDeleteModalWindow({index : null, idx : null})}/>
            )}
        </>
    )
};

export default VacanciesByStage;
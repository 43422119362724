import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function InfoIcon() {
    return (
        <Tooltip title="Включите, чтобы опубликовать на карьерном сайте">
            <InfoOutlinedIcon
                sx={{
                    color: '#4CAF50', // начальный цвет
                    fontSize: 24,
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#599D15', // цвет при наведении
                    },
                }}
            />
        </Tooltip>
    );
}

import { ArrowUpIcon, CheckboxIcon } from "./svgComponents"
import { Pagination as MuiPagination, Box, PaginationItem, Typography, Tooltip, CircularProgress } from "@mui/material"

export const CheckBox = ({ checked, onClick, dontStopPropagation, noHover }) => {
    return (
        <CheckboxIcon
            checked={checked}
            onClick={onClick}
            dontStopPropagation={dontStopPropagation}
            sx={{
                cursor: onClick ? "pointer" : "default",
                width: "20px",
                height: "20px",
                backgroundColor: checked ? 'rgba(244, 247, 242, 1)' : 'transparent',
                borderRadius: "6px",
                border: checked ? "1px solid rgba(89, 157, 21, 1)" : "1px solid rgba(210, 214, 218, 1)",
                ...(noHover
                    ? {} // No hover styles if noHover is true
                    : {
                        "&:hover": {
                            border: onClick
                                ? "1px solid rgba(89, 157, 21, 1)"
                                : "1px solid rgba(210, 214, 218, 1)"
                        }
                    })
            }}
            svgSx={{
                width: "12px",
                height: "9px",
                color: "rgba(89, 157, 21, 1)"
            }}
        />
    )
}

export const Loading = ({sx}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: "100%",
                width: "100%",
                ...sx
            }}
        >
            Загрузка...
        </Box>
    )
}

export const CircularLoading = ({sx}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: "100%",
                width: "100%",
                ...sx
            }}
        >
            <CircularProgress />
        </Box>
    )
}

export const Error = ({error}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: "100%",
                width: "100%",
            }}
        >
            Ошибка при загрузке данных. Причина: <Typography sx={{fontSize: "20px", color: "red", lineHeight: "24px"}}>{error}</Typography>
        </Box>
    )
}

const Previous = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "8px",
            }}
        >
            <ArrowUpIcon 
                sx = {{width: 20, height: 10, transform: "rotate(270deg)"}}
                svgSx = {{width: 10, height: 6, color: "rgba(46, 64, 82, 1)"}}
            />
            <Typography sx={{fontWeight: 400, fontSize: "16px", lineHeight: "20px", color: "rgba(46, 64, 82, 1)" }}>Предыдущая</Typography>
        </Box>
    );
}

const Next = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "8px",
            }}
        >
            <Typography sx={{fontWeight: 400, fontSize: "16px", lineHeight: "20px", color: "rgba(46, 64, 82, 1)" }}>Следующая</Typography>
            <ArrowUpIcon 
                sx = {{width: 20, height: 10, transform: "rotate(90deg)"}}
                svgSx = {{width: 10, height: 6, color: "rgba(46, 64, 82, 1)"}}
            />
        </Box>
    );
}

export const Pagination = ({ sx, count, page, onChange }) => {
    const siblingCount = 2;
    let startElipsis = false;
    let endElipsis = false;
    return (
        <MuiPagination
            count={count}
            siblingCount={siblingCount}
            boundaryCount={1}
            page={page}
            onChange={onChange}
            variant="outlined"
            shape="rounded"
            sx={{
                button: { color: '#6E6E6E', borderColor: '#E0E0E0' },
                ...sx,
                flexGrow: 1,
                "& .MuiPagination-ul": {
                    display: "flex",
                    width: "100%",
                },
                "& .MuiPagination-ul li:first-of-type": {
                    marginRight: "auto",
                },
                "& .MuiPagination-ul li:last-of-type": {
                    marginLeft: "auto",
                },
                "& .MuiPaginationItem-page": {
                    width: "46px",
                    height: "46px",
                    "&.Mui-selected": {
                        backgroundColor: "rgba(244, 247, 242, 1)",
                        color: "rgba(89, 157, 21, 1)",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        }
                    }
                }
            }}
            renderItem={(item) => {
                // control pagination
                if (item.type === "start-ellipsis") {
                    startElipsis = true;
                }
                if (item.type === "end-ellipsis") {
                    endElipsis = true;
                }
                if (item.page < page - siblingCount) {
                    if (item.page > 2) {
                        return null;
                    } else if (!startElipsis && item.page > 1) {
                        item.page = null;
                        item.type = "start-ellipsis"
                        item["aria-label"] = "Go to start-ellipsis page";
                    } 
                }
                if (item.page > page + siblingCount) {
                    if (item.page < count - 1) {
                        return null;
                    } else if (!endElipsis && item.page < count) {
                        item.page = null;
                        item.type = "end-ellipsis"
                        item["aria-label"] = "Go to end-ellipsis page";
                    }
                }
                return <PaginationItem 
                    sx = {{ 
                        border: "none",
                        padding: "12px 20px",
                        borderRadius: "8px",
                        "&:hover": {backgroundColor: "rgba(242, 243, 243, 1)"}
                    }}
                    slots={{ previous: Previous, next: Next }}
                    {...item} 
                />
            }}
        />
    )
}

export const CustomToolTip = ({ children, title, hideIfError, error, placement, align }) => {
    if (hideIfError && !title) {
        return children;
    }
    return (
        <Tooltip
            title={title || error}
            arrow
            slotProps={{
                tooltip: {
                sx: {
                    color: "rgba(46, 64, 82, 1)",
                    backgroundColor: "white",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "16px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Added shadow
                }
                }, 
                arrow : { 
                    sx: { 
                        color: "white",
                    }
                },
                popper: align ? { 
                    modifiers: [
                        {
                        name: 'offset',
                        options: {
                            offset: [0, -20],
                        },
                        },
                    ],
                } : undefined,
            }}
            placement={placement || "top"}
        >
            {children}
        </Tooltip>
    )
}
import { Box, Paper, Typography } from "@mui/material";
import CustomInput from "../../styledComponents/CustomInput";
import { memo } from "react";

const SocialLinkInput = memo(({index, link, onChange}) => {
    console.log(link.social_network);

    return (
        <CustomInput
            label={link.social_network[0].toUpperCase() + link.social_network.slice(1)}
            value={link.url}
            placeholder={"Вставьте ссылку"}
            onChange={(value) => onChange(index, value)}
            style={{ marginLeft: "8px" }}
        />
    );
});

const SocialLinks = ({ socialLinks, onChange }) => {
    return (
        <Paper sx={{ p: 3, border: "none", boxShadow: "none", borderRadius: "8px" }}>
            <Typography
                variant="h6"
                sx={{
                    mb: "36px",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "28px",
                }}
            >
                Социальные сети
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                {socialLinks.map((link, index) => (
                    <SocialLinkInput key={link.social_network} index={index} link={link} onChange={onChange} />
                ))}
            </Box>
        </Paper>
    )
};

export default SocialLinks;
import React, { useState, useEffect } from "react";
import { Box, Button, TextField, FormControl, FormLabel, MenuItem, Typography, Select } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { fetchLanguages } from "../../services/hhpublicationApi";
import { TrashIcon } from "../svgComponents";
import LabeledInput from "../main/LabeledInput";

const LanguageSection = ({ languagesData, setLanguagesData }) => {
    const [languages, setLanguages] = useState([
        {
            "id": "abk",
            "name": "Абхазский",
            "uid": "68"
        },
        {
            "id": "abq",
            "name": "Абазинский",
            "uid": "74"
        },
        {
            "id": "afr",
            "name": "Африкаанс",
            "uid": "5"
        },
        {
            "id": "amh",
            "name": "Амхарский",
            "uid": "143"
        },
        {
            "id": "ara",
            "name": "Арабский",
            "uid": "3"
        },
        {
            "id": "ava",
            "name": "Аварский",
            "uid": "176"
        },
        {
            "id": "aze",
            "name": "Азербайджанский",
            "uid": "1"
        },
        {
            "id": "bak",
            "name": "Башкирский",
            "uid": "6"
        },
        {
            "id": "bel",
            "name": "Белорусский",
            "uid": "7"
        },
        {
            "id": "ben",
            "name": "Бенгальский",
            "uid": "178"
        },
        {
            "id": "bod",
            "name": "Тибетский",
            "uid": "198"
        },
        {
            "id": "bos",
            "name": "Боснийский",
            "uid": "179"
        },
        {
            "id": "bua",
            "name": "Бурятский",
            "uid": "180"
        },
        {
            "id": "bul",
            "name": "Болгарский",
            "uid": "8"
        },
        {
            "id": "cat",
            "name": "Каталанский",
            "uid": "72"
        },
        {
            "id": "ces",
            "name": "Чешский",
            "uid": "51"
        },
        {
            "id": "che",
            "name": "Чеченский",
            "uid": "50"
        },
        {
            "id": "chm",
            "name": "Марийский",
            "uid": "191"
        },
        {
            "id": "chv",
            "name": "Чувашский",
            "uid": "52"
        },
        {
            "id": "crs",
            "name": "Креольский (Сейшельские острова)",
            "uid": "67"
        },
        {
            "id": "dag",
            "name": "Дагестанский",
            "uid": "181"
        },
        {
            "id": "dan",
            "name": "Датский",
            "uid": "14"
        },
        {
            "id": "dar",
            "name": "Даргинский",
            "uid": "144"
        },
        {
            "id": "deu",
            "name": "Немецкий",
            "uid": "58"
        },
        {
            "id": "ell",
            "name": "Греческий",
            "uid": "12"
        },
        {
            "id": "eng",
            "name": "Английский",
            "uid": "57"
        },
        {
            "id": "epo",
            "name": "Эсперанто",
            "uid": "64"
        },
        {
            "id": "est",
            "name": "Эстонский",
            "uid": "54"
        },
        {
            "id": "eus",
            "name": "Баскский",
            "uid": "177"
        },
        {
            "id": "fas",
            "name": "Персидский",
            "uid": "30"
        },
        {
            "id": "fin",
            "name": "Финский",
            "uid": "48"
        },
        {
            "id": "fra",
            "name": "Французский",
            "uid": "59"
        },
        {
            "id": "gle",
            "name": "Ирландский",
            "uid": "184"
        },
        {
            "id": "heb",
            "name": "Иврит",
            "uid": "15"
        },
        {
            "id": "hin",
            "name": "Хинди",
            "uid": "56"
        },
        {
            "id": "hrv",
            "name": "Хорватский",
            "uid": "49"
        },
        {
            "id": "hun",
            "name": "Венгерский",
            "uid": "9"
        },
        {
            "id": "hye",
            "name": "Армянский",
            "uid": "4"
        },
        {
            "id": "ind",
            "name": "Индонезийский",
            "uid": "60"
        },
        {
            "id": "inh",
            "name": "Ингушский",
            "uid": "62"
        },
        {
            "id": "isl",
            "name": "Исландский",
            "uid": "185"
        },
        {
            "id": "ita",
            "name": "Итальянский",
            "uid": "17"
        },
        {
            "id": "jpn",
            "name": "Японский",
            "uid": "55"
        },
        {
            "id": "kas",
            "name": "Кашмирский",
            "uid": "63"
        },
        {
            "id": "kat",
            "name": "Грузинский",
            "uid": "13"
        },
        {
            "id": "kaz",
            "name": "Казахский",
            "uid": "18"
        },
        {
            "id": "kbd",
            "name": "Кабардино-черкесский",
            "uid": "70"
        },
        {
            "id": "khm",
            "name": "Кхмерский (Камбоджийский)",
            "uid": "66"
        },
        {
            "id": "kir",
            "name": "Кыргызский",
            "uid": "19"
        },
        {
            "id": "kom",
            "name": "Коми",
            "uid": "21"
        },
        {
            "id": "kor",
            "name": "Корейский",
            "uid": "22"
        },
        {
            "id": "krc",
            "name": "Карачаево-балкарский",
            "uid": "71"
        },
        {
            "id": "krl",
            "name": "Карельский",
            "uid": "186"
        },
        {
            "id": "kum",
            "name": "Кумыкский",
            "uid": "75"
        },
        {
            "id": "kur",
            "name": "Курдский",
            "uid": "23"
        },
        {
            "id": "lao",
            "name": "Лаосский",
            "uid": "188"
        },
        {
            "id": "lat",
            "name": "Латинский",
            "uid": "109"
        },
        {
            "id": "lav",
            "name": "Латышский",
            "uid": "24"
        },
        {
            "id": "lbe",
            "name": "Лакский",
            "uid": "187"
        },
        {
            "id": "lez",
            "name": "Лезгинский",
            "uid": "189"
        },
        {
            "id": "lit",
            "name": "Литовский",
            "uid": "25"
        },
        {
            "id": "mke",
            "name": "Македонский",
            "uid": "26"
        },
        {
            "id": "mns",
            "name": "Мансийский",
            "uid": "202"
        },
        {
            "id": "mon",
            "name": "Монгольский",
            "uid": "28"
        },
        {
            "id": "mya",
            "name": "Бирманский",
            "uid": "210"
        },
        {
            "id": "nep",
            "name": "Непальский",
            "uid": "192"
        },
        {
            "id": "nld",
            "name": "Голландский",
            "uid": "11"
        },
        {
            "id": "nog",
            "name": "Ногайский",
            "uid": "142"
        },
        {
            "id": "nor",
            "name": "Норвежский",
            "uid": "29"
        },
        {
            "id": "oss",
            "name": "Осетинский",
            "uid": "69"
        },
        {
            "id": "pan",
            "name": "Панджаби",
            "uid": "193"
        },
        {
            "id": "pol",
            "name": "Польский",
            "uid": "31"
        },
        {
            "id": "por",
            "name": "Португальский",
            "uid": "32"
        },
        {
            "id": "pus",
            "name": "Пушту",
            "uid": "194"
        },
        {
            "id": "ron",
            "name": "Румынский",
            "uid": "33"
        },
        {
            "id": "rus",
            "name": "Русский",
            "uid": "34"
        },
        {
            "id": "sah",
            "name": "Якутский",
            "uid": "203"
        },
        {
            "id": "san",
            "name": "Санскрит",
            "uid": "35"
        },
        {
            "id": "slk",
            "name": "Словацкий",
            "uid": "37"
        },
        {
            "id": "slv",
            "name": "Словенский",
            "uid": "38"
        },
        {
            "id": "som",
            "name": "Сомалийский",
            "uid": "195"
        },
        {
            "id": "spa",
            "name": "Испанский",
            "uid": "16"
        },
        {
            "id": "sqi",
            "name": "Албанский",
            "uid": "2"
        },
        {
            "id": "srp",
            "name": "Сербский",
            "uid": "36"
        },
        {
            "id": "swa",
            "name": "Суахили",
            "uid": "39"
        },
        {
            "id": "swe",
            "name": "Шведский",
            "uid": "53"
        },
        {
            "id": "tam",
            "name": "Тамильский",
            "uid": "76"
        },
        {
            "id": "tat",
            "name": "Татарский",
            "uid": "42"
        },
        {
            "id": "tgk",
            "name": "Таджикский",
            "uid": "40"
        },
        {
            "id": "tgl",
            "name": "Тагальский",
            "uid": "196"
        },
        {
            "id": "tha",
            "name": "Тайский",
            "uid": "41"
        },
        {
            "id": "tly",
            "name": "Талышский",
            "uid": "197"
        },
        {
            "id": "tuk",
            "name": "Туркменский",
            "uid": "44"
        },
        {
            "id": "tur",
            "name": "Турецкий",
            "uid": "43"
        },
        {
            "id": "tyv",
            "name": "Тувинский",
            "uid": "199"
        },
        {
            "id": "udm",
            "name": "Удмуртский",
            "uid": "209"
        },
        {
            "id": "uig",
            "name": "Уйгурский",
            "uid": "73"
        },
        {
            "id": "ukr",
            "name": "Украинский",
            "uid": "46"
        },
        {
            "id": "urd",
            "name": "Урду",
            "uid": "47"
        },
        {
            "id": "uzb",
            "name": "Узбекский",
            "uid": "45"
        },
        {
            "id": "vie",
            "name": "Вьетнамский",
            "uid": "10"
        },
        {
            "id": "vls",
            "name": "Фламандский",
            "uid": "201"
        },
        {
            "id": "zho",
            "name": "Китайский",
            "uid": "20"
        },
        {
            "id": "zlm",
            "name": "Малазийский",
            "uid": "110"
        }
    ]);
    const [languageLevels] = useState([
        { id: "a1", name: "A1 — Начальный" },
        { id: "a2", name: "A2 — Элементарный" },
        { id: "b1", name: "B1 — Средний" },
        { id: "b2", name: "B2 — Средне-продвинутый" },
        { id: "c1", name: "C1 — Продвинутый" },
        { id: "c2", name: "C2 — В совершенстве" },
        { id: "l1", name: "Родной" },
    ]);

    // useEffect(() => {
    //     const loadLanguages = async () => {
    //         const data = await fetchLanguages();
    //         setLanguages(data || []);
    //     };
    //     loadLanguages();
    // }, []);

    const handleAddLanguage = () => {
        setLanguagesData([...languagesData, '']);
    };

    const handleLanguageChange = (index, value) => {
        const updatedLanguages = [...languagesData];
        updatedLanguages[index] = value;
        setLanguagesData(updatedLanguages);
    };

    const handleRemoveLanguage = (index) => {
        const updatedLanguages = languagesData.filter((_, i) => i !== index);
        setLanguagesData(updatedLanguages);
    };

    return (
        <FormControl fullWidth sx={{ pt: "17px", borderTop: "1px solid rgba(235, 238, 239, 1)", display: "flex", flexDirection: "column", gap: "24px" }}>
            <FormLabel sx={{ fontWeight: 600, color: "#151813", fontSize: "24px" }}>
                Языки
            </FormLabel>
            {languagesData.map((language, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <LabeledInput
                        value={language}
                        onChange={(e) => handleLanguageChange(index, e.target.value)}
                        placeholder="Введите язык и уровень (Казахский - родной)"
                        name="language"
                        sx={{
                            width: '100%'
                        }}
                    />
                    <Button
                        color="error"
                        onClick={() => handleRemoveLanguage(index)}
                        sx={{
                            ml: 2,
                            padding: 0,
                            minWidth: "unset",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            }
                        }}
                        disabled={languagesData.length === 1}
                    >
                        <TrashIcon
                            sx={{ width: 56, height: 56 }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color: languagesData.length === 1 ? "rgba(173, 181, 189, 1)" : "rgba(46, 64, 82, 1)",
                            }}
                        />
                    </Button>
                </Box>
            ))}

            {/* {languagesData.map((language, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Select
                        fullWidth
                        value={language.id || ""}
                        onChange={(e) => handleLanguageChange(index, "id", e.target.value)}
                        displayEmpty
                        sx={{ 
                            mr: 2,
                        }}
                    >
                        <MenuItem value="" disabled>
                            Выберите язык
                        </MenuItem>
                        {languages.map((lang) => (
                            <MenuItem key={lang.id} value={lang.id}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        fullWidth
                        value={language.level || ""}
                        onChange={(e) => handleLanguageChange(index, "level", e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="">Выберите уровень</MenuItem>
                        {languageLevels.map((level) => (
                            <MenuItem key={level.id} value={level.id}>
                                {level.name}
                            </MenuItem>
                        ))}
                    </Select>
                    
                    <Button
                        color="error"
                        onClick={() => handleRemoveLanguage(index)}
                        sx={{ 
                            ml: 2,
                            padding: 0,
                            minWidth: "unset",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active" : {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            }
                        }}
                        disabled={languagesData.length === 1}
                    >
                        <TrashIcon 
                            sx = {{width : 56, height: 56}}
                            svgSx = {{
                                width: 20,
                                height: 20,
                                color: languagesData.length === 1 ? "rgba(173, 181, 189, 1)" : "rgba(46, 64, 82, 1)",
                            }}
                        />
                    </Button>
                </Box>
            ))} */}
            <Box
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={handleAddLanguage}
            >
                <AddCircleIcon sx={{ color: "#599D15", mr: 1 }} />
                <Typography sx={{ color: "#599D15", fontWeight: 500 }}>
                    Указать еще один язык
                </Typography>
            </Box>
        </FormControl>
    );
};

export default LanguageSection;

import React, { useState } from 'react';
import { TableRow, TableCell, IconButton, Menu, MenuItem, Box, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateVacanciesStatus } from '../../services/vacancyApi';
import { PencilIcon, ProfileIcon, TrashIcon, UnZipIcon, ZipIcon } from '../svgComponents';
import { CheckBox, CustomToolTip } from '../tapHrStyledComponents';

const VacancyTableRow = ({ id, position, region, department, responses, newResponses, recruiterImage, recruiterName, date, handleCheckBoxClick, checkAll, queryKey, tables, handleModalWindow }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    // Состояние для открытия меню
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(false);

    // check if main checkbox is checked which forces all unchecked checkboxes to be checked
    if (checkAll.fromParent && checkAll.currState && !checked) {
        setChecked(true);
    }

    // check if main checkbox is unchecked which forces all checked checkboxes to be unchecked
    if (checkAll.fromParent && !checkAll.currState && checked) {
        setChecked(false);
    }

    const handleRowClick = () => {
        if (tables?.isActive) {
            navigate(`/vacancy/${id}`);
        }
    };

    const handleMenuOpen = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        setAnchorEl(null);
    };

    // navigate to the edit page
    const handleEditClick = (event) => {
        event.stopPropagation(); // Останавливаем всплытие клика
        handleMenuClose(event); // Закрываем меню
        navigate(`/vacancy/edit/${id}`); // Переход на страницу редактирования
    };

    const handleCheckboxChange = () => {
        handleCheckBoxClick(id);
        setChecked(prev => !prev);
    };

    const archivateMutation = useMutation(updateVacanciesStatus, {
        onSuccess: () => {
            console.log(queryKey);
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            });
        },
    });

    /**
     * 
     * @param {React.SyntheticEvent} event - button event
     * @param {Number} statusId - status id pass 3 if you want to archivate and pass 1 if you want to restore
     * @returns {void} 
     */
    const handleStatusChange = (event, statusId) => {
        event.stopPropagation();
        archivateMutation.mutate({ status_id: statusId, vacancy_ids: [id] }); 
        handleMenuClose(event); 
    };

    return (
        <TableRow hover = {tables?.isActive} onClick={handleRowClick} sx={{ cursor: 'pointer' }}>
            <TableCell sx = {{width: "24px"}}></TableCell>
            <TableCell sx = {{width: "20px", padding: 0}}>
                <CheckBox checked={checked} onClick={handleCheckboxChange} dontStopPropagation={false}/>
            </TableCell>
            <TableCell sx = {{padding : "16px", width: "25svw"}}>{position}</TableCell>
            <TableCell sx = {{padding: "16px 24px"}}>{region}</TableCell>
            {!tables?.notDepartment && <TableCell sx = {{padding: "16px 24px"}}>{department}</TableCell>}
            <TableCell sx = {{padding: "16px 24px"}}
            >
                {responses} 
                {newResponses > 0 && <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "rgba(18, 156, 82, 1)", marginLeft: "12px", backgroundColor: "rgba(242, 248, 244, 1)", padding: "2px 8px", borderRadius: "16px" }}
                > 
                    {`+${newResponses}`}
                </Typography>}
            </TableCell>
            <TableCell sx = {{padding: "16px 24px"}}>
                <CustomToolTip title = {recruiterName} error={"Неизвестный рекрутер"} placement={"top-start"} align={true}>
                    <Box sx = {{width: 40, height: 40}}> 
                        {recruiterImage ? <img style = {{width: 40, height: 40, borderRadius: "50%"}} src = {recruiterImage} alt = "profile"></img> :
                            <ProfileIcon
                            key = {id}
                            sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "rgba(232, 239, 228, 1)",
                            }}
                            svgSx={{
                                width: "20px",
                                height: "20px",
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                        }
                    </Box>
                </CustomToolTip>
            </TableCell>
            <TableCell sx = {{padding: "16px 24px"}}>{date}</TableCell>
            <TableCell sx = {{padding: "16px 24px"}} align="right">
                <IconButton size="small" onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
                {/* Меню с действиями */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {(tables?.isActive || tables?.isDraft) && 
                        <MenuItem onClick={handleEditClick}>
                            <PencilIcon sx = {{width: 20, height: 20}} svgSx = {{width: 15, height: 15}}/>
                            <Typography sx={{marginLeft: "8px"}}>Редактировать</Typography>
                        </MenuItem>}
                    {tables?.isActive && 
                        <MenuItem onClick={(e) => {handleStatusChange(e, 3)}}>
                            <ZipIcon sx = {{width: 20, height: 20}} svgSx = {{width: 15, height: 15}}/>
                            <Typography sx={{marginLeft: "8px"}}>Архивировать</Typography>
                        </MenuItem>}
                    {tables?.isArchive && 
                        <MenuItem onClick={(e) => {handleStatusChange(e, 1)}}>
                            <UnZipIcon sx = {{width: 20, height: 20}} svgSx = {{width: 15, height: 15}}/>
                            <Typography sx={{marginLeft: "8px"}}>Восстановить</Typography>
                        </MenuItem>}
                    {(tables?.isArchive || tables?.isDraft) && 
                        <MenuItem onClick={(e) => {
                            handleMenuClose(e);
                            handleModalWindow();
                        }}>
                            <TrashIcon sx = {{width: 20, height: 20}} svgSx = {{width: 15, height: 15}}/>
                            <Typography sx={{marginLeft: "8px"}}>Удалить</Typography>
                        </MenuItem>}
                </Menu>
            </TableCell>
        </TableRow>
    );
};

export default VacancyTableRow;

import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

/**
 * 
 * @param {any} input - input value
 * @param {Number} delay - delay in ms, default 300
 * @param {String} queryKeyStartingValue - query key starting value
 * @returns {Object} debouncedValue, value, setValue, isDebouncing
 */
const useDebounce = (input, delay = 300, queryKeyStartingValue = []) => {
    const [value, setValue] = useState(input);
    const [debouncedValue, setDebouncedValue] = useState(input);
    const [isDebouncing, setIsDebouncing] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (queryKeyStartingValue && queryClient.getQueryData([...queryKeyStartingValue, value])) {
            setDebouncedValue(value)
            setIsDebouncing(false);
            return;
        }
        
        setIsDebouncing(true);
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            setIsDebouncing(false);
        }, delay);

        return () => clearTimeout(handler);
    }, [value, delay]);

    return { debouncedValue, value, setValue, isDebouncing };
}

export default useDebounce;

import React, { useState, useRef } from 'react';
import { Container, Box, Paper } from '@mui/material';
import { Pagination } from '../../components/tapHrStyledComponents';
import FilterBar from '../../components/vacancy/FilterBar';
import VacancyTable from '../../components/vacancy/VacancyTable';
import CreateVacancyModal from '../../components/vacancy/CreateVacancyModal';
import { useQuery } from '@tanstack/react-query';
import { fetchVacancies } from '../../services/vacancyApi';
import useDebounce from '../../hooks/useDebounce';

const VacanciesPage = () => {
    const rowsPerPage = useRef(10); // Количество строк на странице
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [statusId, setStatusId] = useState(1); // Vacancy status id
    const [open, setOpen] = useState(false); // Состояние модального окна
    const queryKey = useRef('vacancies')
    const {debouncedValue, value, setValue} = useDebounce({ vacancy: '', department: '', region: '' });

    // Fetch vacancies using debounced filters
    const { data, isLoading, isError } = useQuery(
        [queryKey.current, statusId, currentPage, rowsPerPage.current, debouncedValue],
        async () => {
            const result = await fetchVacancies(currentPage, rowsPerPage.current, statusId, {
                name: debouncedValue.vacancy,
                city_name: debouncedValue.region,
                department_name: debouncedValue.department
            })
            if (result?.data?.length === 0 && currentPage !== 1) {
                setCurrentPage((prevPage) => prevPage - 1);
            }
            return result;
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true, // Avoid flickering when changing pages
        }
    );

    // Обработчик изменения количества строк на странице
    // const handleRowsPerPageChange = (event) => {
    //     setRowsPerPage(event.target.value);
    //     setCurrentPage(1); // Сброс на первую страницу
    // };

    // Обработчик изменения текущей страницы
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container maxWidth="none" sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <Paper
                elevation={0}
                sx={{
                    flex: 1,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                    backgroundColor: "rgba(248, 249, 249, 1)"
                }}
            >
                {/* Фильтр */}
                <FilterBar
                    label={"Создать вакансию"}
                    handleOpen={handleClickOpen}
                    filter={value}
                    setFilter={setValue}
                />

                {/* Таблица */}
                <Box sx={{ flex: 1, overflowY: 'auto', padding: '0' }}>
                    {/* {isLoading ? (
                        <Typography>Загрузка...</Typography>
                    ) : isError ? (
                        <Typography color="error">Ошибка при загрузке вакансий</Typography>
                    ) : ( */}
                    <VacancyTable isLoading={isLoading} isError={isError} vacancies={data?.data} queryKey={queryKey.current} activeTab={statusId - 1} setActiveTab={setStatusId} setCurrentPage={setCurrentPage} />
                    {/* )} */}

                </Box>

                {/* Пагинация и выбор количества строк */}
                {!isLoading && <Pagination sx={{ my: 3 }} count={data?.meta?.last_page || 1} page={currentPage} onChange={handlePageChange} />}
            </Paper>

            {/* Модальное окно */}
            <CreateVacancyModal open={open} handleClose={handleClose} />
        </Container>
    );
};

export default VacanciesPage;

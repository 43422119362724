import React, { memo } from "react";
import {
    Box,
    Typography,
    Button,
} from '@mui/material';
import { MenuIcon, LockIcon, TrashIcon } from "../svgComponents";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { onDragEnd } from "../../utils/dragAndDrop";

const SecondStep = memo(({stages, setStages }) => {
    const changeIndexes = (stageStartId, stageEndId) => {
        const newStages = [...stages];
    
        // Remove the element from its original position
        const [movedElement] = newStages.splice(stageStartId, 1);
    
        // Insert the element at the new position
        newStages.splice(stageEndId, 0, movedElement);

        // Update the `order` of all elements based on their new positions
        newStages.forEach((stage, index) => {
            stage.order = index + 1; // Assuming order starts from 1
        });
    
        return newStages;
    };

    const handleDragEnd = ({elementStartIdx : stageStartId, elementEndIdx : stageEndId}) => {
        const newStages = changeIndexes(stageStartId, stageEndId);
        setStages(newStages);
    }

    const deleteStage = (index) => {
        const newStages = stages.filter((_, idx) => idx !== index);

        // Обновляем порядок после удаления
        newStages.forEach((stage, idx) => {
            stage.order = idx + 1;
        });

        setStages(newStages);
    };

    return (
        <>
            <Typography sx={{ color: 'rgba(116, 116, 115, 1)', fontSize: '14px', padding: "12px 24px", backgroundColor: "rgba(248, 249, 249, 1)", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>Название этапа</Typography>
            <Box>
                <Box
                    key={'first'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #E0E0E0',
                        padding: "16px 24px"
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <LockIcon sx={{width: "40px", height: "40px"}} svgSx={{width: "24px", height: "20px", color : "rgba(21, 24, 19, 1)"}}/>
                        <Typography>Новый</Typography>
                    </Box>
                </Box>
                <DragDropContext onDragEnd={(e) => onDragEnd(e, handleDragEnd)}>
                    <Droppable droppableId={"0"}>
                        {(provided, snapshot) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="droppable"
                                sx = {{
                                    display: 'flex',
                                    border: snapshot.isDraggingOver ? '1px solid rgba(89, 157, 21, 1)' : 'none',
                                    transition: 'background-color 0.2s ease',
                                    flexDirection: 'column',
                                    maxHeight: "401px",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                {stages.map((stage, index) => (
                                    <Draggable key={stage.order} draggableId={index.toString()} index={index} >
                                        {(provided) => (
                                            <Box
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #E0E0E0',
                                                    padding: "16px 24px"
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                                    <MenuIcon sx = {{width: "40px", height : "40px"}} svgSx={{width: "16px", height: "14px", color: "rgba(21, 24, 19, 1)"}}/>
                                                    <Typography>{stage.name}</Typography>
                                                </Box>
                                                <Box sx={{display: "flex", gap: "24px", alignItems: "center"}}>
                                                    <TrashIcon onClick={() => deleteStage(index)} sx={{cursor: "pointer", width: 46, height: 46, borderRadius: "8px", "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)"}}} svgSx={{color: "rgba(21, 24, 19, 1)", width: 15, height: 17}} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
                <Box
                    key={'last'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #E0E0E0',
                        padding: "16px 24px"
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <LockIcon sx={{width: "40px", height: "40px"}} svgSx={{width: "24px", height: "20px", color : "rgba(21, 24, 19, 1)"}}/>
                        <Typography>Принял оффер</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
});

export default SecondStep;

import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TemplatesRow from './TemplatesRow';
import { Loading } from '../tapHrStyledComponents';
import { DeleteModalWindow } from '../common/modalWindows/deleteModalWindow';

const TemplatesPanel = ({
    handleDelete,
    templates,
    handleModalWindow,
    currentId,
    isLoading,
    hideAuthor,
}) => {
    const currentManagerId =
        parseInt(localStorage.getItem("manager_id")) || null;
    const [deleteModalWindow, setDeleteModalWindow] = useState({
        index: null,
        id: null,
    });

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead
                        sx={{ backgroundColor: "rgba(248, 249, 249, 1)" }}
                    >
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: "30svw",
                                    minWidth: "650px",
                                    padding: "12px 24px",
                                    fontSize: 14,
                                    lineHeight: "18px",
                                }}
                            >
                                Название Шаблона
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: "10svw",
                                    minWidth: "150px",
                                    padding: "12px 24px",
                                    fontSize: 14,
                                    lineHeight: "18px",
                                }}
                            >
                                {hideAuthor ? "": "Автор"}
                            </TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates.map((template, index) => {
                            const isLast = index === templates.length - 1;
                            if (template.id === currentId && isLoading) {
                                return (
                                    <TableRow key={`loading-${template.id}`}>
                                        <TableCell
                                            sx={{
                                                width: "30svw",
                                                minWidth: "650px",
                                                padding: "16px 24px",
                                                fontSize: "16px",
                                                fontWeight: 500,
                                                lineHeight: "20px",
                                                ...(isLast && {borderBottom: "none"})
                                            }}
                                        >
                                            <Loading
                                                key={index}
                                                sx={{
                                                    width: "100%",
                                                    justifyContent:
                                                        "space-between",
                                                    height: "40px",
                                                    color: "rgba(89, 157, 21, 1)",
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{...(isLast && {borderBottom: "none"})}}></TableCell>
                                        <TableCell sx={{...(isLast && {borderBottom: "none"})}}></TableCell>
                                    </TableRow>
                                );
                            }

                            const isEditable = template?.manager_id === currentManagerId;

                            return (
                                <TemplatesRow
                                    key={template.id}
                                    template={template}
                                    isEditable={isEditable}
                                    handleDelete={setDeleteModalWindow}
                                    handleModalWindow={handleModalWindow}
                                    index={index}
                                    isLast={index === templates.length - 1}
                                    hideAuthor={hideAuthor}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {deleteModalWindow.index !== null &&
                deleteModalWindow.index < templates.length &&
                deleteModalWindow.id !== null && (
                    <DeleteModalWindow
                        text={templates[deleteModalWindow.index].name}
                        title="шаблона"
                        subtitle="этот шаблон"
                        handleDelete={() => {
                            handleDelete(deleteModalWindow.id);
                            setDeleteModalWindow({ index: null, id: null });
                        }}
                        handleClose={() =>
                            setDeleteModalWindow({ index: null, id: null })
                        }
                    />
                )}
        </Box>
    );
};

export default TemplatesPanel;

import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, Typography, Avatar } from '@mui/material';
import ResponsesTab from '../../components/candidates/ResponsesTab';
import ResumeUi from '../../components/candidateInfo/candidateResumeUi';
import MainInformationUi from '../../components/candidateInfo/candidateMainInfoUi';
import CommentSectionUi from '../../components/candidateInfo/candidateCommentsUi';
import { useQuery } from '@tanstack/react-query';
import { fetchCandidateById } from "../../services/candidatesApi";
import Negotiations from '../../components/candidateInfo/negotiations';
import RefusalModal from '../../components/vacancy/RefusalModal';

const TabPanel = ({ children, value, index }) => {
    return (
        <div hidden={value !== index} role="tabpanel">
            {children && value === index && <Box sx={{ paddingTop: "20px" }}>{children}</Box>}
        </div>
    );
};

const CandidateDetails = ({ candidateId, queryKey }) => {
    const [activeMainTab, setActiveMainTab] = useState(0); // Основные табы
    const resumeSxRef = useRef({
        paddingTop: "24px",
        border: "1px solid #E0E0E0",
        borderRadius: 2,
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        gap: 2,
    });
    const mainInfoRef = useRef({ isTwoColumned: true, render: { isDivisible: false } });
    const negotiationsRef = useRef({ render: { isDivisible: false, isHeader: false, isButton: true } });
    const [isRefusalWindow, setIsRefusalWindow] = useState(false);


    const { data: candidate, isLoading, refetch } = useQuery(
        queryKey,
        () => fetchCandidateById(candidateId),
        {
            enabled: !!candidateId, // Fetch only if candidateId is available
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );
    const resumes = candidate?.resumes ?? [];
    const negotiations = candidate?.negotiations ?? [];
    console.log("CandidateDetails being rendered");

    const handleRefusalModalWindow = (e) => {
        e.stopPropagation()
        setIsRefusalWindow(prev => !prev)
    }

    const handleSubmit = (reason) => {
        refetch()
        console.log('Кандидат успешно отклонен');
    };

    const handleMainTabChange = (event, newValue) => {
        setActiveMainTab(newValue);
    };

    if (!candidateId || isLoading) {
        return (<Typography variant="body1" color="textSecondary">
            Выберите кандидата для отображения подробностей.
        </Typography>)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box sx={{ background: '#fff', padding: activeMainTab ? "24px 24px 0 24px" : 3, borderRadius: 2, boxShadow: 1 }}>
                {/* Верхняя часть с аватаркой и ФИО */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: '20px',
                    }}
                >
                    <Avatar
                        src={candidate.avatar_medium}
                        alt={candidate.name}
                        sx={{ width: 64, height: 64 }}
                    />
                    <Box>
                        <Typography variant="h6">{candidate.first_name} {candidate.last_name}</Typography>
                    </Box>
                </Box>

                {/* Tabs */}
                <Tabs
                    value={activeMainTab}
                    onChange={handleMainTabChange}
                    variant="fullWidth"
                    TabIndicatorProps={{
                        style: { backgroundColor: '#599D15', height: '3px', borderRadius: "100px 100px 0 0" },
                    }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiTab-root': {
                            maxWidth: 106,
                            textTransform: 'none',
                        },
                    }}
                >
                    <Tab
                        sx={{ mr: 1 }}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <ellipse
                                        cx="12"
                                        cy="17.5"
                                        rx="7"
                                        ry="3.5"
                                        fill="none"
                                        stroke={activeMainTab === 0 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                    />
                                    <circle
                                        cx="12"
                                        cy="7"
                                        r="4"
                                        fill="none"
                                        stroke={activeMainTab === 0 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <Typography
                                    variant="body2"
                                    sx={{ color: activeMainTab === 0 ? "#599D15" : "#ADB5BD" }}
                                >
                                    Резюме
                                </Typography>
                            </Box>
                        }
                    />
                    <Tab
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8 6V5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5V6M2 10.3475C2 10.3475 5.11804 12.4244 9.97767 12.9109M22 10.3475C22 10.3475 18.882 12.4244 14.0223 12.9109M6 22H18C20.2091 22 22 20.2091 22 18V10C22 7.79086 20.2091 6 18 6H6C3.79086 6 2 7.79086 2 10V18C2 20.2091 3.79086 22 6 22Z"
                                        fill="#fff"
                                        stroke={activeMainTab === 1 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M14 12.16V13.16C14 13.17 14 13.17 14 13.18C14 14.27 13.99 15.16 12 15.16C10.02 15.16 10 14.28 10 13.19V12.16C10 11.16 10 11.16 11 11.16H13C14 11.16 14 11.16 14 12.16Z"
                                        fill="none"
                                        stroke={activeMainTab === 1 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <Typography
                                    variant="body2"
                                    sx={{ color: activeMainTab === 1 ? "#599D15" : "#ADB5BD" }}
                                >
                                    Отклики
                                </Typography>
                            </Box>
                        }
                    />
                </Tabs>
                <Box sx={{ borderBottom: '1px solid #E6E6E6', width: '100%' }} />

                {/* Tab Content */}
                <TabPanel value={activeMainTab} index={0}>
                    <MainInformationUi phone={candidate?.phone} email={candidate?.email} birth_date={candidate?.birth_date} city_name={candidate?.city_name} desired_position={candidate?.desiredPosition} salary={candidate?.salary} isTwoColumned={mainInfoRef.current.isTwoColumned} render={mainInfoRef.current.render} />
                </TabPanel>
                <TabPanel value={activeMainTab} index={1}>

                </TabPanel>
            </Box>
            {activeMainTab === 0 ? (
                <ResumeUi
                    sx={resumeSxRef.current}
                    resumes={resumes}
                    candidate={candidate}
                />
            ) : <></>}

            {activeMainTab === 1 ?
                (<>
                    {/* <ResponsesTab negotiations={candidate.negotiations} /> */}
                    <Negotiations render={negotiationsRef.current.render} negotiations={negotiations} handleRefusal={handleRefusalModalWindow} />
                    <CommentSectionUi candidateId={candidate.id} comments={candidate?.comments} queryKey={queryKey} />
                </>)
                : <></>}

            {isRefusalWindow && <RefusalModal
                open={true}
                onClose={handleRefusalModalWindow}
                reasons={[
                    {
                        id: 'discard_by_employer',
                        name: 'Не подходит'
                    },
                    {
                        id: 'discard_by_applicant',
                        name: 'Кандидат отказался'
                    },
                    {
                        id: 'discard_no_interaction',
                        name: 'Не выходит на связь',
                    },
                    {
                        id: 'discard_vacancy_closed',
                        name: 'Вакансия закрыта'
                    },
                    {
                        id: 'discard_to_other_vacancy',
                        name: 'Перевод на другую вакансию'
                    },
                ]}
                companyId={localStorage.getItem('company_id')}
                candidateId={candidate.negotiation_id}
                onSuccess={handleSubmit}
            />}
        </Box>
    );
};

export default CandidateDetails;

import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateVacancy } from '../../services/vacancyApi';
import { useParams } from 'react-router-dom';


const GreenSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    display: 'flex',
    '&:active .MuiSwitch-thumb': {
        width: 22,
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#4CAF50',
                opacity: 1,
                border: 'none',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        width: 22,
        height: 22,
        borderRadius: 11,
        backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
        borderRadius: 13,
        opacity: 1,
        backgroundColor: '#E9E9EA',
        boxSizing: 'border-box',
    },
}));

export default function CustomSwitch({ value }) {
    const { id } = useParams(); // Получаем ID вакансии из URL
    const [checked, setChecked] = React.useState(value);

    const queryClient = useQueryClient();

    // Мутация для обновления вакансии
    const mutation = useMutation(
        (vacancyData) => updateVacancy(id, vacancyData),
        {
            onSuccess: () => {
                // Обновляем кэш вакансий
                queryClient.invalidateQueries(["vacancies"]);
                alert("Вакансия успешно обновлена!");
            },
            onError: (error) => {
                alert("Ошибка при обновлении вакансии:", error.message || error);
            },
        }
    );

    const handleChange = (event) => {
        setChecked(event.target.checked);
        mutation.mutate({
            is_visible: event.target.checked
        })
    };

    return (
        <GreenSwitch checked={checked} onChange={handleChange} />
    );
}

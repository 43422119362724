import React from 'react';
import {
    Box,
    Typography,
    TextField,
} from '@mui/material';
import { CheckIcon } from '../svgComponents';

const CustomStepper = ({ steps, activeStep }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 4, px: "36px" }}>
            {steps.map((label, index) => (
                <React.Fragment key={label}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '124px' }}>
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                backgroundColor:
                                    activeStep > index ? '#73C18D' : activeStep === index ? '#599D15' : 'rgba(173, 181, 189, 1)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: "#fff",
                                fontWeight: 400,
                                fontSize: '24px',
                                zIndex: 1,
                            }}
                        >
                            {activeStep > index ? (
                                <CheckIcon svgSx={{width: 12.5, height: 10.5, color: "#fff"}}/>
                            ) : (
                                index + 1
                            )}
                        </Box>
                        <Typography
                            sx={{
                                color: activeStep > index ? '#14AE5C' : activeStep === index ? '#599D15' : 'rgba(173, 181, 189, 1)',
                                fontWeight: 400,
                                fontSize: '14px',
                                textAlign: 'center',
                                mt: 1,
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>

                    {index < steps.length - 1 && (
                        <Box
                            sx={{
                                flex: 1,
                                height: "1px !important",
                                backgroundColor: activeStep > index ? '#73C18D' : 'rgba(173, 181, 189, 1)',
                                mx: 2,
                                position: 'relative',
                                top: 21,
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};


export default CustomStepper;
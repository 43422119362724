import React, { useState } from "react";
import {
    Box,
    Typography,
    Select,
    MenuItem,
    IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function ShareLink({ vacancyId, channels }) {
    const [selectedChannel, setSelectedChannel] = useState("");

    const handleChannelChange = (event) => {
        setSelectedChannel(event.target.value);
    };

    const handleCopy = () => {
        const selectedChannelObj = channels.find(channel => channel?.utm_source === selectedChannel);
        // Формируем ссылку
        const utm_medium = selectedChannelObj?.utm_medium
            ? `&utm_medium=${encodeURIComponent(selectedChannelObj.utm_medium)}`
            : "";
        const utm_campaign = selectedChannelObj?.utm_campaign
            ? `&utm_campaign=${encodeURIComponent(selectedChannelObj.utm_campaign)}`
            : "";

        const link = `${localStorage.getItem('company_domain')}.taphr.kz/vacancy/${vacancyId}?company_id=${localStorage.getItem("company_id")}&utm_source=${selectedChannelObj ? encodeURIComponent(selectedChannelObj?.utm_source) : "[Выберите канал]"}${utm_medium}${utm_campaign}`;
        navigator.clipboard.writeText(link);
        alert("Ссылка скопирована!");
    };

    // Формируем отображаемую ссылку
    const generateLink = () => {
        const selectedChannelObj = channels.find(channel => channel?.utm_source === selectedChannel);

        const utm_medium = selectedChannelObj?.utm_medium
            ? `&utm_medium=${encodeURIComponent(selectedChannelObj.utm_medium)}`
            : "";
        const utm_campaign = selectedChannelObj?.utm_campaign
            ? `&utm_campaign=${encodeURIComponent(selectedChannelObj.utm_campaign)}`
            : "";

        return `${localStorage.getItem('company_domain')}.taphr.kz/vacancy/${vacancyId}?company_id=${localStorage.getItem("company_id")}&utm_source=${selectedChannelObj ? encodeURIComponent(selectedChannelObj?.utm_source) : "[Выберите канал]"}${utm_medium}${utm_campaign}`;
    };

    return (
        <Box
            sx={{
                backgroundColor: "#F8F9F9",
                padding: "18px",
                borderRadius: "8px",
            }}
        >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Поделиться ссылкой
            </Typography>
            <Typography sx={{ fontSize: "16px", marginBottom: "6px" }}>
                Каналы
            </Typography>
            <Select
                value={selectedChannel}
                onChange={handleChannelChange}
                displayEmpty
                sx={{
                    width: "100%",
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    height: '56px',
                    boxShadow: 'none'
                }}
            >
                <MenuItem value="">Выберите канал</MenuItem>
                {channels?.map((channel, index) => (
                    <MenuItem key={index} value={channel.utm_source}>
                        {channel.name}
                    </MenuItem>
                ))}
            </Select>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                    height: '20px'
                }}
            >
                <Typography
                    sx={{
                        fontSize: "16px",
                        color: "#333",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {generateLink()}
                </Typography>
                <IconButton
                    onClick={handleCopy}
                    sx={{ color: "#9E9E9E", "&:hover": { color: "#599D15" }, marginLeft: 1 }}
                >
                    <ContentCopyIcon sx={{ width: '20px' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

import { useState } from 'react';
import { Input } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { createDepartment } from '../../services/departmentsApi';

const CreateDepartment = ({setInputActive, handleDataChange}) => {
    console.log("CreateDepartment being rendered");
    const [departmentName, setDepartmentName] = useState("");

    const createMutation = useMutation(createDepartment, {
        onSuccess : (data) => {
            handleDataChange(data);
        }
    })

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && departmentName.trim()) {
          createMutation.mutate({ name: departmentName.trim() }); // Call the mutation
          setInputActive(false); // Close the input
          setDepartmentName(""); // Clear the input after submission
        }
    };

    const handleBlur = () => {
        setInputActive(false); // Close the input when blurred
        setDepartmentName(""); // Clear the input
    };

    return (
        <Input
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            onKeyDown={handleKeyDown} // Handle the Enter key
            onBlur={handleBlur} // Handle the Blur event
            placeholder="Название отдела"
            autoFocus = {true}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ml: 3,
                height: "52px",
                "&::after": {
                    borderBottom: "2px solid rgba(89, 157, 21, 1)", // Default green border
                },
            }}
        />
    )
};

export default CreateDepartment;
import React, { memo } from 'react';
import {
    Box,
    Typography,
    Divider,
    Chip,
} from '@mui/material';
import { AiIcon } from '../svgComponents';

const RatingsUi = memo(({ score, description }) => {

    if (score === null) {
        return null;
    }

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                borderRadius: '8px',
                transition: 'all 0.3s',
            }}
        >
            <Box sx={{display: "flex", alignItems: "center", gap: "8px"}}>
                <AiIcon sx={{width: 24, height: 24}} svgSx = {{width : 19, height : 22}}/>
                <Typography variant="h6" 
                    sx={{ 
                        fontWeight: '500', 
                        fontSize: '20px', 
                        lineHeight: '24px',
                        background: "linear-gradient(90deg, #FAA730 0%, #FB585D 25.5%, #FC446F 52%, #E396FE 77.5%, #41B9FC 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        mr: 1,
                    }}>
                    Рейтинг кандидата
                </Typography>
                <Chip
                    label={<Box sx={{ padding: 0 }}>{score}%</Box>}
                    sx={{
                        "& .MuiChip-label": {
                            padding: 0
                        },
                        backgroundColor: score >= 75 ? "#C6E2CE" : (score < 75 && score > 50 ? "#F6E6C8" : "#FFC9C8"),
                        color: score >= 75 ? "#14AE5C" : (score < 75 && score > 50 ? "#E8B931" : "#FF3B30"),
                        fontWeight: 600,
                        height: "24px",
                        width: "38px",
                        fontSize: "12px",
                        lineHeight: "18px",
                        borderRadius: '4px',
                    }}
                />
            </Box>
            <Divider sx={{backgroundColor : "rgba(235, 238, 239, 1)"}}/>
            <Box
                sx={{
                    display: "inline-block",
                    padding: "2px", // Thickness of the border
                    borderRadius: "6px", // Same as the inner element
                    background: "linear-gradient(90deg, #FAA730 0%, #FB585D 25.5%, #FC446F 52%, #E396FE 77.5%, #41B9FC 100%)",
                }}
            >
                <Typography
                    sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(21, 24, 19, 1)",
                    display: "flex",
                    minHeight: 108,
                    padding: "10px 14px",
                    alignItems: "flexstart",
                    backgroundColor: "rgba(248, 249, 249, 1)", // Background for the inner content
                    borderRadius: "6px", // Slightly smaller than the outer border
                    color: description ? "rgba(21, 24, 19, 1)" : "#ADB5BD"
                    }}
                >
                    {description || "Рейтинг для этого кандидата недоступен"}
                </Typography>
            </Box>
        </Box>
    );
})

export default RatingsUi;

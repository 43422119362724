import axiosClassic from "../api/interceptors";

// update profile that gets data formData that has fields name, phone, email all strings and avatar image file or null
export const updateProfile = async (data) => {
    try{
        const response = await axiosClassic.post("/api/profile/edit?_method=PUT", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';

const PopUpWindow = ({ anchorEl, open, onClose = () => {}, onClick = () => {},  middleItems = [], sx = {}, startItems, endItems }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => {
                e.stopPropagation();
                // Remove focus from the presentation element
                document.activeElement.blur();
                onClose(e);
            }}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                },
                ...sx
            }}
            disableAutoFocusItem
        >
            {startItems}
            {middleItems.map((elem) => {
                return (
                    <MenuItem
                        key={elem?.id || "unknown"}
                        onClick={() => onClick(elem?.id)}
                    >
                        {elem?.name || "Неизвестный элемент"}
                    </MenuItem>
                )
            })}
            {endItems}
        </Menu>
    )
}

export default PopUpWindow;
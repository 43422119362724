
import { useState, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateSource, deleteSource } from "../../services/sourcesApi";
import { DELETE_STATUS } from "../../utils/statusesStorage";
import { SourcesRow, EditingRow } from "./SourcesRow";
import { Loading } from "../tapHrStyledComponents";
import { DeleteModalWindow } from "../common/modalWindows/deleteModalWindow";


const SourcesList = ({ sources, perPage, currentPage, handleDataChange }) => {
    const [id, setId] = useState(null);
    const [deleteModalWindow, setDeleteModalWindow] = useState({index: null, id: null});

    const updateMutation = useMutation(
        (data) =>
            updateSource({ page: currentPage, perPage: perPage, body: data }),
        {
            onSuccess: (data) => {
                handleDataChange(data);
                setId(null);
            },
        }
    );

    const { mutate: deleteStageMutate } = useMutation(deleteSource, {
        onSuccess: (data) => {
            handleDataChange(data, DELETE_STATUS);
        },
    });

    const handleEdit = (sourceId, sourceName) => {
        updateMutation.mutate({ id: sourceId, name: sourceName });
    };

    const handleDelete = useCallback((sourceId) => {
        deleteStageMutate({ id: sourceId, page: currentPage, perPage : perPage });
    }, [deleteStageMutate, currentPage, perPage]);

    return (
        <>
            {Array.isArray(sources) && sources.map((source, index) => {
                const isLast= index === sources.length - 1
                if (source.id === id && updateMutation.isLoading) {
                    return <Loading key={index} sx ={{width: "100%", justifyContent: "space-between", height: "72px", color: "rgba(89, 157, 21, 1)", padding: "16px 24px", borderBottom: isLast ? "1px solid transparent" : "1px solid rgba(226, 228, 231, 1)"}}/>
                }

                return (id !== null && source.id === id) ? (
                    <EditingRow
                        key={index}
                        text={source?.name || "Без названия"}
                        id={source?.id}
                        handleEdit={handleEdit}
                        handleDelete={setDeleteModalWindow}
                        setId={setId}
                        index={index}
                    />
                ) : (
                    <SourcesRow
                        key={index}
                        text={source?.name || "Без названия"}
                        isLast={index === sources.length - 1}
                        id={source?.id}
                        isEditable = {!Boolean(source?.isDefault)}
                        handleDelete={setDeleteModalWindow}
                        setId={setId}
                        index={index}
                    />
                )
            })}
            {deleteModalWindow.index !== null && deleteModalWindow.index < sources.length && deleteModalWindow.id !== null && (
                <DeleteModalWindow 
                    text = {sources[deleteModalWindow.index].name} 
                    title = "источника резюме"
                    subtitle="данный источник" 
                    handleDelete = {() => {
                        handleDelete(deleteModalWindow.id)
                        setDeleteModalWindow({index : null, id : null})
                    }} 
                    handleClose={() => setDeleteModalWindow({index : null, id : null})}/>
            )}
        </>
    );
}

export default SourcesList;
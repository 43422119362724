import React, { useState, useMemo } from 'react';
import { Box, Typography, Avatar, Chip, IconButton, Paper, Divider } from "@mui/material";
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Горизонтальное троеточие
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useQueryClient } from '@tanstack/react-query';
import { onDragEnd } from '../../utils/dragAndDrop';
import ShowModalWindow from '../common/modalWindows/candidateInfoModalWindow';
import RefusalModal from './RefusalModal';
import StagePopUpWindow from '../candidateInfo/candidateStagePopUpWindow';
import { CustomToolTip } from '../tapHrStyledComponents';


const OverviewTab = ({ stages, vacancyId, onStageChangeSuccess, refetch }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryClient = useQueryClient();

    const handleDragEnd = ({ columnStartIdx, columnEndIdx, elementStartIdx: candidateIdx }) => {
        queryClient.setQueryData(["KANBAN"], () => {
            return {
                negotiationId: stages[columnStartIdx].candidates[candidateIdx].negotiation_id,
                stageId: stages[columnEndIdx].id,
            }
        });
        onStageChangeSuccess();
    };

    const handleStageChange = (negotiationId, stageId) => {
        queryClient.setQueryData(["KANBAN"], () => {
            return {
                negotiationId: negotiationId,
                stageId: stageId,
            }
        });
        onStageChangeSuccess();
    };

    // Отфильтровать кандидатов на основе значения поиска
    const filteredStages = useMemo(() => {
        if (!searchQuery) return stages;

        const lowerCaseQuery = searchQuery.toLowerCase();

        return stages.map((stage) => ({
            ...stage,
            candidates: stage.candidates.filter((candidate) => {
                const fullName = `${candidate.first_name} ${candidate.last_name}`.toLowerCase();
                return fullName.includes(lowerCaseQuery);
            }),
        }));

    }, [stages, searchQuery]);


    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", flex: 1 }}>
            {/* Поле поиска */}
            <Box
                sx={{
                    width: "464px",
                    my: 3,
                    backgroundColor: "#F8F9F9",
                    borderRadius: "10px",
                    padding: "13px 16px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <SearchIcon style={{ marginRight: "8px" }} />
                <input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    placeholder="Поиск"
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        color: "#A2A1A8",
                        fontSize: "16px",
                    }}
                />
            </Box>

            {/* Канбан-доска с этапами */}
            <DragDropContext onDragEnd={(e) => onDragEnd(e, handleDragEnd, true)}>
                <Box
                    display="flex"
                    gap={2}
                    className = "custom-scrollbar"
                    sx={{
                        flex: 1,
                        paddingBottom: "8px",
                        overflowX: "auto",
                        maxWidth: "100%",
                        height: "100%",
                    }}
                >
                    {filteredStages.map((stage, index) => (
                        <CandidateColumn refetch={refetch} key={stage.id} index={index} stage={stage} stages={stages} onStageChange={handleStageChange} vacancyId={vacancyId} />
                    ))}
                </Box>
            </DragDropContext>
        </Box>
    );
};


const CandidateColumn = ({ stage, index, stages, onStageChange, vacancyId, refetch }) => {
    return (
        <Droppable droppableId={index.toString()}>
            {(provided, snapshot) => (
                <Paper
                    sx={{
                        minWidth: "300px",
                        height: "calc(100svh - 32px)",
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px 16px 0 16px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        backgroundColor: "#F1F2F3",
                        border: snapshot.isDraggingOver ? '1px solid rgba(89, 157, 21, 1)' : 'none',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#151813",
                        }}
                    >
                        {stage.name}{" "}
                        <Typography
                            component="span"
                            sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#fff",
                                padding: "0 8px",
                                backgroundColor: "rgba(89, 157, 21, 1)",
                                borderRadius: "99px",
                            }}
                        >
                            {stage.candidates?.length}
                        </Typography>
                    </Typography>
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        mt={2}
                        sx={{ overflowY: "auto", flex: 1 }}
                    >
                        {stage.candidates?.map((candidate, index) => (
                            <CandidateCard
                                key={candidate.id}
                                index={index}
                                candidate={candidate}
                                currStageId={stage.id}
                                stages={stages}
                                onStageChange={onStageChange}
                                vacancyId={vacancyId}
                                refetch={refetch}
                            />
                        ))}
                        {provided.placeholder}
                    </Box>
                </Paper>
            )}
        </Droppable>
    )
};

const CandidateCard = ({ candidate, index, currStageId, stages, onStageChange, vacancyId, refetch }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isCandidateModalWindow, setIsCandidateModalWindow] = useState(false);
    const [isRefusalWindow, setIsRefusalWindow] = useState(false);

    const queryClient = useQueryClient();

    const handleRefusalModalWindow = (e) => {
        if (e) {
            e.stopPropagation()
        }
        setIsRefusalWindow(prev => !prev)
    }

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = (reason) => {
        refetch()
        console.log('Кандидат успешно отклонен');
    };

    const handleCandidateModalWindow = () => {
        console.log("here")
        if (Boolean(anchorEl)) {
            return;
        }
        if (isCandidateModalWindow) {
            queryClient.removeQueries(["active_candidate"]);
        }
        setIsCandidateModalWindow(prev => !prev);
    }


    return (
        <Draggable draggableId={candidate.id.toString()} index={index} > 
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleCandidateModalWindow}
                    className="candidateCard"
                    sx={{
                        backgroundColor: "#FFFFFF",
                        padding: 2,
                        borderRadius: "12px",
                        border: `4px solid ${candidate.is_seen ? "transparent" : "#C6E2CE" }`,
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                        position: "relative",
                        maxWidth: "300px",
                        mt: 2,
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#E0E0E0",
                            "& .avatarImage": {
                                backgroundColor: "#FFFFFF",
                                color: "#E0E0E0",
                            },
                            "& .trippleButton": {
                                backgroundColor: "#F8F9FA",
                            }
                        },
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {/* Аватар */}
                        <Avatar
                            src={candidate.avatar_small || null}
                            alt={`${candidate.first_name} ${candidate.last_name}`}
                            className="avatarImage"
                            sx={{
                                width: 45,
                                height: 45,
                                backgroundColor: "#E0E0E0",
                                borderRadius: '4px'
                            }}
                        />
                        {/* Рейтинг */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <CustomToolTip title={candidate?.relevance_description} hideIfError={true} >
                                {candidate.relevance_rate && (
                                        <Chip
                                            label={<Box sx={{ padding: 0 }}>{candidate.relevance_rate}%</Box>}
                                            sx={{
                                                "& .MuiChip-label": {
                                                    padding: 0
                                                },
                                                backgroundColor: candidate.relevance_rate >= 75 ? "#C6E2CE" : (candidate.relevance_rate < 75 && candidate.relevance_rate > 50 ? "#F6E6C8" : "#FFC9C8"),
                                                color: candidate.relevance_rate >= 75 ? "#14AE5C" : (candidate.relevance_rate < 75 && candidate.relevance_rate > 50 ? "#E8B931" : "#FF3B30"),
                                                fontWeight: 600,
                                                height: "24px",
                                                width: "38px",
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                borderRadius: '4px',
                                                cursor: candidate?.relevance_description ? 'pointer' : 'grab',
                                            }}
                                        />
                                    )}
                            </CustomToolTip>
                            {/* Горизонтальное троеточие */}
                            <IconButton
                                onClick={handleMenuOpen}
                                className="trippleButton"
                                sx={{
                                    padding: "8px",
                                    backgroundColor: "transparent",
                                    borderRadius: "50%",
                                    "&:hover": { backgroundColor: "white !important" },
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {/* Имя и фамилия */}
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#16151C",
                            marginTop: 1,
                        }}
                    >
                        {candidate.first_name} {candidate.last_name}
                    </Typography>

                    {/* Разделитель */}
                    <Divider sx={{ marginY: 1 }} />

                    {/* Контактная информация */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 0.5 }}>
                        <EmailIcon sx={{ fontSize: 16, color: "#6C757D" }} />
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#6C757D",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {candidate.email || "Не указан"}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <PhoneIcon sx={{ fontSize: 16, color: "#6C757D" }} />
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#6C757D",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {candidate.phone || "Не указан"}
                        </Typography>
                    </Box>

                    {/* Меню */}
                    <StagePopUpWindow 
                        anchorEl={anchorEl} 
                        onClose={handleMenuClose} 
                        handleRefusal={handleRefusalModalWindow} 
                        onStageChange={onStageChange} 
                        negotiationId={candidate.negotiation_id} 
                        currStageId={currStageId} 
                        stages = {stages}
                    />

                    {isRefusalWindow && <RefusalModal
                        open={true}
                        onClose={handleRefusalModalWindow}
                        reasons={[
                            {
                                id: 'discard_by_employer',
                                name: 'Не подходит'
                            },
                            {
                                id: 'discard_by_applicant',
                                name: 'Кандидат отказался'
                            },
                            {
                                id: 'discard_no_interaction',
                                name: 'Не выходит на связь',
                            },
                            {
                                id: 'discard_vacancy_closed',
                                name: 'Вакансия закрыта'
                            },
                            {
                                id: 'discard_to_other_vacancy',
                                name: 'Перевод на другую вакансию'
                            },
                        ]}
                        companyId={localStorage.getItem('company_id')}
                        candidateId={candidate.negotiation_id}
                        onSuccess={handleSubmit}
                    /> }

                    {/* Модальное окно */}
                    {!isRefusalWindow && isCandidateModalWindow && <ShowModalWindow handleModalWindow={handleCandidateModalWindow} candidateId={candidate.id} vacancyId={vacancyId} handleRefusal ={handleRefusalModalWindow} onStageChange = {onStageChange} currStageId = {currStageId} stages = {stages} negotiationId = {candidate.negotiation_id} />}
                </Box>
            )}
        </Draggable>
    );
};


export default OverviewTab;

import React, { useState } from "react";
import { Box, Grid, Typography, TextField, IconButton, Button } from "@mui/material";
import { TrashIcon } from "../svgComponents";

const LinksForm = ({ links, setLinks }) => {
    const handleLinkChange = (index, value) => {
        setLinks((prev) => {
            const updatedLinks = [...prev];
            updatedLinks[index] = value;
            return updatedLinks;
        });
    };

    const addLink = (event) => {
        event.preventDefault();
        setLinks((prev) => [...prev, ""]);
    };

    const removeLink = (index) => {
        setLinks((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <Box sx={{ pt: "17px", borderTop: "1px solid rgba(235, 238, 239, 1)", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px" }}>
                Ссылки
            </Typography>
            {links.map((link, index) => (
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }} key={index}>
                    <Box sx={{flex: 1}}>
                        <TextField
                            fullWidth
                            placeholder="Введите ссылку"
                            value={link}
                            onChange={(e) => handleLinkChange(index, e.target.value)}
                            variant="outlined"
                            sx={{
                                backgroundColor: "#F8F9F9",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "transparent",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#BDBDBD",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#599D15",
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Button
                        color="error"
                        onClick={() => removeLink(index)}
                        sx={{
                            padding: 0,
                            minWidth: "unset",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active" : {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            }
                        }}
                        disabled={links.length === 1}
                    >
                        <TrashIcon 
                            sx = {{width : 56, height: 56}}
                            svgSx = {{
                                width: 20,
                                height: 20,
                                color: links.length === 1 ? "rgba(173, 181, 189, 1)" : "rgba(46, 64, 82, 1)",
                            }}
                        />
                    </Button>
                </Box>
            ))}
            <Box>
                <Box
                    component = "button"
                    variant="contained"
                    color="success"
                    onClick={addLink}
                    sx={{
                        textTransform: "none",
                        fontWeight: 500,
                        padding: "16px 24px",
                        border: "1px solid rgba(89, 157, 21, 1)",
                        color: "rgba(89, 157, 21, 1)",
                        fontSize: "18px",
                        lineHeight: "22px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(244, 247, 242, 1)",
                            borderColor: "rgba(80, 140, 19, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(205, 221, 198, 1)",
                            borderColor: "rgba(69, 122, 16, 1)",
                        }
                    }}
                >
                    Добавить ссылку
                </Box>
            </Box>
        </Box>
    );
};

export default LinksForm;

import React, {useState, memo, useEffect} from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ArrowUpIcon, CloseIcon } from "../svgComponents";
import { useInfiniteQuery } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";


/**
 * CustomAutoComplete component
 * @param {Object} props.value - value of the autocomplete
 * @param {Function} props.setValue - function to set the value of the autocomplete
 * @param {String} props.queryKeyStartingValue - query key starting value
 * @param {Function} props.fetchFunction - function to fetch the data
 * @param {Number} props.maxHeight - max height of the list
 * @param {Object} props.additional - additional data to pass to the fetch function
 * @returns {JSX.Element}
*/
const CustomAutoComplete = memo(({value = null, setValue = () => {}, queryKeyStartingValue = [""], fetchFunction = () => {}, maxHeight = 520, additional }) => {
    const {debouncedValue : debouncedSearchQuery, value: searchQuery, setValue : setSearchQuery, isDebouncing} = useDebounce(value?.name || "", 300, queryKeyStartingValue);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setSearchQuery(value?.name || "");
    }, [value]);

    // fetch data
    const {
        data,
        isFetching,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery([queryKeyStartingValue, debouncedSearchQuery], ({ pageParam = 1 }) => fetchFunction(debouncedSearchQuery, pageParam, 10, additional), {
        getNextPageParam: (lastPage) => {
            const target = lastPage?.meta || lastPage;
            if (target) {
                const currentPage = parseInt(target.current_page, 10);
                const totalPages = Math.ceil(target.total / target.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            }
            return undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const flatData = (data && data.pages && data.pages.flatMap((page) => page?.data || page)) || [];

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    // logic is that we need to reload the list, only if we are not fetching the next page, all other cases such as searching and debouncing should trigger the loading
    const isOptionsLoading = (isFetching && !isFetchingNextPage) || isDebouncing;

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            noOptionsText={"Нет вариантов"}
            disableClearable={true}
            value={value}
            inputValue={searchQuery}
            onChange={(e, newValue) => setValue(newValue)}
            onInputChange={(e, newInputValue) => setSearchQuery(newInputValue)}
            options={isOptionsLoading ? [] : flatData}
            getOptionLabel={(option) => option?.name || "Без названия"}
            filterOptions={(options) => options}
            loading={isOptionsLoading || isFetchingNextPage}
            sx = {{
                position: "relative",
                overflow: "hidden",
            }}
            popupIcon={
                <ArrowUpIcon
                    sx={{ width: 24, height: 24, transform: "rotate(180deg)" }}
                    svgSx={{
                        width: 10,
                        height: 4,
                        color: searchQuery
                            ? "rgba(21, 24, 19, 1)"
                            : "rgba(173, 181, 189, 1)",
                    }}
                />
            }
            renderOption={(props, option) => {
                const { key, ...rest } = props; // Extract `key` from `props`
                return (
                    <li
                        key={option?.id || key}
                        {...rest}
                        style={{
                            ...props.style,
                            height: "52px",
                            maxHeight: "52px",
                            padding: "16px",
                        }}
                    >
                        {option?.name || "Без названия"}{" "}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={"Выберите из списка"}
                    variant="outlined"
                    sx={{
                        height: "56px",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        color: "#151813",
                        border: "1px solid #E2E4E7",
                        boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",

                        "&:hover": {
                            borderColor: "#599D15", // Hover border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "&.MuiFormControl-root:focus-within": {
                            border: "2px solid rgba(205, 221, 198, 1)",
                        },
                    }}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {searchQuery && (
                                        <CloseIcon
                                            onClick={() => {
                                                setSearchQuery("");
                                                setValue(null);
                                                setOpen(true);
                                            }}
                                            sx={{
                                                cursor: "pointer",
                                                width: 24,
                                                height: 24,
                                            }}
                                            svgSx={{
                                                width: 10,
                                                height: 10,
                                                color: "rgba(0,0,0,0.5)",
                                                hoverColor: "rgba(0,0,0,0.75)",
                                                pressedColor: "rgba(0,0,0,1)",
                                            }}
                                        />
                                    )}
                                    {params.InputProps?.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            slotProps={{
                listbox: {
                    style: { maxHeight: maxHeight, overflow: "auto" },
                    onScroll: handleScroll,
                }
            }}
        />
    );
})

export default CustomAutoComplete;
import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox } from "../tapHrStyledComponents";
import { LockIcon, MenuIcon } from "../svgComponents";
import CustomInput from "../styledComponents/CustomInput";

const ThirdStep = ({
    handleBack,
    handleCreate,
    name,
    salaryFrom,
    salaryTo,
    currency,
    experience,
    employmentType,
    workSchedule,
    taxType,
    text,
    city,
    department,
    category,
    accept_temporary,
    stages,
}) => {

    const [vacancyExpanded, setVacancyExpanded] = useState(true);
    const [stageExpanded, setStageExpanded] = useState(true);

    const handleVacancyAccordionChange = (event) => {
        setVacancyExpanded(prev => !prev);
    };

    const handleStageAccordionChange = (event) => {
        setStageExpanded(prev => !prev);
    };

    const returnExperienceText = (key) => {
        switch (key) {
            case 'noExperience':
                return 'Нет опыта'
            case 'between1And3':
                return 'От 1 года до 3 лет'
            case 'between3And6':
                return 'От 3 до 6 лет'
            case 'moreThan6':
                return 'Более 6 лет'
            default:
                return 'Нет опыта'
        }
    }

    const returnEmploymentTypeText = (key) => {
        switch (key) {
            case 'full':
                return 'Полная занятость'
            case 'part':
                return 'Частичная занятость'
            case 'project':
                return 'Проектная работа'
            case 'volunteer':
                return 'Волонтерство'
            case 'probation':
                return 'Стажировка'
            default:
                return 'Полная занятость'
        }
    }

    const returnScheduleText = (key) => {
        switch (key) {
            case 'fullDay':
                return 'Полный день'
            case 'shift':
                return 'Сменный график'
            case 'flexible':
                return 'Гибкий график'
            case 'remote':
                return 'Удаленная работа'
            case 'flyInFlyOut':
                return 'Вахтовый метод'
            default:
                return 'Полный день'
        }
    }

    return (
        <>
            {/* Заголовок Шага 3 */}
            {/* Accordion для "Посмотреть вакансию" */}
            <Accordion
                expanded={vacancyExpanded}
                onChange={handleVacancyAccordionChange}
                sx={{ 
                    boxShadow: "none",
                    borderTopLeftRadius: "10px !important",
                    borderTopRightRadius: "10px !important",
                    backgroundColor: vacancyExpanded ? "#fff" : "transparent",
                }}
            >
                <AccordionSummary
                
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: "#E8EFE4",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomLeftRadius: vacancyExpanded
                            ? "0px"
                            : "10px", // Border radius when closed
                        borderBottomRightRadius: vacancyExpanded
                            ? "0px"
                            : "10px", // Border radius when closed
                        minHeight: "56px", // Фиксируем минимальную высоту
                        height: "56px", // Фиксируем общую высоту
                        boxShadow: "none", // Убираем тень
                        "&.Mui-expanded": {
                            minHeight: "56px", // Фиксируем высоту при открытии
                            height: "56px",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 600,
                            color: "#599D15",
                            fontSize: "18px",
                        }}
                    >
                        Посмотреть вакансию
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "24px" }}>
                    <Box
                        sx={{
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: "356px 356px",
                            mb: 3,
                        }}
                    >
                        <CustomInput readOnly = {true} label="Название" value={name} />
                        <CustomInput readOnly = {true} label="Город" value={city ? city.name : null} />
                        <CustomInput readOnly = {true} label="Отдел" value={department ? department.name : null} />
                        <CustomInput readOnly = {true} label="Категория" value={category ? category.name : null} />
                        <CustomInput readOnly = {true} label="Заработная плата от" value={salaryFrom} />
                        <CustomInput readOnly = {true} label="Заработная плата до" value={salaryTo} />
                        <CustomInput readOnly = {true} label="Налогообложение" value={taxType ? "До вычета налогов" : "На руки"} />
                        <CustomInput readOnly = {true} label="Валюта" value={currency} />
                        <CustomInput readOnly = {true} label="Опыт работы" value={returnExperienceText(experience)} />
                        <CustomInput readOnly = {true} label="Тип занятости" value={returnEmploymentTypeText(employmentType)} />
                        <CustomInput readOnly = {true} label="График работы" value={returnScheduleText(workSchedule)} />
                        <Box sx={{ display: "flex", alignItems: "flex-end", py: 2 }}>
                            <CheckBox checked={accept_temporary} noHover={true} />
                            <Typography
                                variant="body1"
                                sx={{
                                    ml: 1,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#151813",
                                }}
                            >
                                Возможно временное оформление
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontWeight: 500 }}>Описание</Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            mt: 1,
                            padding: "8px 14px",
                            borderRadius: "4px",
                            border: "1px solid #D2D6DA",
                            backgroundColor: "#FFFFFF",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                "& ul": {
                                    listStyleType: "disc",
                                    marginLeft: "20px",
                                    width: "calc(100% - 20px)",
                                },
                                "& li": {
                                    width: "100%",
                                    whiteSpace:
                                        "normal" /* Allow text wrapping */,
                                    wordWrap:
                                        "break-word" /* Break long words onto a new line */,
                                    wordBreak:
                                        "break-word" /* Ensure words wrap even if very long */,
                                },
                            }}
                        >
                            {/* HTML-содержимое description */}
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#151813",
                                    padding: 0,
                                }}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </Box>
                    </Paper>
                </AccordionDetails>
            </Accordion>

            {/* Accordion для "Посмотреть этапы" */}
            <Accordion
                expanded={stageExpanded}
                onChange={handleStageAccordionChange}
                sx={{ 
                    mt: 3, 
                    boxShadow: "none", 
                    borderTopLeftRadius: "10px !important",
                    borderTopRightRadius: "10px !important",
                    backgroundColor: stageExpanded ? "#fff" : "transparent", 
                    "&::before": {
                        display: "none", // Prevent any unwanted line or pseudo-element
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: "#E8EFE4",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomLeftRadius: stageExpanded ? "0px" : "10px", // Border radius when closed
                        borderBottomRightRadius: stageExpanded ? "0px" : "10px", // Border radius when closed
                        minHeight: "56px", // Фиксируем минимальную высоту
                        height: "56px", // Фиксируем общую высоту
                        boxShadow: "none", // Убираем тень
                        "&.Mui-expanded": {
                            minHeight: "56px", // Фиксируем высоту при открытии
                            height: "56px",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 600,
                            color: "#599D15",
                            fontSize: "18px",
                        }}
                    >
                        Посмотреть этапы
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx = {{
                        padding: "0px",
                    }}
                >
                    <Box
                        key={'first'}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #E0E0E0',
                            padding: "16px 24px"
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                            <LockIcon sx={{width: "40px", height: "40px"}} svgSx={{width: "24px", height: "20px", color : "rgba(21, 24, 19, 1)"}}/>
                            <Typography>Новый</Typography>
                        </Box>
                    </Box>
                    {stages &&
                        stages.map((stage, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #E0E0E0',
                                    padding: "16px 24px"
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                    <MenuIcon sx = {{width: "40px", height : "40px"}} svgSx={{width: "16px", height: "14px", color: "rgba(21, 24, 19, 1)"}}/>
                                    <Typography>{stage.name}</Typography>
                                </Box>
                            </Box>
                        ))}
                    <Box
                        key={'last'}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #E0E0E0',
                            padding: "16px 24px"
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                            <LockIcon sx={{width: "40px", height: "40px"}} svgSx={{width: "24px", height: "20px", color : "rgba(21, 24, 19, 1)"}}/>
                            <Typography>Принял оффер</Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>

            {/* Кнопки "Назад" и "Создать" */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                    gap: 2,
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        marginRight: 2,
                        height: "56px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        width: "202px",
                        color: "#599D15",
                        borderColor: "#599D15",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}
                    onClick={handleBack}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#599D15",
                        color: "white",
                        height: "56px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        width: "202px",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}
                    onClick={handleCreate} // Здесь обработчик для создания вакансии
                >
                    Создать
                </Button>
            </Box>
        </>
    );
}


export default ThirdStep;
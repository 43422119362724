import { memo, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UploadIcon } from "../../svgComponents";
import CareerSiteUploadImgModal from "../../common/modalWindows/CareerSiteUploadImgModal";

const ImageUploader = memo(({backImg, setData}) => {
    console.log("File Previewer rendering");
    const [openUploadModalWindow, setOpenUploadModalWindow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState({loaded: false, url: backImg || null});

    useEffect(() => {
        if (!(backImg instanceof File)) {
            setPreviewUrl({ loaded: false, url: backImg });
        }
    }, [backImg]);


    // Allowed image MIME types
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/svg+xml"];
    const maxFileSize = 2097152; // 2 MB in bytes

    const handleModalWindow = () => {
        setOpenUploadModalWindow(prev => !prev);
    }

    const handleCroppedFile = (croppedFile) => {
        setData(prev => {
            return {
                ...prev,
                background_image: croppedFile,
            };
        });
        setPreviewUrl({loaded: true, url: URL.createObjectURL(croppedFile)}); // Update the preview with the cropped image
        setUploadedFile(null);
        handleModalWindow(); // Close the modal
    };

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            // Check if the uploadedFile type is in the allowed image types
            if (allowedImageTypes.includes(uploadedFile.type) && uploadedFile.size <= maxFileSize) {
                setUploadedFile(uploadedFile);
                // setPreviewUrl(URL.createObjectURL(uploadedFile));
                setErrorMessage(""); // Clear any previous error
                handleModalWindow();
            } else if (uploadedFile.size > maxFileSize) {
                setErrorMessage("Размер файла не должен превышать 1 MB!");
                setUploadedFile(null);
                setPreviewUrl({loaded: false, url: null});
            }
            else {
                setErrorMessage("Можно загружать только изображения (JPEG, PNG, GIF, WebP, SVG)!");
                setUploadedFile(null);
                setPreviewUrl({loaded: false, url: null});
            }
        }
        event.target.value = null; // Reset the input field
    };

    return (
        <>
            <Box
                sx={{
                    gridColumn: "2 / 3", // Span the second column
                    gridRow: "1 / 3", // Span from the first to the second row
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        textAlign: "center",
                    }}
                >
                    Фон
                </Typography>
                <Box
                    sx={{
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='rgba(31, 107, 197, 1)' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "72%",
                        minWidth:"450px",
                        aspectRatio: "13 / 4",
                        padding: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(248, 249, 249, 1)",
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            aspectRatio: "13 / 4",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            backgroundImage: previewUrl.url
                                ? `url(${previewUrl.url})`
                                : "none",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        // onClick={handleModalWindow}
                    >
                        {!previewUrl.url && (
                            <>
                                <UploadIcon
                                    sx={{ width: 36, height: 36, mb: "12px" }}
                                    svgSx={{
                                        width: 36,
                                        height: 24,
                                        color: "rgba(31, 107, 197, 1)",
                                    }}
                                />
                                <Typography
                                    sx={{ fontSize: "16px", lineHeight: "20px" }}
                                >
                                    Перетащите свой файл или{" "}
                                    <span style={{ color: "rgba(31, 107, 197, 1)" }}>
                                        откройте
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        mt: 1,
                                        color: "rgba(116, 116, 115, 1)",
                                    }}
                                >
                                    Оптимальный размер 1920x1080px
                                </Typography>
                            </>
                        )}
                        
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                                cursor: "pointer",
                            }}
                        />
                    </Box>
                </Box>
                {errorMessage && (
                    <Typography sx={{ fontSize: "16px", color: "red" }}>
                        {errorMessage}
                    </Typography>
                )}
                {(previewUrl.loaded && previewUrl.url && !errorMessage) && (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            color: "rgba(109, 109, 109, 1)",
                        }}
                    >
                        Фон успешно загружен
                    </Typography>
                )}
            </Box>
            {openUploadModalWindow && (
                <CareerSiteUploadImgModal
                    handleClose={handleModalWindow}
                    uploadedFile={uploadedFile}
                    handleCroppedFile={handleCroppedFile}
                />
            )}
        </>
    );
})

export default ImageUploader;

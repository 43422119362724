import { TrashIcon, PencilIcon, CheckIcon, NoteIcon } from "../svgComponents";
import { Box, Input } from "@mui/material";
import { useState, memo } from "react";

const TrashIconUi = ({handleModalWindow, index, id, isEditable}) => {
    return (
        <TrashIcon
            onClick={() => {
                if (isEditable) {
                    handleModalWindow({index, id});
                }
            }}
            sx={{
                width: 46,
                height: 46,
                cursor: "pointer",
                borderRadius: "8px",
                "&:hover": {
                    backgroundColor: "rgba(242, 243, 243, 1)",
                },
            }}
            svgSx={{
                width: 20,
                height: 20,
                color: "rgba(255, 59, 48, 1)"
            }}
        />
    )
}

export const EditingRow = ({text, handleEdit, handleDelete, setId, id, index}) => {
    const [name, setName] = useState(text);

    const handleSave = () => {
        if (name.trim().length !== 0 && name.trim() !== text){
            handleEdit(id, name.trim());
        } else {
            setId(null);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && name.trim()) {
            handleSave();
        }
    }

    const handleBlur = () => {
        console.log("unbluring")
        setId(null);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "space-between", padding: "12.5px 16px", borderBottom: "1px solid rgba(226, 228, 231, 1)"}}>
            <Box sx={{display: "flex", gap: "12px", padding: "3px 8px", alignItems: "center", width: "71%"}}>
                <NoteIcon 
                    sx={{width: 40, height: 40, backgroundColor: "rgba(244, 247, 242, 1)", borderRadius: "50%"}}
                    svgSx = {{width: 13, height: 15, color: "rgba(41, 57, 73, 1)"}}
                />
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    autoFocus
                    sx={{ 
                        height: "46px", 
                        padding: 0,
                        "&::after": {
                            borderBottom: "2px solid rgba(89, 157, 21, 1)",
                        },
                        width: "100%",
                    }}
                />
            </Box>
            <Box sx={{display: "flex", gap: "4px"}}>
                <TrashIconUi handleModalWindow={handleDelete} index={index} id={id} isEditable={true}/>
                <CheckIcon 
                    onMouseDown={handleSave}
                    dontPreventDefault = {false}
                    sx={{
                        cursor: "pointer",
                        width: 46,
                        height: 46,
                        "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                        borderRadius: "8px",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(21, 24, 19, 1)",
                        hoverColor: "rgba(41, 57, 73, 1)",
                        pressedColor: "rgba(36, 50, 64, 1)",
                    }}
                />
            </Box>
        </Box>
    )
};

export const SourcesRow = memo(({text, id, isLast, isEditable, handleDelete, setId, index}) => {
    const onEdit = () => {
        if (isEditable) { 
            setId(id);
        }
    }
    return (
        <Box sx={{display: "flex", justifyContent: "space-between", padding: "12.5px 16px", borderBottom: isLast ? "1px solid transparent" : "1px solid rgba(226, 228, 231, 1)"}}>
            <Box sx={{fontSize: "16px", fontWeight: 500, lineHeight: "20px", display: "flex", gap: "12px", padding: "3px 8px", alignItems: "center"}}>
                <NoteIcon 
                    sx={{width: 40, height: 40, backgroundColor: "rgba(244, 247, 242, 1)", borderRadius: "50%"}}
                    svgSx = {{width: 13, height: 15, color: "rgba(41, 57, 73, 1)"}}
                />
                {text}
            </Box>
            <Box sx={{display: "flex", gap: "4px"}}>
                <TrashIconUi handleModalWindow={handleDelete} index={index} id={id} isEditable={isEditable}/>
                <PencilIcon
                    onClick={onEdit}
                    sx={{
                        width: 46,
                        height: 46,
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: isEditable ? "rgba(242, 243, 243, 1)" : "transparent",
                        },
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: isEditable
                            ? "rgba(46, 64, 82, 1)"
                            : "rgba(200, 202, 204, 1)",
                    }}
                />
            </Box>
        </Box>
    )
});
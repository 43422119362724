import React, { useState, useCallback } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { updateStage, deleteStage } from '../../services/stagesApi';
import { useMutation} from '@tanstack/react-query'
import { EditingRow, StagesRow } from './StagesRow';
import { Loading } from '../tapHrStyledComponents';
import { DELETE_STATUS } from '../../utils/statusesStorage';

const StagesPanel = ({stages, handleDataChange, currentPage, perPage}) => {
    const [id, setId] = useState(null);

    const updateMutation = useMutation(
        (data) =>
            updateStage({ page: currentPage, perPage: perPage, body: data }),
        {
            onSuccess: (data) => {
                handleDataChange(data);
                setId(null);
            },
        }
    );

    const { mutate: deleteStageMutate } = useMutation(deleteStage, {
        onSuccess: (data) => {
            handleDataChange(data, DELETE_STATUS);
        },
    });

    const handleEdit = (stageId, stageName) => {
        updateMutation.mutate({ id: stageId, name: stageName });
    };

    const handleDelete = useCallback((stageId) => {
        deleteStageMutate({ id: stageId, page: currentPage, perPage : perPage });
    }, [deleteStageMutate, currentPage, perPage]);

    return (
        <Box>
        <TableContainer>
            <Table>
            <TableHead sx={{backgroundColor: "rgba(248, 249, 249, 1)"}}>
                <TableRow>
                <TableCell sx={{width: "30svw", minWidth:"650px", padding: "12px 24px", fontSize: 14, lineHeight: "18px"}}>Название этапа</TableCell>
                <TableCell align="center" sx={{width: "10svw", minWidth: "150px", padding: "12px 24px", fontSize: 14, lineHeight: "18px"}}>Назначение</TableCell>
                <TableCell align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {stages.map((stage, index) => {
                    if (stage.id === id && updateMutation.isLoading) {
                        return (<TableRow key={`loading-${stage.id}`}>
                            <TableCell sx={{width: "30svw", minWidth:"650px", padding: "16px 24px", fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>
                                <Loading key={index} sx ={{width: "100%", justifyContent: "space-between", height: "40px", color: "rgba(89, 157, 21, 1)"}}/>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>)
                    }

                    return id !== null && stage.id === id ? (
                        <EditingRow
                            key={index}
                            text={stage?.name || "Без названия"}
                            id={stage?.id}
                            purpose={stage?.purpose || 1}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            setId={setId}
                        />
                    ) : (
                        <StagesRow
                            key={index}
                            text={stage?.name || "Без названия"}
                            id={stage?.id}
                            purpose={stage?.purpose || 1}
                            isEditable = {!Boolean(stage?.is_default)}
                            handleDelete={handleDelete}
                            setId={setId}
                        />
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>
        </Box>
    );
};

export default StagesPanel;

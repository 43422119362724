import { RadioIcon } from "../svgComponents";

const CustomRadio = ({checked, onClick}) => {
    return (
        <RadioIcon
            checked={checked}
            onClick={onClick}
            sx={{
                width: 20,
                height: 20,
                border: "1px solid",
                borderColor: checked
                    ? "rgba(89, 157, 21, 1)"
                    : "rgba(210, 214, 218, 1)",
                backgroundColor: checked
                    ? "rgba(244, 247, 242, 1)"
                    : "#fff",
                borderRadius: "50%",
            }}
            svgSx={{
                width: 10,
                height: 10,
                color: "rgba(89, 157, 21, 1)",
            }}
        />
    );
}

export default CustomRadio;
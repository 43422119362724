
import { Box, Button, Dialog, DialogActions, Divider, Typography } from "@mui/material";
import { AddDocumentIcon, CloseIcon } from "../../svgComponents";
import CustomInput from "../../styledComponents/CustomInput";
import { useState } from "react";
import CustomRadioList from "../../styledComponents/CustomRadioList";
import 'react-quill/dist/quill.snow.css';
import TemplateEditor from "../../templates/TemplateEditor";

/* possible predefined variables 
    {id: "Компания.ссылка", name: "Ссылка на компанию"},
*/

const predefinedVariables = [
    {id: "Кандидат.имя", name: "Имя кандидата"},
    {id: "Кандидат.фамилия", name: "Фамилия кандидата"},
    {id: "Вакансия.название", name: "Название вакансии"},
    {id: "Вакансия.ссылка", name: "Ссылка на вакансию"},
    {id: "Компания.имя", name: "Название компании"},
    {id: "Рекрутер.имя", name: "Имя рекрутера"},
    {id: "Рекрутер.почта", name: "Почта рекрутера"},
    {id: "Рекрутер.телефон", name: "Телефон рекрутера"},
]; // Add valid variables here

export const CreateMessengerTemplate = ({handleClose, handleNewItem, data}) => {
    const [body, setBody] = useState(data || {name: "", content: "", type: 1});

    const handleSave = () => {
        if (!body.name || !body.content) {
            return;
        }

        if (data && body.name === data.name && body.content === data.content && body.type === data.type) {
            return;
        }

        handleNewItem(body);
    };

    const handleChange = (fieldName, value) => {
        setBody({ ...body, [fieldName]: value });
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "739px",
                    height: "508px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    overflow: "hidden",
                    margin: 0,
                },
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            width: 48,
                            height: 48,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <AddDocumentIcon
                            sx={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                            }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                    }}
                >
                    Новый шаблон
                </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "24px" }}>
                <Box
                    sx={{
                        width: "437px",
                        display: "flex",
                        flexDirection: "column",
                        mt: 2,
                        gap: "24px",
                    }}
                >
                    <CustomInput
                        sx={{
                            inputPadding: "18px 16px",
                            inputBorder: "1px solid rgba(226, 228, 231, 1)",
                        }}
                        label="Название шаблона"
                        placeholder="Введите название"
                        value={body.name}
                        onChange={(value) => {
                            handleChange("name", value);
                        }}
                        required={true}
                    />
                    <TemplateEditor
                        label="Содержание"
                        value={body.content}
                        predefinedVariables={predefinedVariables}
                        onChange={(value) => {
                            handleChange("content", value);
                        }}
                        placeholder="Введите содержание"
                        hideToolbar={true}
                        formats={["color"]}
                        slotProps={{
                            fullEditor: {
                                height: "128px",
                            },
                            container: {
                                mt: "6px",
                                borderColor: "rgba(226, 228, 231, 1)",
                            },
                        }}
                    />
                </Box>
                <Divider
                    sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}
                    orientation="vertical"
                    flexItem
                />
                <Box>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            mt: 2,
                        }}
                    >
                        Видимость шаблона
                    </Typography>
                    <CustomRadioList
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            alignItems: "flex-start",
                            mt: 2,
                        }}
                        defaultValue={body.type}
                        onClick={(value) => {
                            handleChange("type", value);
                        }}
                        inputs={[
                            { value: 1, text: "Личный" },
                            { value: 2, text: "Общий" },
                        ]}
                    />
                </Box>
            </Box>
            <DialogActions
                sx={{ justifyContent: "flex-start", padding: 0, mt: "10px" }}
            >
                <Box sx={{ width: 437, display: "flex", gap: "16px" }}>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        sx={{
                            flex: 1,
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            color: "#fff",
                            height: "46px",
                            borderRadius: "8px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                                boxShadow: "none !important",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                        }}
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            flex: 1,
                            color: "rgba(46, 64, 82, 1)",
                            borderColor: "rgba(46, 64, 82, 1)",
                            height: "46px",
                            borderRadius: "8px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                        }}
                    >
                        Отменить
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default CreateMessengerTemplate;
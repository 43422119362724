
import { useMutation } from "@tanstack/react-query";
import { createSource } from "../../services/sourcesApi";
import { ADD_STATUS } from "../../utils/statusesStorage";
import { Input, Box } from "@mui/material";
import { PlusIcon } from "../svgComponents";
import { useState } from "react";

const CreateSource = ({handleDataChange, perPage, currentPage}) => {
    const [text, setText] = useState("");

    const createSourceMutation = useMutation(
        (data) =>
            createSource({
                page: currentPage,
                perPage: perPage,
                body: data,
            }),
        {
            onSuccess: (data) => {
                handleDataChange(data, ADD_STATUS);
            },
        }
    );

    const handleAddText = () => {
        console.log(text);
        if (text.trim()){
            createSourceMutation.mutate({ name: text.trim() }); // Call the mutation
            setText(""); // Clear the input after submission
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" ) {
            handleAddText(); // Call the mutation
        }
    };

    const handleBlur = () => {
        setText(""); // Clear the input
    };


    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "13px 16px", borderBottom: "1px solid rgba(226, 228, 231, 1)" }}>
            <Input
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown} // Handle the Enter key
                onBlur={handleBlur} // Handle the Blur event
                placeholder="Введите название источника"
                autoFocus = {true}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "13px 8px",
                    height: "46px",
                    width: "70%",
                    "&::after": {
                        borderBottom: "2px solid rgba(89, 157, 21, 1)", // Default green border
                    },
                }}
            />
            <Box
                component={"button"}
                onMouseDown={handleAddText}
                sx={{
                    borderRadius: "8px",
                    padding: "12px 20px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    border: "1px solid rgba(89, 157, 21, 1)",
                    "&:hover": {
                        backgroundColor: "rgba(244, 247, 242, 1)",
                    },
                    "&:active": {
                        backgroundColor: "rgba(205, 221, 198, 1)",
                    },
                    color: "rgba(89, 157, 21, 1)",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: 400,
                }}
            >
                <PlusIcon 
                    sx={{width: 20, height: 20}}
                    svgSx = {{width: 10, height: 10, color: "rgba(89, 157, 21, 1)"}}
                />
                Добавить
            </Box>
        </Box>
    )
};

export default CreateSource;
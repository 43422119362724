import axiosClassic from "../api/interceptors";

/**
 * @param {String} searchQuery - search query
 * @param {Number} page - page number
 * @param {Number} perPage - number of items per page
 * @param {Number} purpose - 1 for stages, 2 for refuses
 * @returns {Object} - object that contains data and meta
 */

export const fetchSources = async (searchQuery = "", page = 1, perPage = 10,  purpose = null) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    
    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/settings/source`, {
            params: { 
                page, 
                ...(purpose ? { purpose } : {}), // check if purpose is active
                per_page: perPage,
                ...(searchQuery ? { name: searchQuery } : {}), // check if search is active
            },
        });

        // Check if the response has data
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error fetching stages:", error.message);
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * 
 * @param {Object} data - data containing two fields name String, and purpose Number, 1 for stages, 2 for refuses
 * @returns {Object} - object that contains data and meta
 */
export const createSource = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/settings/source`, 
            { 
                ...data.body,
            },
            {
                params: { 
                    page: data.page || 1,
                    per_page: data.perPage || 13,
                }, 
            }
        );
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error creating source:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Object} data - data containing two fields first is id Number and second is name String
 * @returns {Object} - object that contains data and meta
 */
export const updateSource = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/settings/source/${data?.body?.id}`,
            { 
                ...data.body,
            },
            {
                params: { 
                    page: data.page || 1,
                    per_page: data.perPage || 13,
                }, 
            }
        );
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error updating source:", error.message);
        throw error;
    }
}

/**
 * 
 * @param {Number} data - object with field id in side
 * @returns {Object} - object that contains data and meta
 */
export const deleteSource = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/settings/source/${data.id}`, {
            params: { 
                page : data.page || 1, 
                per_page: data.perPage || 13,
            },
        });
        return {
            data: response?.data?.data || [],
            meta: response?.data?.meta,
        };
    } catch (error) {
        console.error("Error deleting source:", error.message);
        throw error;
    }
}

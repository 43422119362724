// Import axios
import axiosClassic from "../api/interceptors";

// Get department list
export const fetchDepartments = async (searchQuery = "", page = 1, perPage = 10) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        page,
        per_page: perPage,
        ...(searchQuery && { name: searchQuery }),
    }

    const response = await axiosClassic.get(`/api/company/${companyId}/departments`, {params});

    // Check if the response has data
    return {
        data: response?.data?.data || [],
        meta: response?.data?.meta,
    };
};

// Create department
export const createDepartment = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.post(`/api/company/${companyId}/departments`, data);

    // Check if the response has data
    return {
        data: response?.data?.data || [],
        meta: response?.data?.meta,
    };
}

// Delete department
export const deleteDepartment = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.delete(`/api/company/${companyId}/departments/${data.id}`);

    // Check if the response has data
    return {
        data: response?.data?.data || [],
        meta: response?.data?.meta,
    };
}

// update department
export const updateDepartment = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.put(`/api/company/${companyId}/departments/${data.id}`, {name : data.name}, {
        params : {
            page : data?.page || 1,
            per_page: 10
        }
    });

    // Check if the response has data
    return {
        data: response?.data?.data || [],
        meta: response?.data?.meta,
    };
}
import { useEffect, useRef, useState } from 'react';
import {
    Container, Typography, Box, IconButton, Select, MenuItem, CircularProgress, Badge, Popover, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { ReactComponent as NotificationIcon } from '../../assets/icons/notificationIcon.svg';
import axiosClassic from '../../api/interceptors';
import { getMe } from '../../services/main';
import { logout } from '../../services/authApi'; // Импортируем функцию logout
import { closeHHNotify } from '../../services/hhpublicationApi';
import { navigateToHH } from "../../utils/connections"
import { ArrowUpIcon, ProfileIcon } from "../svgComponents";
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchUnreadNotificationsCount, fetchUserNotifications } from '../../services/notificationsApi';
import NotificationsPopover from './NotificationsPopover';
import PopUpWindow from '../popUpWindow';



const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data, isLoading, isError, refetch } = useQuery(['getMe'], getMe);
    const [anchorEl, setAnchorEl] = useState(null);
    const selectRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Запрос на количество уведомлений
    const { data: notificationCount, refetch: refetchCount } = useQuery(
        ['notificationsCount'],
        fetchUnreadNotificationsCount,
        {
            refetchInterval: 15000, // Автообновление каждые 15 секунд
            initialData: 0, // Начальное значение (пустой бейдж)
        }
    );

    // Мутация для загрузки уведомлений
    const {
        data: notifications = [], 
        mutate: loadNotifications,
        isLoading: loadingNotifications,
    } = useMutation(fetchUserNotifications);

    // Обработчик открытия уведомлений
    const handleOpenNotifications = (event) => {
        setAnchorEl(event.currentTarget);
        loadNotifications(); // Загружаем уведомления при открытии
        refetchCount(); // Сбрасываем счётчик
    };

    // Обработчик закрытия уведомлений
    const handleCloseNotifications = () => {
        setAnchorEl(null);
    };

    const isNotificationsOpen = Boolean(anchorEl);



    const sendAuthRequest = async (code, redirectUri, managerId) => {
        try {
            const response = await axiosClassic.post('https://api.taphr.kz/api/hh/auth', {
                code,
                redirect_uri: redirectUri,
                manager_id: managerId,
            });
            console.log('Запрос hh/auth успешно отправлен:', response.data);

            // Повторный запрос getMe после успешного выполнения hh/auth
            refetch();
        } catch (error) {
            console.error('Ошибка при выполнении запроса hh/auth:', error);
        }
    };


    useEffect(() => {
        if (data?.data) {
            const currentCompany = data.data.companies.find(
                company => company.company_id == localStorage.getItem('company_id')
            );
            if (currentCompany && !currentCompany.is_hh_notified && !currentCompany.is_hh_connected) {
                setIsModalOpen(true);
            } else {
                setIsModalOpen(false);
            }

            localStorage.setItem('is_hh_connected', currentCompany?.is_hh_connected);
        }
    }, [data]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const code = params.get('code');
        const managerId = location.search.split('manager_id=')[1]?.split('&')[0];
        const redirectUri = `https://app.taphr.kz${location.pathname}?manager_id=${managerId}`;

        if (code && managerId) {
            // Отправляем запрос hh/auth
            sendAuthRequest(code, redirectUri, managerId);
        }
    }, [location]);


    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <Typography color="error">Ошибка загрузки данных пользователя</Typography>
            </Box>
        );
    }

    localStorage.setItem('client_id', data?.data.hh_client_id)
    const userName = data?.data.name || 'Имя пользователя';
    const userAvatar = data?.data.avatar;
    const companyId = localStorage.getItem('company_id');
    const currentCompany = data?.data?.companies.find(company => company.company_id == companyId);
    const companyName = currentCompany?.company_name || 'Компания не найдена';

    const getHeaderText = () => {
        const path = location.pathname;

        if (path === '/') {
            return { title: 'Вакансии', subtitle: 'Показать все вакансии' };
        }
        if (path === '/candidates') {
            return { title: 'Кандидаты', subtitle: 'Список всех кандидатов' };
        }
        if (path === '/profile_settings') {
            return { title: "Настройки" };
        }
        if (/\/settings/.test(path)) {
            return { title: 'Настройки', subtitle: 'Домашняя страница' };
        }
        if (path === '/vacancy/create') {
            return { title: 'Новая вакансия', subtitle: 'Создайте новую вакансию' };
        }
        if (path === '/candidates/create') {
            return { title: 'Кандидаты', subtitle: 'Добавить нового кандидата' };
        }
        if (/^\/vacancy\/\d+$/.test(path)) {
            return { title: 'Детали вакансии', subtitle: 'Просмотрите информацию о вакансии' };
        }
        if (/^\/vacancy\/edit\/\d+$/.test(path)) {
            const id = path.split('/').pop(); // Извлекаем ID из пути
            return { title: `Редактирование вакансии`, subtitle: 'Редактируйте информацию о вакансии' };
        }

        return { title: 'Страница', subtitle: 'Описание страницы' };
    };


    const { title, subtitle } = getHeaderText();

    const handleLogout = async () => {
        try {
            await logout(); // Отправляем запрос на выход
            localStorage.clear(); // Очищаем localStorage
            navigate('/login'); // Перенаправляем на страницу входа
        } catch (error) {
            console.error("Ошибка при выходе из системы:", error);
        }
    };

    const handleSettingsClick = () => {
        navigate('/profile_settings')
    };

    const handleMenu = () => {
        console.log('Focused element after blur:', document.activeElement);
        setIsMenuOpen(prev => !prev);
        console.log("Меню открыто:", isMenuOpen);
    };

    const onClose = async () => {
        const response = await closeHHNotify();
        setIsModalOpen(false)
    }

    const handleMarkAllRead = () => {
        console.log('Отметить все как прочитанные');
        // Здесь можно добавить запрос для отметки всех уведомлений как прочитанных
    };

    return (
        <>
            <Container
                maxWidth="none"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "white",
                    height: "88px",
                }}
            >
                <Box sx={{ ml: 3 }}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#16151C",
                            fontSize: "24px",
                            lineHeight: "28px",
                            fontWeight: 600,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            display: "block",
                            height: "20px",
                            color: "rgba(134, 140, 146, 1)",
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: 400,
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    <IconButton
                        sx={{
                            color: "#A2A1A8",
                            backgroundColor: "#FFFFFF",
                            padding: "8px",
                            borderRadius: "8px",
                            "&:hover": { backgroundColor: "#F0F0F0" },
                        }}
                        onClick={handleOpenNotifications}
                    >
                        <Badge
                            badgeContent={
                                notificationCount > 0 ? notificationCount : null
                            }
                            color="error"
                            overlap="circular"
                            sx={{
                                "& .MuiBadge-badge": {
                                    fontSize: "12px",
                                    height: "20px",
                                    minWidth: "20px",
                                    borderRadius: "50%",
                                },
                            }}
                        >
                            <NotificationIcon />
                        </Badge>
                    </IconButton>
                    {isNotificationsOpen && (
                        <Popover
                            open={isNotificationsOpen}
                            anchorEl={anchorEl}
                            onClose={handleCloseNotifications}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <NotificationsPopover
                                notifications={notifications}
                                isLoading={loadingNotifications}
                                onMarkAllRead={handleMarkAllRead}
                                onClose={handleCloseNotifications}
                            />
                        </Popover>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "relative",
                            padding: "8px",
                            borderRadius: "8px",
                            backgroundColor: selectRef.current && isMenuOpen ? "rgba(248, 249, 249, 1)" : "transparent",
                            "&:hover": {
                                backgroundColor: "rgba(248, 249, 249, 1)",
                            }
                        }}
                        ref={selectRef}
                        onClick={handleMenu}
                    >
                        {userAvatar ? (
                            <img
                                style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: "50%",
                                }}
                                src={userAvatar}
                                alt="profile"
                            ></img>
                        ) : (
                            <ProfileIcon
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(232, 239, 228, 1)",
                                }}
                                svgSx={{
                                    width: "20px",
                                    height: "20px",
                                    color: "rgba(89, 157, 21, 1)",
                                }}
                            />
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis", // Ensures text truncates with ellipsis
                                    width: "136px", // Adjusting width to accommodate the arrow icon
                                }}
                            >
                                {userName}
                                <Typography
                                    component="span"
                                    sx={{
                                        display: "block",
                                        fontWeight: 400,
                                        color: "rgba(116, 116, 115, 1)",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis", // Ensures text truncates with ellipsis
                                        width: "136px", // Adjusting width to accommodate the arrow icon
                                    }}
                                >
                                    {companyName}
                                </Typography>
                            </Typography>
                            <ArrowUpIcon
                                sx={{
                                    width: 46,
                                    height: 46,
                                    transform: selectRef.current && isMenuOpen ? "none" : "rotate(180deg)",
                                }}
                                svgSx={{
                                    width: 10,
                                    height: 6,
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            />
                        </Box>
                        <PopUpWindow
                            anchorEl={selectRef.current}
                            open={Boolean(selectRef.current && isMenuOpen)}
                            onClose={handleMenu}
                            startItems={[
                                data?.data?.companies &&
                                    data?.data?.companies.length > 1 && (
                                        <MenuItem
                                            key="change_company"
                                            onClick={() =>
                                                navigate("/select_account")
                                            }
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                color: "#151813",
                                            }}
                                        >
                                            Сменить компанию
                                        </MenuItem>
                                    ),
                                <MenuItem
                                    key="profile_settings"
                                    onClick={handleSettingsClick}
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "#151813",
                                    }}
                                >
                                    Настройки профиля
                                </MenuItem>,
                                <MenuItem
                                    key="logout"
                                    onClick={handleLogout}
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "#151813",
                                        borderTop: "2px solid rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    Выйти
                                </MenuItem>,
                            ].filter(Boolean)}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: "262px",
                                    boxShadow: `
                                        0px 4px 6px -2px rgba(10, 13, 18, 0.03), 
                                        0px 12px 16px -4px rgba(10, 13, 18, 0.08)
                                    `,

                                    border: "1px solid rgba(245, 245, 245, 1)",
                                    marginTop: "5px",
                                },
                                "& .MuiList-root": {
                                    padding: "0",
                                },
                                "& .MuiMenuItem-root": {
                                    padding: "16px",
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Container>
            <Dialog
                open={isModalOpen}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "36px",
                        textAlign: "center",
                        backgroundColor: "#FFFFFF",
                        width: "508px",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontWeight: 600,
                        fontSize: "30px",
                        lineHeight: "24px",
                        color: "#151813",
                        marginBottom: "8px",
                    }}
                >
                    Привязать HeadHunter
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: "344px",
                        margin: "auto",
                        padding: 0,
                        height: "60px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#ADB5BD",
                        }}
                    >
                        Для того, чтобы продолжить работу, привяжите аккаунт
                        hh.kz
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                        paddingTop: "16px",
                    }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#151813",
                            borderColor: "#E2E4E7",
                            borderRadius: "8px",
                            width: "209px",
                            height: "46px",
                            padding: "8px 20px",
                            "&:hover": {
                                backgroundColor: "#F8F9F9",
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                            navigateToHH();
                        }}
                        variant="contained"
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#FFFFFF",
                            backgroundColor: "#599D15",
                            borderRadius: "8px",
                            padding: "8px 20px",
                            width: "209px",
                            height: "46px",
                            "&:hover": {
                                backgroundColor: "#4A8B12",
                            },
                        }}
                    >
                        Привязать
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Header;

import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    MenuItem,
    Divider,
    CircularProgress,
} from '@mui/material';
import LabeledInput from "../main/LabeledInput";
import { useQuery } from "@tanstack/react-query";
import { fetchCities, fetchCurrencies } from "../../services/dictionariesApi";
import { fetchDepartments } from "../../services/departmentsApi";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "../tapHrStyledComponents";
import { fetchCategories } from "../../services/categoriesApi";
import CustomAutoComplete from "../styledComponents/CustomAutoComplete";
import CustomRadioList from "../styledComponents/CustomRadioList";
import CustomEditor from "../styledComponents/CustomEditor";

const FirstStep = ({
    name,
    setName,
    salaryFrom,
    setSalaryFrom,
    salaryTo,
    setSalaryTo,
    currency,
    setCurrency,
    experience,
    setExperience,
    employmentType,
    setEmploymentType,
    workSchedule,
    setWorkSchedule,
    taxType,
    setTaxType,
    text,
    setText,
    city,
    setCity,
    department,
    setDepartment,
    category,
    setCategory,
    accept_temporary,
    set_accept_temporary,
    characters,
}) => {
    const navigate = useNavigate()

    const {
        data: currencies = [],
        isLoading: isCurrencyLoading,
    } = useQuery(["currencies"], fetchCurrencies);

    const handleAcceptTemporary = () => {
        set_accept_temporary(prev => !prev);
    }

    const isOnlyNumbersAndSpecialChars = (str) => /^[^A-Za-z]*$/.test(str);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    sx={{ fontWeight: 500, fontSize: 20, lineHeight: "24px" }}
                >
                    Основная информация
                </Typography>
                <Box
                    component= "button"
                    onClick={() => navigate("/")}
                    variant="outlined"
                    sx={{
                        color: "rgba(255, 59, 48, 1)",
                        border: "1px solid transparent",
                        backgroundColor: "transparent",
                        padding: "12px 20px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "rgba(255, 243, 242, 1)",
                        },
                        "&:active": {
                            color: "rgba(198, 46, 37, 1)",
                        }
                    }}
                >
                    Отменить
                </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />

            <Box sx={{ display: "flex", gap: 2, width: "100%", mb: "36px" }}>
                <LabeledInput
                    label="Название"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    placeholder="Введите название вакансии"
                    sx={{ minWidth: "356px", flex: 1 }}
                    required
                />
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Город <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={city}
                        setValue={setCity}
                        queryKeyStartingValue={["cities"]}
                        fetchFunction = {fetchCities}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, mb: "36px" }}>
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Отдел <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={department}
                        setValue={setDepartment}
                        queryKeyStartingValue={["departments"]}
                        fetchFunction = {fetchDepartments}
                    />
                </Box>
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Категория <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={category}
                        setValue={setCategory}
                        queryKeyStartingValue={["categories"]}
                        fetchFunction = {fetchCategories}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, mb: 2, height: "84px" }}>
                <LabeledInput
                    label="Заработная плата"
                    InputProps={{
                        type: "numeric",
                    }}
                    value={salaryFrom}
                    onChange={(e) => {
                        if (isOnlyNumbersAndSpecialChars(e.target.value)) {
                            setSalaryFrom(e.target.value);
                        }
                    }}
                    placeholder="от"
                    sx={{ minWidth: "356px" }}
                />
                <LabeledInput
                    InputProps={{
                        type: "numeric",
                    }}
                    value={salaryTo}
                    onChange={(e) => {
                        if (isOnlyNumbersAndSpecialChars(e.target.value)) {
                            setSalaryTo(e.target.value);
                        }
                    }}
                    placeholder="до"
                    sx={{ minWidth: "356px", marginTop: "20px"}}
                />
                <Box sx={{ width: "94px" }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Валюта
                    </Typography>
                    <TextField
                        select
                        placeholder="₸"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        variant="outlined"
                        sx={{
                            backgroundColor: "#fff",
                            border: "1px solid rgba(226, 228, 231, 1)",
                            borderRadius: "8px",
                            width: "100%",
                            "&:hover": {
                                borderColor: "#599D15", // Hover border color
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            "& .MuiOutlinedInput-root": {
                                height: "54px",
                                borderRadius: "8px",
                            },
                            "&:has(.Mui-focused)": {
                                border: "2px solid rgba(205, 221, 198, 1)", // Focused border color
                                "& .MuiOutlinedInput-root": {
                                    height: "52px", // Height when focused
                                },
                            },
                        }}
                    >
                        {isCurrencyLoading ? (
                            <MenuItem>Загрузка...</MenuItem>
                        ) : (
                            currencies.map((cur) => (
                                <MenuItem key={cur.code} value={cur.code}>
                                    {cur.name}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
            <CustomRadioList
                sx={{ display: "flex", gap: "16px" }}
                isHor={true}
                defaultValue={taxType}
                inputs={[
                    { value: true, text: "До вычета налогов" },
                    { value: false, text: "На руки" },
                ]}
                onClick={setTaxType}
            />
            </Box>

            <div style={{ marginTop: "36px" }}>
                <Typography sx={{ mb: "12px" }}>Описание</Typography>
                <p
                    style={{
                        color: "#ADB5BD",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontWeight: 400,
                        marginBottom: "16px",
                    }}
                >
                    Описание вакансии не может быть менее 150 символов
                </p>
                <CustomEditor
                    value={text}
                    onChange={setText}
                    formats={[
                        'bold',
                        'italic',
                        'underline',
                        'align',
                        'list',
                    ]}
                    slotProps={{
                        fullEditor: {
                            height: "412px",
                        }
                    }}
                 />
                <Typography
                    sx={{
                        mt: "6px",
                        color: "rgba(116, 116, 115, 1)",
                    }}
                >
                    Количество символов : <span style={{color: characters >= 150 ? "#599D15" : "red"}}>{characters}</span>
                </Typography>
            </div>

            <Typography sx={{ mt: "36px", mb: "12px", lineHeight: "20px" }}>Опыт работы</Typography>
            <Typography sx={{ mb: 2, color: "rgba(173, 181, 189, 1)", fontSize: "14px", lineHeight: "18px", fontWidth: "400" }}>Если нужен опыт от нескольких месяцев до года, то выберите “Нет опыта”</Typography>

            <CustomRadioList
                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                defaultValue={experience}
                inputs={[
                    { value: "noExperience", text: "Нет опыта" },
                    { value: "between1And3", text: "От 1 года до 3 лет" },
                    { value: "between3And6", text: "От 3 до 6 лет" },
                    { value: "moreThan6", text: "Более 6 лет" },
                ]}
                onClick={setExperience}
            />


            <Typography sx={{ mt: "36px", mb: 2, lineHeight: "20px" }}>Тип занятости</Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "8px",
                    mb: "36px",
                    cursor: "pointer",
                }}
                onClick={handleAcceptTemporary}
            >
                <CheckBox checked={accept_temporary} onClick={() => {}} />
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#151813",
                    }}
                >
                    Возможно временное оформление
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{
                            display: "block",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "18px",
                            color: "#9BA2A9",
                        }}
                    >
                        По совместительству или по ГПХ: услуги, подряд, ИП,
                        самозанятые
                    </Typography>
                </Typography>
            </Box>

            <CustomRadioList
                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                isCheckBox = {true}
                defaultValue={employmentType}
                inputs={[
                    { value: "full", text: "Полная занятость" },
                    { value: "part", text: "Частичная занятость" },
                    { value: "project", text: "Проектная работа" },
                    { value: "volunteer", text: "Волонтерство" },
                    { value: "probation", text: "Стажировка" },
                ]}
                onClick={setEmploymentType}
            />

            <Typography sx={{ mt: "36px", mb: 2, lineHeight: "20px" }}>График работы</Typography>
            <CustomRadioList
                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                isCheckBox = {true}
                defaultValue={workSchedule}
                inputs={[
                    { value: "fullDay", text: "Полный день" },
                    { value: "shift", text: "Сменный график" },
                    { value: "project", text: "Гибкий график" },
                    { value: "remote", text: "Удаленная работа" },
                    { value: "flyInFlyOut", text: "Вахтовый метод" },
                ]}
                onClick={setWorkSchedule}
            />
        </>
    );
};

export default FirstStep;

import { memo } from "react";
import { Box, TextField } from "@mui/material";


const CustomInput = memo(({ label, value, placeholder, readOnly, multiline, rows, onChange = () => {}, sx={}, renderOutput, renderInput, required }) => {
    return (
        <Box sx={sx}>
            <label
                style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    marginBottom: "8px",
                    display: "block",
                }}
            >
                {label} {required && <span style={{ color: "red" }}>*</span>}
            </label>
            <TextField
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(248, 249, 249, 1)",
                    borderRadius: "8px",
                    border: sx.inputBorder || "none",
                    "& fieldset": { border: "none" },
                    "& input": { padding: sx.inputPadding || "16px", fontSize: "16px", lineHeight: "20px", height: "20px", cursor: readOnly ? "default" : "text" },
                }}
                value={renderOutput ? renderOutput(value) : value}
                onChange={(e) => onChange(renderOutput ? renderInput(e.target.value) : e.target.value)}
                placeholder={placeholder ? placeholder : undefined} // Conditional placeholder
                multiline={Boolean(multiline)}
                rows={rows}
                slotProps={{
                    input: {
                        readOnly: Boolean(readOnly),
                    },
                    htmlInput: {
                        autoComplete: "off",
                    },
                }}
            />
        </Box>
    );
});

export default CustomInput;
import { Box, Typography } from '@mui/material';
import { SendIcon } from '../svgComponents';

const BackCircle = ({ width, height, opacity }) => {
    return (
        <Box
            sx={{
                position: "absolute",
                width: width,
                height: height,
                borderRadius: "50%",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: `rgba(221, 221, 221, ${opacity})`,
            }}
        >
            <Box
                sx= {{
                    width : width - 1,
                    height: height - 1,
                    backgroundColor: "white",
                    borderRadius: "50%",
                }}
            />
        </Box>  
    );
};


const EmptyTextTemplate = ({handleModalWindow, mainText, subText}) => {

    const handleCreateTemplate = (event) => {
        event.preventDefault();
        handleModalWindow();
    }
    return (
        <Box
            sx={{
                position: "relative",
                height: "376px",
                padding: 0,
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "48px",
                    alignItems: "center",
                    padding: 0,
                }}
            >
                <SendIcon
                    sx={{width: 56, height: 56, border: "1px solid rgba(229, 230, 231, 1)", backgroundColor: "white", borderRadius: "12px", mt: "48px"}}
                    svgSx = {{width: 28, height: 28, color: "rgba(21, 24, 19, 1)"}}
                />
                <Box>
                    <Typography sx={{fontSize: "30px", lineHeight: "34px", fontWeight: 600, mb: 2}}>
                        {mainText}
                    </Typography>
                    <Typography sx={{fontSize: "16px", lineHeight: "20px", fontWeight: 400, color: "rgba(116, 116, 115, 1)", mb: 3, textAlign: "center"}}>
                        {subText}
                    </Typography>
                    <Box
                        component= "button"
                        onClick={handleCreateTemplate}
                        sx={{
                            padding: "12px 20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            fontSize: "16px",
                            lineHeight: "20px",
                            margin: "0 auto",
                            border: "none",
                            outline: "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            "&:hover" : {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                            },
                            "&:active" : {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                        }}
                    >
                        Создать шаблон
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 1,
                }}
            >
                <BackCircle width={704} height={704} opacity={0.10} />
                <BackCircle width={608} height={608} opacity={0.15} />
                <BackCircle width={512} height={512} opacity={0.20} />
                <BackCircle width={416} height={416} opacity={0.25} />
                <BackCircle width={320} height={320} opacity={0.30} />
                <BackCircle width={224} height={224} opacity={0.35} />
                <BackCircle width={128} height={128} opacity={0.4} />
            </Box>
        </Box>
    )
}

export default EmptyTextTemplate; 
import { TrashIcon, PencilIcon, CheckIcon } from "../svgComponents";
import { TableRow, TableCell, Chip, Input } from "@mui/material";
import { useState, memo } from "react";

export const EditingRow = ({text, purpose, handleEdit, handleDelete, setId, id}) => {
    const [name, setName] = useState(text);
    const onDelete = () => {
        handleDelete(id);
    }

    const handleSave = () => {
        if (name.trim().length !== 0 && name.trim() !== text){
            handleEdit(id, name.trim());
        } else {
            setId(null);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && name.trim()) {
            handleSave();
        }
    }

    const handleBlur = () => {
        console.log("unbluring")
        setId(null);
    };

    return (
        <TableRow>
            <TableCell sx={{width: "30svw", minWidth:"650px", padding: "16px 24px 14px 24px", fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    autoFocus
                    sx={{ 
                        height: "42px", 
                        padding: 0,
                        "&::after": {
                            borderBottom: "2px solid rgba(89, 157, 21, 1)",
                        },
                    }}
                />
            </TableCell>
            <TableCell align="center" sx={{width: "10svw", minWidth: "150px", padding: "16px 24px"}}>
            <Chip
                label={purpose === 1 ? "Этапы" : "Отказы"}
                sx={{
                    color:
                    purpose === 1
                        ? "rgba(28, 96, 176, 1)"
                        : "rgba(228, 53, 43, 1)",
                    backgroundColor:
                    purpose === 1
                        ? "rgba(242, 244, 250, 1)"
                        : "rgba(255, 243, 242, 1)",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                }}
                size="small"
                variant="outlined"
            />
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "13px 16px",
                    gap: "4px",
                }}
            >
                <TrashIcon
                    onClick={onDelete}
                    sx={{
                        width: 46,
                        height: 46,
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(255, 59, 48, 1)"
                    }}
                />
                <CheckIcon 
                    onMouseDown={handleSave}
                    dontPreventDefault = {false}
                    sx={{
                        cursor: "pointer",
                        width: 46,
                        height: 46,
                        "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                        borderRadius: "8px",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(21, 24, 19, 1)",
                        hoverColor: "rgba(41, 57, 73, 1)",
                        pressedColor: "rgba(36, 50, 64, 1)",
                    }}
                />
            </TableCell>
        </TableRow>
    )
};

export const StagesRow = memo(({text, id, purpose, isEditable, handleDelete, setId}) => {
    const onDelete = () => {
        if (isEditable) {
            handleDelete(id);
        }
    }

    const onEdit = () => {
        if (isEditable) { 
            setId(id);
        }
    }
    return (
        <TableRow>
            <TableCell sx={{width: "30svw", minWidth:"650px", padding: "16px 24px", fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>{text}</TableCell>
            <TableCell align="center" sx={{width: "10svw", minWidth: "150px", padding: "16px 24px"}}>
                <Chip
                label={purpose === 1 ? "Этапы" : "Отказы"}
                sx={{
                    color:
                    purpose === 1
                        ? isEditable
                        ? "rgba(28, 96, 176, 1)"
                        : "rgba(18, 156, 82, 1)"
                        : "rgba(228, 53, 43, 1)",
                    backgroundColor:
                    purpose === 1
                        ? isEditable
                        ? "rgba(242, 244, 250, 1)"
                        : "rgba(242, 248, 244, 1)"
                        : "rgba(255, 243, 242, 1)",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                }}
                size="small"
                variant="outlined"
            />
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "13px 16px",
                    gap: "4px",
                }}
            >
                <TrashIcon
                    onClick={onDelete}
                    sx={{
                        width: 46,
                        height: 46,
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: isEditable ? "rgba(242, 243, 243, 1)" : "transparent",
                        },
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: isEditable
                            ? "rgba(255, 59, 48, 1)"
                            : "rgba(200, 202, 204, 1)",
                    }}
                />
                <PencilIcon
                    onClick={onEdit}
                    sx={{
                        width: 46,
                        height: 46,
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: isEditable ? "rgba(242, 243, 243, 1)" : "transparent",
                        },
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: isEditable
                            ? "rgba(46, 64, 82, 1)"
                            : "rgba(200, 202, 204, 1)",
                    }}
                />
            </TableCell>
        </TableRow>
    );
});
import React, { memo, useState } from 'react';
import { Divider, Typography, Box, Tab, Tabs } from '@mui/material';
import { EducationUi, ExperienceUi, FilesUi, KeySkillsUi, LanguagesUi, SupplementaryUi } from './candidateInfoUi';
import { getSourceName } from '../../utils/manageSources';

const ResumeFromSource = ({ source_id, sx, resume, candidate, render = { isFiles: true } }) => {

    console.log(resume)
    return (
        <Box sx={sx}>
            {source_id === 2 && (
                <SupplementaryUi
                    sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
                    dateOfBirth={resume?.source_body?.birth_date}
                    citizenship={resume?.source_body?.citizenship}
                    city={resume?.source_body?.area?.name}
                    employments={resume?.source_body?.employments}
                    schedules={resume?.source_body?.schedules}
                    businessTrips={resume?.source_body?.business_trip_readiness}
                    render={{ isSubDividers: true }}
                    source_id={source_id}
                />
            )}

            {source_id === 1 && (
                <SupplementaryUi
                    sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
                    dateOfBirth={candidate?.birth_date}
                    citizenship={resume?.source_body?.citizenship}
                    city={candidate?.city_name}
                    employments={resume?.source_body?.employments || resume?.source_body?.employment}
                    schedules={resume?.source_body?.schedules || resume?.source_body?.schedule}
                    businessTrips={resume?.source_body?.business_trip_readiness}
                    render={{ isSubDividers: true }}
                    source_id={source_id}
                />
            )}

            {source_id === 2 && (
                <EducationUi source_id={source_id} sx={{ display: "flex", flexDirection: "column", gap: "24px" }} education={resume?.source_body?.education?.primary} render={{ isDivider: false }} />
            )}

            {source_id === 1 && (
                <EducationUi source_id={source_id} sx={{ display: "flex", flexDirection: "column", gap: "24px" }} education={resume?.source_body?.education?.items} render={{ isDivider: false }} />
            )}
            <ExperienceUi sx={{ display: "flex", flexDirection: "column", gap: "24px" }} experience={resume?.source_body?.experience} />

            {source_id === 2 && (
                <KeySkillsUi sx={{ display: "flex", flexDirection: "column", gap: "24px" }} skills={resume?.source_body?.skill_set} />
            )}

            {source_id === 1 && (
                <KeySkillsUi sx={{ display: "flex", flexDirection: "column", gap: "24px" }} skills={resume?.source_body?.skills} />
            )}
            {source_id === 2 && (
                <LanguagesUi sx={{ display: "flex", flexDirection: "column", gap: "24px" }} languages={resume?.source_body?.language} />
            )}

            {source_id === 1 && (
                <LanguagesUi sx={{ display: "flex", flexDirection: "column", gap: "24px" }} languages={resume?.source_body?.language} />
            )}
            {/* {render.isFiles && <FilesUi onClick={() => window.open(resume?.cv_path, '_blank')} sx={{ display: "flex", flexDirection: "column", gap: "24px" }} file={resume?.cv_path} render ={{isDivider : false}} />} */}
        </Box>
    );
}

/**
 * @param {Object} sx - styles for the resume block, if you want to prevent rerender pass sx inside useRef
 * @param {Object} resumes - resume data
 * @returns {JSX.Element} - resume block
 */
const ResumeUi = memo(({ sx, resumes, candidate }) => {
    console.log("resume being render");
    const [currentTab, setCurrentTab] = useState(0);
    const sourceCounter = {};

    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    }

    if (!resumes || !resumes.length) {
        return (
            <Box sx={{ ...sx, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                    sx={{
                        color: "rgba(121, 128, 136, 1)",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Нет действующих резюме для отоброжения
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={sx}>
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    sx={{
                        padding: "0 24px",
                        gap: "32px",
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'rgba(46, 64, 82, 1)',
                            height: '3px',
                            borderTopLeftRadius: '2px',
                            borderTopRightRadius: '2px',
                        },
                    }}
                    TabIndicatorProps={{
                        style: { transform: 'translateY(0px)' },
                    }}
                >
                    {resumes.map((source, index) => {
                        const text = getSourceName(sourceCounter, source.source_id);
                        return (
                            <Tab
                                key={index}
                                label={
                                    <Typography
                                        sx={{
                                            color: currentTab === index ? 'rgba(46, 64, 82, 1)' : 'rgba(121, 128, 136, 1)',
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                }
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '9px 8px',
                                }}
                            />
                        )
                    })}
                </Tabs>
                <Divider sx={{ backgroundColor: "rgba(226, 228, 231, 1)", margin: "0 24px" }}></Divider>
            </Box>
            {resumes[currentTab].source_id === 2 && (
                <ResumeFromSource source_id={resumes[currentTab].source_id} sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "32px", mb: 3 }} resume={resumes[currentTab]} />
            )}
            {resumes[currentTab].source_id === 1 && (
                <ResumeFromSource candidate={candidate} source_id={resumes[currentTab].source_id} sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "32px", mb: 3 }} resume={resumes[currentTab]} />
            )}
        </Box>
    );
});

export default ResumeUi;
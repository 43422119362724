
export const countCharactersAndWords = (html) => {
    if (!html) return { characters: 0, words: 0 };

    // Remove all HTML tags using a regular expression
    const strippedText = html.replace(/<\/?[^>]+(>|$)/g, '');

    // Trim whitespace
    const trimmedText = strippedText.trim();

    // Count the characters
    const characterCount = trimmedText.length;

    // Count the words by splitting the text into an array of words
    const wordCount = trimmedText ? trimmedText.split(/\s+/).length : 0;

    return { characters: characterCount, words: wordCount };
};

export const countCharacters = (html) => {
    if (!html) return 0;

    // Remove all HTML tags using a regular expression
    const strippedText = html.replace(/<\/?[^>]+(>|$)/g, '');
  
    // Trim whitespace and count the characters
    return strippedText.trim().length;
};
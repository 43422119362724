import { Box, Typography } from "@mui/material";
import { CheckBox } from "../tapHrStyledComponents"
import CustomRadio from "./CustomRadio";

const RadioList = ({ value, isActive, text, onClick, isCheckBox }) => {
    return (
        <Box
            onClick={(e) => onClick(value)}
            sx={{
                display: "flex",
                alightItems: "center",
                justifyContent: "center",
                gap: "8px",
                cursor: "pointer",
            }}
        >
            {isCheckBox ? (
                <CheckBox checked={isActive} onClick={() => {}} />
            ) : (
                <CustomRadio checked={isActive} onClick={() => {}} />
            )}
            <Typography
                sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

/**
 * 
 * @param {Object} sx - Styles for the Box component
 * @param {Boolean} isHor - Horizontal or vertical list
 * @param {String} defaultValue - Default value
 * @param {Array} inputs - Array of inputs
 * @param {Function} onClick - Function to handle click
 * @returns {JSX.Element} - Custom radio list
 */

const CustomRadioList = ({sx = {}, isCheckBox = false, defaultValue = "", inputs = [], onClick = () => {}, multiInputs = false}) => {
    return (
        <Box sx={sx}>
            {Array.isArray(inputs) &&
                inputs.map((input, index) => (
                    <RadioList
                        key={index}
                        text={input.text}
                        value={input.value}
                        isActive={(multiInputs && Array.isArray(defaultValue)) ? defaultValue.includes(input.value) :input.value === defaultValue}
                        onClick={onClick}
                        isCheckBox={isCheckBox}
                    />
                ))}
        </Box>
    );
};

export default CustomRadioList;
